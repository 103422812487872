import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {routeLink, toasterMessage} from '../../../constant';
import HttpAuth from '../../../services/HttpAuthService';
import {useToast} from '@unthinkable/react-toast';
import {
  FooterContainer,
  FormContainer,
  HeaderContainer,
  LayoutContainer,
  LoginMessage,
  ScreenContainer,
} from './LoginForm.style';

import {LayoutFieldsRender} from '@unthinkable/react-form';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';

const PasswordForm = () => {
  const navigate = useNavigate();
  const label = {title: 'Reset Password', buttonText: 'Reset Password'};
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const resetPassword = async ({values}) => {
    try {
      setLoading(true);
      await HttpAuth.put(routeLink.changePasswordLink, {
        ...values,
      });
      toast({
        message: toasterMessage.ChangeExistingPassword.success,
        type: 'Success',
      });
      navigate('/');
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };
  const handleMatch = (newPassword, confirmPassword) => {
    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        return void 0;
      } else {
        return toasterMessage.passwordMatch.failure;
      }
    }
    return '';
  };

  return (
    <ScreenContainer>
      <FormContainer>
        <HeaderContainer>
          <LoginMessage>{label.title}</LoginMessage>
        </HeaderContainer>
        <Form>
          {formContext => {
            const {values, isValid} = formContext;
            return (
              <LayoutContainer>
                <LayoutFieldsRender
                  layoutFields={[
                    {
                      type: 'password',
                      field: 'oldPassword',
                      label: 'Old Password',
                      required: true,
                      inputProps: {
                        autoComplete: 'new-password',
                      },
                    },
                    {
                      type: 'password',
                      field: 'newPassword',
                      label: 'New Password',
                      required: true,
                      validate: value => {
                        return handleMatch(value, values.confirmPassword);
                      },
                    },
                    {
                      type: 'password',
                      field: 'confirmPassword',
                      label: 'Confirm Password',
                      required: true,
                      validate: value => {
                        return handleMatch(values.newPassword, value);
                      },
                      onKeyUp: async e => {
                        if (e.which == 13) {
                          await resetPassword({values});
                        }
                      },
                    },
                  ]}
                  {...formContext}
                />

                <FooterContainer>
                  <Button
                    disabled={
                      !isValid ||
                      !values.newPassword ||
                      !values.confirmPassword ||
                      values.newPassword !== values.confirmPassword
                    }
                    text={label.buttonText}
                    loading={loading}
                    onPress={async () => {
                      await resetPassword({values});
                    }}
                  />
                </FooterContainer>
              </LayoutContainer>
            );
          }}
        </Form>
      </FormContainer>
    </ScreenContainer>
  );
};

export default PasswordForm;
