import {action} from '../../constant/authorization';
import {NewRequestsTable} from './screens/NewRequestsTable';
import {RejectReason} from './components/Reject-reason';
import {NewRequestsForm} from '../practices/screens/NewRequestsForm';
import checkAuthorization from '../../services/accessService';

const stackRoutes = [
  {
    name: 'New Requests',
    path: '/new-requests',
    component: NewRequestsTable,
    visible: user => {
      return checkAuthorization(user, 'New Requests', action.view);
    },
    children: [
      {
        name: 'Reject Reason',
        path: '/new-requests/reject-reason/:newRequestId',
        component: RejectReason,
        screenOptions: {
          modalSize: 'small',
        },
      },
    ],
  },
  {
    name: 'New Requests',
    path: '/new-requests/:Id/newRequest',
    component: NewRequestsForm,
  },
];

const modalRoutes = [];

export default {
  stack: stackRoutes,
  modal: modalRoutes,
};
