import React, {useEffect, useCallback} from 'react';
import {CBadge} from '@coreui/react';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useDispatch} from 'react-redux';
import {increaseUnreadCount, setUnreadCount} from '../chatRedux/actions';
import {useToast} from '@unthinkable/react-toast';
import {toasterMessage} from '../../../constant';
import {getMessageCount} from '../chatRedux/reducer';
import {useEventHandler} from '@unthinkable/react-event-handler';

const GetCountMessages = ({row}) => {
  const {user} = useAuth();
  const toast = useToast();
  const dispatch = useDispatch();
  const unreadCount = getMessageCount(row);
  const {notify} = useEventHandler();

  const fetchMessageCounts = useCallback(async row => {
    try {
      const ChannelPNGroupId = row?.groupId?.PNGroupId;
      const lastReadTimeToken = row?.lastReadTimeToken - 2 || 0;
      const response = await user.chatInstance.sdk?.messageCounts({
        channels: [ChannelPNGroupId],
        channelTimetokens: [lastReadTimeToken],
      });
      const count = lastReadTimeToken
        ? response?.channels[ChannelPNGroupId] - 1
        : response?.channels[ChannelPNGroupId];
      dispatch(setUnreadCount(count, ChannelPNGroupId));
    } catch (error) {
      toast({
        message: `${toasterMessage.getCountMessage.failure}: ${error}`,
        type: 'Error',
      });
    }
  }, []);

  useEffect(() => {
    if (user?.chatInstance) {
      fetchMessageCounts(row);
      const ChannelPNGroupId = row?.groupId?.PNGroupId;
      const chatListener = {
        message: async res => {
          if (res.channel === ChannelPNGroupId) {
            dispatch(increaseUnreadCount(row?.groupId?.PNGroupId));
          }
          notify &&
            notify('dataUpdated', {
              id: 'chatgroups',
            });
        },
      };
      user.chatInstance.sdk?.addListener(chatListener);
      user.chatInstance.sdk?.subscribe({channels: [ChannelPNGroupId]});
      return () => {
        user.chatInstance.sdk?.removeListener(chatListener);
        user.chatInstance.sdk?.unsubscribe({channels: [ChannelPNGroupId]});
      };
    }
  }, [row.lastReadTimeToken, row._id]);

  return (
    <div>{unreadCount > 0 && <CBadge color="info">{unreadCount}</CBadge>}</div>
  );
};

export default GetCountMessages;
