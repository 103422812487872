import React from 'react';
import Config from '../../Config';
import {Image} from '@unthinkable/react-core-components';
import HttpAuth from '../../services/HttpAuthService';

export const ImagePreviewer = ({value}) => {
  const {downloadUrl} = Config;
  const url = downloadUrl + '/files/' + value.key;
  return (
    <Image
      key={value.key}
      source={url}
      resizeMode={'contain'}
      style={{
        width: '100%',
        height: '100%',
        alignSelf: 'center',
      }}
      onError={async e => {
        await HttpAuth.refreshTokens();
        e.target.src += '?' + Date.now();
      }}
    />
  );
};
