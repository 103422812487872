import {put, all, call, takeLatest} from 'redux-saga/effects';
import {loadError, loadSuccess, pageCountSuccess} from './actions.js';
import {LOAD_INIT, PAGE_COUNT_INIT} from './TableReduxConsts.js';
import {HttpAuthService} from '../../services';
import config from '../../Config';

const fetch = async ({uri, skip, perPage, props, onError}) => {
  const result = await HttpAuthService.get(
    uri,
    {
      params: {
        ...props,
        skip: skip,
        limit: perPage,
      },
    },
    onError,
  );
  return result;
};

function* loadWorker({payload}) {
  const {
    filter,
    addOnFilter,
    groupBy,
    addonGroupBy,
    params,
    search,
    searchFields,
    api,
    fields,
    sort,
    perPage = config.defaultListLimit,
    page,
    data,
    onError,
  } = payload;
  try {
    if (!api && data) {
      yield put(loadSuccess(api, data));
      return;
    }
    let response = yield call(fetch, {
      uri: api,
      skip: page * perPage,
      perPage,
      onError,
      props: {
        fields,
        filter,
        addOnFilter,
        groupBy,
        addonGroupBy,
        params,
        search,
        searchFields,
        sort,
      },
    });
    response = response.data;
    yield put(loadSuccess(api, response));
  } catch (err) {
    yield put(loadError(api, err));
  }
}

function* pageCountWorker({payload}) {
  const {
    filter,
    addOnFilter,
    groupBy,
    addonGroupBy,
    params,
    search,
    searchFields,
    api,
    fields,
    perPage = config.defaultListLimit,
    data,
  } = payload;
  try {
    if (!api && data) {
      yield put(
        pageCountSuccess(api, Math.ceil(response?.data?.length / perPage)),
      );
      return;
    }
    let response = yield call(fetch, {
      uri: api,
      props: {
        fields,
        filter,
        addOnFilter,
        groupBy,
        addonGroupBy,
        params,
        search,
        searchFields,
        count: true,
      },
    });
    response = Math.ceil(response?.data?.count / perPage) || 0;
    yield put(pageCountSuccess(api, response));
  } catch (err) {
    yield put(loadError(api, err));
  }
}

export default function* tableSaga() {
  yield all([
    takeLatest(PAGE_COUNT_INIT, pageCountWorker),
    takeLatest(LOAD_INIT, loadWorker),
  ]);
}
