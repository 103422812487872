import React from 'react';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {
  nameValidation,
  phoneAndFaxValidation,
} from '../../../utils/validations/commonValidations';
import { useParams } from 'react-router';
import { useAuth } from '../../auth/hooks/useAuth';

export const NewRequestsForm = props => {
  const {practiceId} = useParams();
  const { user } = useAuth();
  const {onSubmit} = useFormSubmit({
    uri: routeLink.newRequest,
    eventSourceId: 'newRequest',
  });

  return (
    <Form
      saveButtonText={'Save'}
      defaultValues={{
        practiceId,
        requester: user._id
      }}
      onSubmit={onSubmit}
      layoutFields={[
        {
          label: 'Create New Request',
          fields: [
            {
              type: 'autoComplete',
              field: 'type',
              label: 'Type',
              api: routeLink.requestType,
              sort: {name: 1},
              suggestionField: 'name',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              label: 'Name',
              field: 'name',
              size: 'medium',
              required: true,
              validate: nameValidation,
            },
            {
              type: 'text',
              label: 'Contact Name',
              field: 'contactName',
              size: 'medium',
              required: true,
              validate: nameValidation,
            },
            {
              type: 'email',
              label: 'Email',
              field: 'email',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              label: 'Phone No.',
              field: 'phoneNumber',
              required: true,
              size: 'medium',
              validate: value => phoneAndFaxValidation(value, 'phone')
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
