import {authorization, action} from '../../constant/authorization';
import {AddPractice, EditPractice} from './screens/PracticeForm';
import {PracticeTable} from './screens/PracticeTable';
import {NewRequestsForm} from './screens/NewRequestsForm';
const stackRoutes = [
  {
    name: 'Practices',
    path: '/practices',
    component: PracticeTable,
    visible: user => {
      return authorization[user?.userType.name]?.Practices?.includes(
        action.view,
      );
    },
  },
  {
    name: 'Add Practice',
    path: '/practices/add',
    component: AddPractice,
    visible: user => {
      return authorization[user?.userType.name]?.Practices?.includes(
        action.create,
      );
    },
  },
  {
    name: 'Edit Practice',
    path: '/practices/edit/:practiceId',
    component: EditPractice,
    visible: user => {
      return authorization[user?.userType.name]?.Practices?.includes(
        action.edit,
      );
    },
  },
  {
    name: 'New Request',
    path: '/practices/edit/:practiceId/facilityRequest',
    component: NewRequestsForm,
  },
];

export default {
  stack: stackRoutes,
};
