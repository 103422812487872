import React, {useMemo, useState} from 'react';
import {useFormik} from 'formik';
import {useFetchFormData} from '@unthinkable/react-data-handler';
import {useComputations} from './useComputations';

export const useForm = ({
  isDualMode,
  computations,
  beforeSubmit,
  afterSubmit,
  successMessage,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  closeOnSubmit: closeOnSubmitProp,
  resetOnSubmit: resetOnSubmitProp,
  readOnly: formReadOnly,
  ...props
}) => {
  if (formReadOnly && isDualMode) {
    isDualMode = false;
  }
  const {mode, onError, onClose, _parentValues} = props;
  const [readOnly, setEditMode] = useState(isDualMode ? true : formReadOnly);

  const [submitAction, _setSubmitAction] = useState();

  let {data, loading} = useFetchFormData(props);

  const onSubmitForm = async (values, formikContext) => {
    const {
      type,
      closeOnSubmit = closeOnSubmitProp,
      resetOnSubmit = resetOnSubmitProp,
      onNext,
      setLoading,
    } = submitAction || {};

    try {
      if (!onSubmit) {
        throw new Error('Form must have onSubmit function to submit.');
      }
      const {resetForm} = formikContext;

      setLoading?.(true);
      if (typeof beforeSubmit === 'function') {
        let result = await beforeSubmit({data: values, submitAction});
        if (result?.data) {
          values = result.data;
        }
      }
      const submitResult = await onSubmit(values, formikContext);
      afterSubmit && afterSubmit(submitResult);
      isDualMode && setEditMode(true);
      onSubmitSuccess &&
        onSubmitSuccess(
          typeof successMessage === 'function'
            ? successMessage(submitResult)
            : successMessage,
        );

      type === 'saveAndNext' && onNext && (await onNext?.(submitResult));
      if (type === 'saveAndNew' || resetOnSubmit) {
        resetForm();
      } else if (type === 'saveAndClose' || closeOnSubmit) {
        onClose && onClose();
      }
      return submitResult;
    } catch (err) {
      console.log('!!!!!Error in submit form >>>>', err?.message);
      onSubmitError && onSubmitError(err, formikContext);
    } finally {
      setLoading?.(false);
    }
  };

  const emptyInitialValues = useMemo(() => ({}), []);

  const formikProps = useFormik({
    initialValues: data || emptyInitialValues,
    onSubmit: onSubmitForm,
    enableReinitialize: true,
    validateOnMount: true,
    ...props,
  });

  useComputations({computations, _parentValues, onError}, formikProps);

  const setSubmitAction = (action, e) => {
    _setSubmitAction(typeof action === 'string' ? {type: action} : action);
    setTimeout(_ => {
      formikProps.handleSubmit(e);
    });
  };

  return {
    loading,
    mode,
    isDualMode,
    setEditMode,
    readOnly,
    setSubmitAction,
    ...props,
    ...formikProps,
    computations,
  };
};
