import React from 'react';
import {useAuth} from '../../auth/hooks/useAuth';
import {BasicTable} from '../../../components/table/Table';
import {View} from '@unthinkable/react-core-components';
import {
  SecondaryTitleText,
  TitleText,
} from '@unthinkable/react-screen-components/src/styles/HeaderStyles';
import Config from '../../../Config';
import moment from 'moment';
import theme from '../../../theme/Theme';
import {Button} from '../../../components/button';
import {useNavigate} from 'react-router-dom';
import {resolveMessage} from './Utility';
export const AlertPopupTable = ({hide}) => {
  const {user} = useAuth();
  const navigate = useNavigate();
  return (
    <BasicTable
      api="/v1/OneLinerAlerts"
      sort={{
        _id: -1,
      }}
      onRowPress={({row}) => {
        const {navigationLink, navigationState} = row;
        navigationLink && hide && hide();
        navigationLink &&
          navigate(navigationLink, {
            state: navigationState,
          });
      }}
      renderRow={({row}) => {
        return (
          <View style={{paddingTop: 4, paddingBottom: 4, gap: 2}}>
            <TitleText styles={{title: {...theme.fonts.BODY1}}}>
              {row?.title}
            </TitleText>
            <SecondaryTitleText
              styles={{secondaryTitle: {...theme.fonts.CAPTION_SMALL}}}>
              {resolveMessage(row)}
            </SecondaryTitleText>
            <SecondaryTitleText
              styles={{
                secondaryTitle: {
                  ...theme.fonts.CAPTION_SMALL,
                  alignSelf: 'flex-end',
                },
              }}>
              {moment(row?.createdAt).format(Config.dateTimeFormat)}
            </SecondaryTitleText>
          </View>
        );
      }}
      filter={JSON.stringify({
        'watcher.userId': user._id,
      })}
      limit={6}
      renderFooter={() => {
        return (
          <Button
            text="View All"
            onPress={() => {
              hide && hide();
              navigate('/one-liner-alerts');
            }}
          />
        );
      }}
    />
  );
};
