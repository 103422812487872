import checkAuthorization from '../../services/accessService';
import { ErrorLogsTable } from './screens/ErrorLogsTable';
import { InsertionLogsForm } from './screens/InsertionLogsForm';
import { InsertionLogsTable } from './screens/InsertionLogsTable';
import { action, entity } from '../../constant/authorization';

const InsertionLogRoutes = [
  {
    name: 'Insertion Logs',
    path: '/patient/insertion-logs',
    component: InsertionLogsTable,
    children: [
      {
        name: 'Insert New File',
        path: '/patient/insertion-logs/insert-new-file',
        component: InsertionLogsForm,
      },
    ],
    visible: user => {
      return checkAuthorization(user, entity.patient, action.viewAll);
    },
  },
  {
    name: 'Error Logs',
    path: '/patient/insertion-logs/error-logs',
    component: ErrorLogsTable,
    visible: user => {
      return checkAuthorization(user, entity.patient, action.viewAll);
    },
  },
];

export default  {
  stack: InsertionLogRoutes,
};
