import {Col, Row, Text, View} from '@unthinkable/react-core-components';
import styled from 'styled-components';
export const CardContainer = styled(Row)`
  padding: 16px;
  border: 1px solid #d6dbe3;
  border-radius: 16px;
  flex: 1;
  gap: 32px;
  @media (max-width: 480px) {
    width:280px;
  }
`;

export const CardMessage = styled(Text)`
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  number-of-lines: 2;
`;

export const DueTaskCount = styled(Text)`
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  color: ${({dueColor}) => dueColor};
`;

export const OverDueTaskCount = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: ${({overdueColor}) => overdueColor};
`;

export const CountContainer = styled(Col)`
  justify-content: center;
  align-items: center;
`;

export const DueCardRow = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 16px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
  ${props => props.styles}
`;
