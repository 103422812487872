import React, {useMemo} from 'react';
import {
  Col,
  Image,
  Row,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {getRangeData, rangeCalculator} from './DateUtility';
import {DateRange} from './DateRange';
import {AutoComplete} from '@unthinkable/react-autocomplete';

const dateSelectorData = [
  {label: 'All', value: 'All'},
  {label: 'Day', value: 'Day'},
  {label: 'Week', value: 'Week'},
  {label: 'Month', value: 'Month'},
  {label: 'Custom', value: 'Custom'},
];

export const DateFilter = ({
  onChangeValue,
  value,
  options,
  styles = {},
  direction = 'row',
}) => {
  const {
    gap,
    previousIcon,
    nextIcon,
    containerStyle,
    textStyle,
    iconContainerStyle,
    iconStyle,
    autoCompleteTheme,
  } = styles;

  const {filterOf = 'All', from, to} = value || {};

  let displayRange = useMemo(() => rangeCalculator(value), [value]);

  const applyFilter = ({from, to, filterOf, source} = {}) => {
    value = {...value};
    if (source === 'Reset' || source === 'All') {
      value = {};
    } else {
      if (source !== 'Prev' && source !== 'Next') {
        const dateData = getRangeData({filterOf, from, to});
        if (
          filterOf == 'Day' ||
          filterOf == 'Week' ||
          filterOf == 'Month' ||
          filterOf == 'Year' ||
          filterOf == 'FY' ||
          (filterOf == 'Custom' && source !== 'Toggle')
        ) {
          from = dateData?.from;
          to = dateData?.to;
        } else if (filterOf == 'Custom' && source === 'Toggle') {
          if (value) {
            from = value.from;
            to = value.to;
          }
        }
      }
      value = {
        from: from,
        to: to,
        filterOf,
      };
    }

    onChangeValue && onChangeValue(value);
  };

  const selectorOnChange = val => {
    applyFilter({filterOf: val?.value, source: 'Toggle'});
  };

  const onDateChange = newValue => {
    onChangeValue({...value, ...newValue});
  };

  const PrevNextFunctionOfDate = ({source}) => {
    const dateData = getRangeData({source, from, to, filterOf});
    let _from = dateData?.from;
    let _to = dateData?.to;
    applyFilter({from: _from, to: _to, source, filterOf});
  };

  const getNavigationComponent = ({source, icon}) => {
    const style = {};
    if (source === 'Prev') {
      style.paddingLeft = 8;
    } else {
      style.paddingRight = 8;
    }
    return filterOf && filterOf !== 'All' && filterOf !== 'Custom' ? (
      <TouchableOpacity
        onPress={() => PrevNextFunctionOfDate({source})}
        style={{cursor: 'pointer', ...style, ...iconContainerStyle}}
        title={`${source} ${filterOf}`}>
        <Image source={icon} style={iconStyle} />
      </TouchableOpacity>
    ) : (
      void 0
    );
  };

  let component = void 0;
  let containerExtraStyle = direction === 'row' ? {} : {width: '100%'};
  if (displayRange) {
    component = (
      <View
        style={{
          ...containerStyle,
          ...containerExtraStyle,
        }}>
        {getNavigationComponent({
          source: 'Prev',
          icon: previousIcon,
        })}
        <Text style={textStyle}>{displayRange}</Text>
        {getNavigationComponent({
          source: 'Next',
          icon: nextIcon,
        })}
      </View>
    );
  }
  if (filterOf === 'Custom') {
    component = (
      <DateRange styles={styles} value={{from, to}} onDateChange={onDateChange}>
        <View
          style={{
            ...containerStyle,
            ...containerExtraStyle,
          }}>
          <Text style={textStyle}>{displayRange}</Text>
        </View>
      </DateRange>
    );
  }

  const Comp = direction === 'row' ? Row : Col;
  let compStyle = direction === 'row' ? {alignItems: 'center'} : {};
  return (
    <Comp gap={gap} style={compStyle}>
      <View
        style={{
          ...(direction === 'row' ? {width: 100} : {width: '100%'}),
        }}>
        <AutoComplete
          styles={autoCompleteTheme}
          options={options ? options : dateSelectorData}
          suggestionField="label"
          value={filterOf}
          onChangeValue={selectorOnChange}
          allowClear={false}
          showArrow
          fetch={() => options || dateSelectorData}
        />
      </View>
      {component}
    </Comp>
  );
};
