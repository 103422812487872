import React, {useRef, useState} from 'react';
import {Row, View, Wrap} from '@unthinkable/react-core-components';
import {ChipRender} from '@unthinkable/react-autocomplete/src/Chip';
import {Input, InputContainer, InputWrapper} from '@unthinkable/react-input';
import {ClearInputAction} from '@unthinkable/react-autocomplete/src/Actions';

const AutoCompleteRightAdornments = ({
  styles,
  searchValue,
  value = [],
  multiple,
  allowClear,
  onClearValue,
  readOnly,
  disabled,
}) => {
  if (disabled || readOnly) {
    return null;
  }
  return (
    <Row gap={8} style={{alignItems: 'center'}}>
      {searchValue === undefined &&
      value &&
      !multiple &&
      allowClear !== false ? (
        <ClearInputAction styles={styles} onPress={onClearValue} />
      ) : null}
    </Row>
  );
};

const AutoCompleteInputRender = ({
  searching,
  chipGap = 8,
  onChangeSearch,
  maxInputWidth = 150,
  onRemove,
  inputProps,
  isFloatingLabel,
  ...props
}) => {
  const {
    styles = {},
    searchValue,
    multiple,
    value = [],
    disabled,
    readOnly,
    getDisplayValue,
    inputRef,
  } = props;

  const handleKeyPress = event => {
    if (event.nativeEvent.key === 'Enter' && searchValue) {
      value?.push(searchValue);
      onChangeSearch('');
    }
  };

  const renderChip = chipProps => <ChipRender {...props} {...chipProps} />;

  const inputComponent = (
    <Input
      {...inputProps}
      ref={inputRef}
      styles={styles}
      value={
        multiple || searchValue !== undefined
          ? searchValue
          : getDisplayValue({value})
      }
      caretHidden={!searching}
      showSoftInputOnFocus={searching}
      onChangeText={onChangeSearch}
      maxWidth={multiple ? maxInputWidth : void 0}
      isFloatingLabel={false}
      onKeyPress={handleKeyPress}
    />
  );
  let inputRender = void 0;
  if (multiple) {
    inputRender = (
      <Wrap gap={chipGap}>
        {value?.map((doc, index) =>
          renderChip({
            value: doc,
            crossable: !(disabled || readOnly),
            onCross: () => {
              onRemove({item: doc, index});
            },
          }),
        )}
        <View style={{flex: 1, justifyContent: 'center'}}>
          {inputComponent}
        </View>
      </Wrap>
    );
  }

  return (
    <InputWrapper {...props}>
      <InputContainer {...props}>
        <Row style={{alignItems: 'center'}}>
          <View
            style={{
              flex: 1,
              overflow: 'auto',
              maxHeight: '300px',
              ...(isFloatingLabel ? {marginTop: 12} : {}),
            }}>
            {inputRender}
          </View>
          <AutoCompleteRightAdornments {...props} />
        </Row>
      </InputContainer>
    </InputWrapper>
  );
};

const BaseInputTextChip = ({...props}) => {
  const elementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(0);

  const onLayout = e => {
    const {width} = e.nativeEvent.layout;
    if (elementWidth !== width) {
      setElementWidth(width);
    }
  };

  return (
    <View ref={elementRef} onLayout={onLayout}>
      <AutoCompleteInputRender {...props} />
    </View>
  );
};

export default BaseInputTextChip;
