import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import theme from '../theme/Theme';
export const Paginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  align-self: center;
  font-family: OpenSans-Medium;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  padding: 0px;
  color: ${theme.colors.SIDEBAR_BACKGROUND_HIGH};
  margin: 10px 0px 0 0px;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: ${theme.colors.SECONDARY_MEDIUM} 1px solid;
  }
  li.previous {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 121px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  li a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.SIDEBAR_BACKGROUND_HIGH};
    width: 40px;
    height: 40px;
    text-decoration: none;
    user-select: none;
  }
  li.next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  li.break a {
    border-color: transparent;
  }
  li.active {
    background-color: ${theme.colors.SECONDARY_UPPER_LOW};
    border-color: transparent;
    color: ${theme.colors.BACKGROUND};
  }
  li.disabled a {
    cursor: default;
  }

  /* Adjustments for tablet screens */
  @media (max-width: 768px) {
    font-size: 12px;
    li a {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }

  /* Adjustments for mobile screens */
  @media (max-width: 480px) {
    li a {
      width: 25px;
      height: 25px;
      font-size: 10px;
    }
    li.previous {
      width: 80px;
    }
    li.next {
      width: 60px;
    }
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
