import {LOAD_SUCCESS, LOAD_ERROR, GET_PAGE_COUNT} from './TableReduxConsts.js';
export function loadSuccess(api, data) {
  return {
    type: LOAD_SUCCESS,
    api,
    data,
  };
}

export function loadError(api, error) {
  return {
    type: LOAD_ERROR,
    api,
    error,
  };
}

export function pageCountSuccess(api, pageCount) {
  return {
    type: GET_PAGE_COUNT,
    api,
    pageCount,
  };
}
