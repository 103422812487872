export const DiagnoseCategoryMapping = {
  A: 'Infectious And Parasitic Disease',
  B: 'Infectious And PArasitic Disease',
  C: 'Neoplasms',
  D: 'Neoplasms , Blood and Blood-forming Organs',
  E: 'Endocrine, Nutritional And Metabolic Disease',
  F: 'Mental and Behavioral Disorders',
  G: 'Nervous System',
  H: 'Eye and Adnexa ,Ear and Mastoid Process',
  I: 'Circulatory System',
  J: 'Respiratory System',
  K: 'Digestive System',
  L: 'Skin and Subcutaneous Tissue',
  M: 'Musculoskeletal and Connective Tissue',
  N: 'Genitourinary System',
  O: 'Pregnancy, childbirth and the puerperium',
  P: 'Certain Conditions Originating in the Perinatal Period',
  Q: 'Congenital Malformations , Deformations and Chromosomal Abnormalities',
  R: 'Symptoms,signs and abnormal clinical and laboratory findings',
  S: 'Injury,Poisoning nad Certain Other Consequences of External Causes',
  T: 'Injury,Poisoning and Certain Other Consequences of External Causes',
  V: 'External Causes Of Morbidity',
  W: 'External Causes Of Morbidity',
  X: 'External Causes Of Morbidity',
  Y: 'External Causes Of Morbidity',
  Z: 'Factors Influencing Health Status and Contact With Health Services',
};
