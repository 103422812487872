import React from 'react';
import {BaseTextRender, InputLabel} from '@unthinkable/react-input';
import {ChipRender} from './Chip';
import {getCascaderDisplayValue, getValueToDisplay} from './Utility';
import {Wrap} from '@unthinkable/react-core-components';

export const AutoCompleteRenderer = ({chip, ...props}) => {
  const displayValue = getValueToDisplay(props);
  let Component = <BaseTextRender {...props} value={displayValue} />;
  if (chip) {
    Component = <ChipRender {...props} value={displayValue} />;
  }
  return <InputLabel {...props}>{Component}</InputLabel>;
};

export const MultiAutoCompleteRenderer = props => {
  let {value, sort: sortby} = props;
  if (value && !Array.isArray(value)) {
    value = [value];
  }
  if (sortby && Array.isArray(value)) {
    const [key, order] = Object.entries(sortby)[0];
    value.sort((a, b) => (a[key] > b[key] ? 1 : -1) * order);
  }
  return (
    <InputLabel {...props}>
      {value ? (
        <Wrap gap={8}>
          {value.map((doc, index) => (
            <ChipRender
              {...props}
              key={index}
              getDisplayValue={() => {
                return getValueToDisplay({...props, value: doc});
              }}
              value={doc}
            />
          ))}
        </Wrap>
      ) : (
        void 0
      )}
    </InputLabel>
  );
};

export const CascaderRenderer = props => {
  return (
    <InputLabel {...props}>
      <BaseTextRender {...props} value={getCascaderDisplayValue(props)} />
    </InputLabel>
  );
};
