export const tableHeaderStyles = ({theme: {colors, fonts}}) => {
  return {
    container: {
      backgroundColor: colors.BACKGROUND,
      marginBottom: 8,
      flexWrap:'wrap',
      justifyContent:'flex-end'
    },
    title: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
    secondaryTitle: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};
