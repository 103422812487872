import React from 'react';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import { useNavigate, useParams} from 'react-router-dom';

export const FormRejectReason = props => {
  const navigate = useNavigate();
  const {taskId} = useParams();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.form1823}/${taskId}`,
    eventSourceId: 'newRequest',
    method: 'put',
    afterSubmit: () => {
      navigate(-1);
    }
  });


  return (
    <Form
      saveButtonText={'Save'}
      onSubmit={onSubmit}
      defaultValues={{
        status: 'Rejected',
      }}
      layoutFields={[
        {
          label: 'Reject Reason',
          fields: [
            {
              type: 'text',
              label: 'Reason',
              field: 'rejectedReason',
              required: true,
              size: 'large',
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
