import React, {useEffect, useState} from 'react';
import {View} from '@unthinkable/react-core-components';
import {
  DateFilter as DateFilterComponent,
  getZeroTimeDate,
  useFieldFilter,
} from '@unthinkable/react-filters';
import {useStyles} from '@unthinkable/react-theme';
import {
  AutoComplete,
  MultiAutoComplete,
} from '../formEditors/autocomplete/AutoComplete';
import {SearchFilter as SearchFilterComponent} from './SearchFilter';
import {
  AutoCompleteFilterStyles,
  DateFilterStyles,
  MultiAutoCompleteFilterStyles,
  SearchFilterStyles,
  SoloDateFilterStyles,
} from './theme';

import ReactDatePicker from 'react-date-picker';
import './DateFilter.css';

export const SearchFilter = ({onChangeFilter, ...props}) => {
  const styles = useStyles(SearchFilterStyles);
  const onChangeValue = _value => {
    onChangeFilter && onChangeFilter({type: 'search', value: _value});
  };
  return (
    <SearchFilterComponent
      {...props}
      styles={styles}
      onChangeValue={onChangeValue}
    />
  );
};
export const BooleanFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  dependentFilterFields,
  options = [
    {label: 'True', value: true},
    {label: 'False', value: false},
  ],
  ...props
}) => {
  const styles = useStyles(AutoCompleteFilterStyles);
  const {value, onChangeValue} = useFieldFilter({
    asParam,
    field,
    onChangeFilter,
    filterValues,
    dependentFilterFields,
    type: 'boolean',
  });
  return (
    <AutoComplete
      keyField="value"
      suggestionField="label"
      placeholder="Select"
      {...props}
      options={options}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};

export const AutoCompleteFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  params,
  dependentFilterFields,
  ...props
}) => {
  const styles = useStyles(AutoCompleteFilterStyles);
  if (typeof params === 'function') {
    params = params(filterValues);
  }
  const {value, onChangeValue} = useFieldFilter({
    asParam,
    field,
    onChangeFilter,
    filterValues,
    dependentFilterFields,
    type: 'autoComplete',
  });
  return (
    <AutoComplete
      {...props}
      params={params}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};

export const MultiAutoCompleteFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  params,
  dependentFilterFields,
  ...props
}) => {
  const styles = useStyles(MultiAutoCompleteFilterStyles);
  if (typeof params === 'function') {
    params = params(filterValues);
  }
  const {value, onChangeValue} = useFieldFilter({
    asParam,
    field,
    onChangeFilter,
    filterValues,
    dependentFilterFields,
    type: 'multiAutoComplete',
  });
  return (
    <MultiAutoComplete
      {...props}
      params={params}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};
export const SpanFilter = ({
  field = 'span',
  placeholder = 'Select Span',
  options = ['Weekly', 'Monthly'],
  ...props
}) => {
  return (
    <View style={{width: 122}}>
      <AutoCompleteFilter
        field={field}
        placeholder={placeholder}
        options={options}
        asParam
        allowClear={false}
        {...props}
      />
    </View>
  );
};

export const DateFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  dependentFilterFields,
  ...props
}) => {
  const styles = useStyles(DateFilterStyles);
  const {value, onChangeValue} = useFieldFilter({
    type: 'date',
    field,
    onChangeFilter,
    asParam,
    dependentFilterFields,
    filterValues,
  });
  return (
    <DateFilterComponent
      {...props}
      styles={styles}
      value={value}
      onChangeValue={onChangeValue}
    />
  );
};

export const SoloDateFilter = ({
  onChangeFilter,
  filterValues,
  field,
  asParam,
  placeholder,
  dependentFilterFields,
}) => {
  const styles = useStyles(SoloDateFilterStyles);
  const {value = {filterOf: 'Day'}, onChangeValue} = useFieldFilter({
    type: 'date',
    field,
    onChangeFilter,
    asParam,
    dependentFilterFields,
    filterValues,
  });
  const [date, setDate] = useState();

  useEffect(() => {
    if (date) {
      onChangeValue({
        filterOf: 'Day',
        from: getZeroTimeDate(date[0]),
        to: getZeroTimeDate(date[1], true),
      });
    } else {
      onChangeValue({filterOf: 'All'});
    }
  }, [date]);

  return (
    <View style={styles.containerStyle}>
      <ReactDatePicker
        value={date}
        onChange={setDate}
        showLeadingZeros
        className="react-datepicker"
        calendarClassName="calendar-popup"
        format={value ? 'MM/dd/yyyy' : 'dd'}
        dayPlaceholder={placeholder}
        monthPlaceholder=""
        yearPlaceholder=""
        returnValue="range"
      />
    </View>
  );
};
