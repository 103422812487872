import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../components/button';
import {Table} from '../../../components/table';
import images from '../../../assets/images';
import Config from '../../../Config';
import {useInvoke} from '../../../hooks';
import {
  ColumnTextRender,
  Icon,
  TaskCountChip,
  TaskCountContainer,
} from './FacilityTable.style';
import {TableHeader} from '../../../components/header/TableHeader';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {status} from '../../../constant/status';
import {View} from '@unthinkable/react-core-components';
import {GroupRowText} from '@unthinkable/react-table/src/styles/TableStyles';
import {Confirm} from '../../../components/confirm';
import buttonType from '../../../constant/buttonType';
import {useTheme} from '@unthinkable/react-theme';
import {useAuth} from '../../auth/hooks/useAuth';
import {action, entity} from '../../../constant/authorization';
import checkAuthorization from '../../../services/accessService';
import {useFilter} from '@unthinkable/react-filters';
import modalMessages from '../../../constant/ModalMessage';
import {routeLink} from '../../../constant';
import {employerType} from '../../../constant/employerType';
const {defaultListLimit} = Config;

const RenderEdit = () => {
  return <Icon src={images.edit} />;
};

const RenderDelete = ({row}) => {
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['facilities'],
  });
  if (row.status === status.inactive) {
    return (
      <Confirm
        title={modalMessages.activate.title}
        secondaryTitle={row.name}
        message={`${modalMessages.activate.message} Facility?`}
        confirmText="Confirm"
        onConfirm={async () => {
          await invoke({
            uri: `${routeLink.getFacilities}/` + row._id,
            data: {status: status.active},
          });
        }}>
        <Icon src={images.activation} />
      </Confirm>
    );
  }
  return (
    <Confirm
      title={modalMessages.deactivate.title}
      secondaryTitle={row.name}
      message={`${modalMessages.deactivate.message} Facility?`}
      confirmText="Confirm"
      onConfirm={async () => {
        await invoke({
          uri: `${routeLink.getFacilities}/` + row._id,
          data: {status: status.inactive},
        });
      }}>
      <Icon src={images.trash} />
    </Confirm>
  );
};

const RenderIsSubscribed = ({row}) => {
  return (
    <ColumnTextRender style={{fontWeight: 500}}>
      {row?.isSubscribed ? 'Yes' : 'No'}
    </ColumnTextRender>
  );
};

const RenderAdmin = ({row}) => {
  return (
    <View>
      <ColumnTextRender> {row?.primaryAdmin?.name} </ColumnTextRender>
      <GroupRowText> {row?.primaryAdmin?.email} </GroupRowText>
    </View>
  );
};

const RenderTaskCount = ({row}) => {
  const {dueContactTasks, dueMedRecTasks, dueVisitTasks, dueNotesTasks} = row;

  return (
    <TaskCountContainer>
      {(dueContactTasks && (
        <TaskCountChip variant="ContactTask">
          Contact {`(${dueContactTasks})`}
        </TaskCountChip>
      )) ||
        void 0}
      {(dueMedRecTasks && (
        <TaskCountChip variant="MedRecTask">
          MedRec {`(${dueMedRecTasks})`}
        </TaskCountChip>
      )) ||
        void 0}
      {(dueVisitTasks && (
        <TaskCountChip variant="VisitTask">
          Visit {`(${dueVisitTasks})`}
        </TaskCountChip>
      )) ||
        void 0}
      {(dueNotesTasks && (
        <TaskCountChip variant="NotesTask">
          Notes {`(${dueNotesTasks})`}
        </TaskCountChip>
      )) ||
        void 0}
    </TaskCountContainer>
  );
};

export const FacilityTable = props => {
  const {fromFacilityStats} = props;
  const {user} = useAuth();
  const navigate = useNavigate();
  const filterProps = useFilter({
    params: {
      isRedoxFacility: 'false',
    },
    filter: {},
  });
  const {searchValue, onChangeFilter, filterValues} = filterProps;
  const {params} = filterValues;
  const {AddFacility} = useTheme('icons');
  if (user?.employerType?.name === employerType.facility) {
    filterValues.filter = {
      ...filterValues.filter,
      _id: user?.facilityId?._id,
    };
  }
  if (fromFacilityStats && user?.employerType?.name === employerType.practice) {
    if (checkAuthorization(user, entity.facilityStats, action.view)) {
      filterValues.filter = {
        ...filterValues.filter,
        practices: user?.practiceId?._id,
      };
      params.primaryPractice = user?.practiceId?._id;
    } else if (checkAuthorization(user, entity.facilityStats, action.viewOwn)) {
      filterValues.filter = {
        ...filterValues.filter,
        _id: {$in: user?.facilityAssigned},
      };
    }
  }
  if (fromFacilityStats) {
    filterValues.filter = {
      ...filterValues.filter,
      status: status.active,
    };
  }
  if (params?.isRedoxFacility) {
    filterValues.filter = {
      ...filterValues.filter,
      isRedoxFacility: 'params?.isRedoxFacility',
    };
  } else {
    delete filterValues.filter.isRedoxFacility;
  }
  let myPatientField = '';
  if (user.practiceId) {
    myPatientField = checkAuthorization(user, entity.patient, action.viewAll)
      ? 'myPatientViewAll'
      : 'myPatients';
  } else if (user.facilityId) {
    myPatientField = checkAuthorization(user, entity.patient, action.viewAll)
      ? 'patientCount'
      : 'myPatients';
  } else {
    myPatientField = 'myPatients';
  }
  return (
    <Table
      params={{...params}}
      noDataText={'No facilities found'}
      renderHeader={() => (
        <TableHeader
          title={'Facilities'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search by name or phone'}
            />,
            !fromFacilityStats && (
              <AutoCompleteFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                field={'status'}
                options={[status.active, status.inactive]}
                key={'statusFilter'}
                placeholder={'Status'}
              />
            ),
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'isRedoxFacility'}
              options={[
                {label: 'Redox Facilities', value: 'true'},
                {label: 'Elitecare Facilities', value: 'false'},
              ]}
              key={'facilityFilter'}
              placeholder={'Facility'}
              keyField="value"
              suggestionField="label"
              asParam={true}
            />,
            checkAuthorization(user, entity.adminFacilities, action.create) &&
            !fromFacilityStats ? (
              <Button
                key="addFacility"
                text="Create New Facility"
                onPress={() => {
                  navigate('/facilities-company/add');
                }}
                icon={AddFacility}
                iconPosition="right"
                buttonType={buttonType.linkButtonWithBorder}
              />
            ) : null,
          ]}
        />
      )}
      search={searchValue}
      searchFields={['name', 'phoneNumber']}
      sort={{name: 1}}
      api={routeLink.getFacilities}
      eventSourceId={['facilities']}
      addOnFilter={JSON.stringify(filterValues.filter)}
      perPage={defaultListLimit}
      fields={{
        name: 1,
        phoneNumber: 1,
        faxNumber: 1,
        status: 1,
        primaryAdmin: {
          name: 1,
          email: 1,
        },
        dueVisitTasks: {
          _id: 1,
        },
        dueContactTasks: {
          _id: 1,
        },
        dueMedRecTasks: {
          _id: 1,
        },
        dueNotesTasks: {
          _id: 1,
        },
        patientCount: {
          _id: 1,
        },
        myPatients: {
          _id: 1,
        },
        type: 1,
        subscribedUpTo: 1,
        isSubscribed: 1,
        isRedoxFacility: 1,
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Facility Type',
          field: 'type',
          type: 'text',
          visible: !!fromFacilityStats,
        },
        {
          header: 'Primary Admin',
          render: RenderAdmin,
          visible: !fromFacilityStats,
        },
        {
          header: 'Phone No.',
          field: 'phoneNumber',
          type: 'text',
          visible: !fromFacilityStats,
        },
        {
          header: 'Fax No.',
          field: 'faxNumber',
          type: 'text',
          visible: !fromFacilityStats,
        },
        {
          header: 'Subscribed',
          render: RenderIsSubscribed,
          visible:
            !fromFacilityStats &&
            checkAuthorization(user, entity.adminFacilities, action.create),
        },
        {
          header: 'Status',
          field: 'status',
          type: 'text',
          width: 80,
          visible: !fromFacilityStats,
        },
        {
          header: 'Current Census',
          field: 'patientCount',
          type: 'number',
          width: 120,
          visible: !!fromFacilityStats,
        },
        {
          header: 'My Patients',
          field: myPatientField,
          type: 'number',
          visible: !!fromFacilityStats,
          width: 100,
        },
        {
          width: 200,
          header: 'Outstanding Tasks',
          render: RenderTaskCount,
          align: 'center',
          visible: !!fromFacilityStats,
        },
        {
          render: RenderEdit,
          onPress: ({row}) => {
            navigate('/facilities-company/edit/' + row?._id, {
              state: {
                facility: row,
                titleName: row.name,
              },
            });
          },
          width: 50,
          visible:
            !fromFacilityStats &&
            checkAuthorization(user, entity.adminFacilities, action.edit),
        },
        {
          render: RenderDelete,
          width: 50,
          visible:
            !fromFacilityStats &&
            checkAuthorization(user, entity.adminFacilities, action.delete),
        },
      ]}
      {...props}
    />
  );
};
