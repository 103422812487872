import React from 'react';
import {useParams} from 'react-router-dom';
import {Form} from '../../../components/form';
import {useFormSubmit} from '../../../hooks/useFormSubmit';
import {routeLink} from '../../../constant';
import {useAuth} from '../../auth/hooks/useAuth';
import {TCMContactStatus} from '../../../constant/status';
import {TableHeader} from '../../../components/header/TableHeader';
import {useLocation} from 'react-router-dom';

export const AddContactAttemptsModal = props => {
  const {EncounterId, TaskId} = useParams();
  const {user} = useAuth();
  const {state :{encounter, titleName}} = useLocation();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.getContactAttempt}`,
    method: 'post',
    eventSourceId: ['contactAttempts','tcmtasks'],
  });

  const validateDate = date => {
    const todayDate = new Date().setHours(12,0,0,0);
    const dischargeDate = new Date(encounter.dischargeDate).setHours(12,0,0,0);
    const selectDate = date.setHours(12,0,0,0);

    if (selectDate > todayDate) {
      return 'you can not set future Date';
    }
    if (dischargeDate > selectDate) {
      return 'you can not set date earlier than discharge date';
    }
  };

  return (
    <>
      <TableHeader
        title="Contact Attempts"
        secondaryTitle={titleName}
      />
      <Form
        api={`${routeLink.getContactAttempt}`}
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          taskId: TaskId,
          updatedBy: user._id,
          encounterId: EncounterId
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'mode',
                label: 'Contact Mode',
                api: routeLink.getContactMode,
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'person',
                label: 'Contact Person',
                api: routeLink.getContactPerson,
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'contactStatus',
                label: 'Contact Status',
                size: 'medium',
                required: true,
                options: Object.values(TCMContactStatus),
              },
              {
                type: 'date',
                field: 'date',
                label: 'Date of Contact',
                size: 'medium',
                required: true,
                validate: date => validateDate(date),
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
