import React from 'react';
import {useNavigate} from 'react-router-dom';
import Config from '../../../Config';
import {TableHeader} from '../../../components/header/TableHeader';
import Table from '../../../components/table/Table';
import {TextRender} from '@unthinkable/react-table';
import {ColumnTextRender} from '../../../modules/tasks/styles/TaskTable.style';
import moment from 'moment';
import {routeLink} from '../../../constant';
import {TASK_TYPE_TO_SHOW} from '../../../constant/taskType';
import {
  getDueDateColor,
  getPatientsFullName,
} from '../../../utils/commonMethod';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {
  CardBody,
  CardFooter,
  CardHeader,
} from '../../../components/card/taskCard';
import {CardStyle} from 'src/components/card/taskCard.style';

const renderLocation = ({row}) => {
  const location = row?.patientId?.currentLocation
    ? row?.patientId?.currentLocation?.name
    : row?.patientId?.currentLocationType?.value;
  return <TextRender value={location} />;
};

const tcmDueDate = ({row}) => {
  let dueDate = row?.tcmDueDate;
  if (!dueDate) {
    return;
  }
  if (row?.nextTcmDueDate) {
    if (new Date(row?.tcmDueDate) < new Date()) {
      dueDate = row?.nextTcmDueDate;
    }
  }
  return (
    <ColumnTextRender type={getDueDateColor(dueDate)}>
      {moment(dueDate).utc().format(`${Config.dateFormat}`)}
    </ColumnTextRender>
  );
};

const renderTaskType = ({row}) => {
  return <TextRender value={TASK_TYPE_TO_SHOW[row?.type]} />;
};

const renderTaskCard = ({row}) => {
  return (
    <CardStyle>
      <CardHeader item={row} />
      <CardBody item={row} />
      <CardFooter item={row} />
    </CardStyle>
  );
};

export const MyTaskTable = props => {
  const {taskFilter} = props;
  const navigate = useNavigate();
  const {isMobile} = useMediaQuery();
  let columns = [];
  if (isMobile) {
    columns = [
      {
        render: renderTaskCard,
      },
    ];
  } else {
    columns = [
      {
        header: 'Patient name',
        type: 'text',
        render: ({row}) => {
          const {FirstName, LastName, MiddleName} = row.patientId;
          return getPatientsFullName({FirstName, LastName, MiddleName});
        },
      },
      {
        header: 'Date Of Birth',
        field: 'patientId.DOB',
        type: 'date',
        formatOptions: {
          format: Config.dateFormat,
        },
      },
      {
        header: 'Task Type',
        render: renderTaskType,
      },
      {
        header: 'Location Of Patient',
        render: renderLocation,
      },
      {
        header: 'TCM Due Date',
        render: tcmDueDate,
      },
    ];
  }
  return (
    <Table
      onRowPress={({row}) => {
        navigate('/patient/details', {
          state: {
            patient: row.patientId,
            titleName: getPatientsFullName({
              FirstName: row.patientId.FirstName,
              MiddleName: row.patientId.MiddleName,
              LastName: row.patientId.LastName,
              Render: false,
            }),
          },
        });
      }}
      style={{
        border: isMobile,
        rowBorder: isMobile,
        columnPadding: isMobile,
      }}
      perPage={Config.defaultListLimit}
      noDataText={'No task found'}
      renderHeader={() => <TableHeader title={'Outstanding TCM Tasks'} />}
      sort={{isDateBounded: -1, onGoingTcmDueDate: 1, _id: 1}}
      api={`${routeLink.getTCMTask}`}
      eventSourceId="tcmtasks"
      addOnFilter={JSON.stringify({
        ...taskFilter,
      })}
      limit={Config.defaultListLimit}
      fields={{
        _id: 1,
        patientId: {
          _id: 1,
          FirstName: 1,
          MiddleName: 1,
          LastName: 1,
          FullName: 1,
          DOB: 1,
          groupId: {
            _id: 1,
            name: 1,
            PNGroupId: 1,
          },
          currentLocationType: {
            value: 1,
          },
          status: 1,
          residenceType: {
            value: 1,
          },
          currentLocation: {
            name: 1,
          },
          residence: 1,
          primaryPractice: {
            _id: 1,
          },
          latestEncounter: {
            taskInfo: {
              _id: 1,
            },
          },
        },
        owners: {
          _id: 1,
          name: 1,
        },
        type: 1,
        tcmDueDate: 1,
        nextTcmDueDate: 1,
        onGoingTcmDueDate: 1,
        isDateBounded: 1,
        encounterId: {
          _id: 1,
          dischargeDate: 1,
          taskInfo: 1,
        },
        taskId: 1,
      }}
      columns={columns}
      {...props}
    />
  );
};
