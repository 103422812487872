import React from 'react';
import {cilBell} from '@coreui/icons';
import {
  NotificationBadge,
  NotificationContainer,
  NotificationIcon,
} from './AppHeaderAction.style';

const AppHeaderAction = ({notifications}) => {
  return (
    <NotificationContainer>
      <NotificationIcon
        notifications={notifications}
        icon={cilBell}
        className="me-2"
      />
      <NotificationBadge position="top">{notifications || 0}</NotificationBadge>
    </NotificationContainer>
  );
};

export default AppHeaderAction;
