import {
  View,
  Styled,
  FlatList,
  Text,
  Row,
  TouchableOpacity,
  Image,
} from '@unthinkable/react-core-components';
import DotsSixVertical from '../../images/DotsSixVertical.svg';
import ExpandIcon from '../../images/GroupExpand.svg';
import CollapseIcon from '../../images/GroupCollapse.svg';

export const FlexContainer = Styled(View).withConfig({
  shouldForwardProp: prop => !['height', 'maxHeight'].includes(prop),
})`
  overflow: hidden;
  ${props => {
    const {height, maxHeight} = props;
    return height ? {height} : {flex: 1, maxHeight};
  }}  
`;

export const TableContainer = Styled(View).withConfig({
  shouldForwardProp: prop =>
    ![
      'autoHeight',
      'maxHeight',
      'variant',
      'borderColor',
      'borderWidth',
    ].includes(prop),
})`
  background-color: #FFFFFF;
  overflow: hidden;
  ${props => {
    const {autoHeight} = props;
    return autoHeight ? {maxHeight: '100%'} : {flex: 1};
  }}  
  ${({maxHeight}) => (maxHeight ? {maxHeight} : {})}  
  ${({styles}) => styles?.container}
  ${({variant}) => {
    if (variant === 'outline' || variant === 'bordered') {
      return {border: '1px solid #EEEEED', borderRadius: 8};
    }
  }}
  ${({styles, variant}) => styles?.variant?.[variant]?.container}
  ${({variant, borderColor, borderWidth}) => {
    if (variant === 'outline' || variant === 'bordered') {
      let style = {};
      if (borderColor) style.borderColor = borderColor;
      if (borderWidth) style.borderWidth = borderWidth;
      return style;
    }
  }}
  ${({styles}) => {
    if (styles.border) {
      return {
        border: 'none',
      };
    }
  }}
`;

export const StyledHeaderWrapper = Styled(View)`
  overflow:hidden;
  ${props => props.styles?.headerWrapper}
`;

export const StyledHeader = Styled(Row)`
  background-color:#F7F7F7;  
  overflow: hidden;
  ${props => props.styles?.header}
`;

export const StyledHeaderAggregate = Styled(Row)`
  background-color:#F6F6F6;
  overflow: hidden;
  ${props => props.styles?.headerAggregate}
`;

export const HeaderRow = Styled(Row).withConfig({
  shouldForwardProp: prop =>
    !['variant', 'borderColor', 'borderWidth'].includes(prop),
})`
  overflow: hidden;
  flex:1;
  ${({styles}) => styles?.headerRow}
  ${({variant}) => {
    if (variant === 'bordered') {
      return {border: '1px solid #EEEEED'};
    }
  }}
  ${({styles, variant}) => styles?.variant?.[variant]?.headerRow}
  ${({variant, borderColor, borderWidth}) => {
    if (variant === 'bordered') {
      let style = {};
      if (borderColor) style.borderColor = borderColor;
      if (borderWidth) style.borderWidth = borderWidth;
      style.borderTopWidth = 0;
      style.borderRightWidth = 0;
      style.borderLeftWidth = 0;
      return style;
    }
  }}

`;

export const HeaderColumnRow = Styled(Row)`
  gap: 8px;
  align-items: center;
  ${props => props.styles?.headerColumnRow}
`;

export const HeaderText = Styled(Text).withConfig({
  shouldForwardProp: prop => !['textAlign'].includes(prop),
})`
  color: #9E9E9E;
  font-size: 12px;
  line-height: 16px;
  justify-content: center;
  ${props => props.styles?.headerText}
  ${props => (props.textAlign ? {textAlign: props.textAlign} : {})};
`;

export const HeaderIcon = Styled(Image)
  .attrs(props => ({
    source: props.icon,
  }))
  .withConfig({
    shouldForwardProp: prop => !['icon'].includes(prop),
  })`
  height: 16px;
  width: 16px;
  ${props => props.styles?.headerIcon}
`;

export const HeaderAggregateText = Styled(Text).withConfig({
  shouldForwardProp: prop => !['textAlign'].includes(prop),
})`
  color: #9E9E9E;
  font-size: 12px;
  line-height: 16px;
  justify-content: center;
  ${props => props.styles?.headerAggregateText}
  ${props => (props.textAlign ? {textAlign: props.textAlign} : {})};
`;

export const StyledFooter = Styled(Row)`
  background-color:#F7F7F7;  
  overflow: hidden;
  ${props => props.styles?.footer}
`;

export const StyledTable = Styled(FlatList)`
  ${props => props.styles?.list}
`;

export const StyledRow = Styled(Row).withConfig({
  shouldForwardProp: prop =>
    ![
      'index',
      'level',
      'skipRowBorder',
      'variant',
      'borderColor',
      'borderWidth',
      'rowWidth',
    ].includes(prop),
})`
  ${({styles}) => styles?.row}
  ${({variant, skipRowBorder}) => {
    if (variant === 'bordered') {
      return {border: '1px solid #EEEEED'};
    } else if (!skipRowBorder) {
      return {borderTop: '1px solid #EEEEED'};
    }
  }}
  ${({styles, variant}) => styles?.variant?.[variant]?.row}
  ${({skipRowBorder, variant}) =>
    skipRowBorder && variant !== 'none' ? {borderWidth: 0} : {}}
  ${({variant, borderColor, borderWidth, skipRowBorder}) => {
    if (variant === 'bordered' || !skipRowBorder) {
      let style = {};
      if (borderColor) style.borderTopColor = borderColor;
      if (borderWidth) style.borderTopWidth = borderWidth;
      style.borderBottomWidth = 0;
      style.borderRightWidth = 0;
      style.borderLeftWidth = 0;
      return style;
    }
  }}
  ${({rowWidth}) => (rowWidth ? {width: rowWidth} : {})}
  ${({index, level, skipRowBorder}) =>
    index === 0 && !level && !skipRowBorder ? {borderWidth: 0} : {}}
  &: hover {
    ${props => `background-color: ${props.styles?.rowHoverColor || '#F6F6F6'}`}
  }
  ${({styles}) => {
    if (styles?.rowBorder) {
      return {
        border: 'none',
        margin: '10px',
      };
    }
  }}
`;

export const StyledHeaderColumnGroup = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['index', 'variant', 'borderColor', 'borderWidth'].includes(prop),
})`
  padding: 8px 12px;
  overflow: hidden;
  align-items: center;
  ${({styles}) => styles?.columnGroup}
  ${({variant}) => {
    if (variant === 'bordered') {
      return {border: '1px solid #EEEEED'};
    }
  }}
  ${({styles, variant}) => styles?.variant?.[variant]?.columnGroup}
  ${({index, variant, borderColor, borderWidth}) => {
    if (variant === 'bordered') {
      let style = {};
      if (borderColor) style.borderColor = borderColor;
      if (borderWidth) style.borderWidth = borderWidth;
      if (index === 0) style.borderLeftWidth = 0;
      style.borderTopWidth = 0;
      style.borderRightWidth = 0;
      return style;
    }
  }}
`;

export const StyledHeaderColumnGroupText = Styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: #1A1A1A;
  font-weight: 500;
  ${props => props.styles?.columnGroupText}
`;

export const StyledCommonColumn = Styled(View).withConfig({
  shouldForwardProp: prop =>
    ![
      'minWidth',
      'width',
      'align',
      'index',
      'variant',
      'borderColor',
      'borderWidth',
    ].includes(prop),
})`
  padding: 8px 12px;
  overflow: hidden;
  justify-content: center;
  ${({styles}) => styles?.column}
  ${({variant}) => {
    if (variant === 'bordered') {
      return {border: '1px solid #EEEEED'};
    }
  }}
  ${({styles, variant}) => styles?.variant?.[variant]?.column}
  ${({index, level, variant, borderColor, borderWidth}) => {
    if (variant === 'bordered') {
      let style = {};
      if (borderColor) style.borderColor = borderColor;
      if (borderWidth) style.borderWidth = borderWidth;
      if (index === 0 && (level === 0 || !level)) style.borderLeftWidth = 0;
      style.borderTopWidth = 0;
      style.borderRightWidth = 0;
      style.borderBottomWidth = 0;
      return style;
    }
  }}
  ${props => {
    const {width, minWidth} = props;
    if (width) {
      return {width};
    }
    return {flex: 1, minWidth};
  }}
  ${({align}) => {
    if (align === 'right') {
      return {alignItems: 'flex-end'};
    } else if (align === 'center') {
      return {alignItems: 'center'};
    }
  }}
`;

export const StyledColumn = Styled(StyledCommonColumn)`
  ${({styles}) => styles?.rowColumn}
  ${({variant, styles}) => styles?.variant?.[variant]?.rowColumn}
  ${({styles}) => {
    if (styles.columnPadding) {
      return {padding: '0px'};
    }
  }}  

`;
export const StyledHeaderColumn = Styled(StyledCommonColumn)`
  ${({styles}) => styles?.headerColumn}
  ${({variant, styles}) => styles?.variant?.[variant]?.headerColumn}
`;

export const StyledHeaderAggregateColumn = Styled(StyledCommonColumn)`
  ${({styles}) => styles?.headerAggregateColumn}
  ${({variant, styles}) => styles?.variant?.[variant]?.headerAggregateColumn}
`;

export const ColumnText = Styled(Text).withConfig({
  shouldForwardProp: prop => !['variant', 'textAlign', 'color'].includes(prop),
})`
  font-size: 14px;
  line-height: 20px;
  color: #19191A;
  ${props => props.styles?.rowText}
  ${props =>
    (props.variant && props.styles?.columnVariant?.[props.variant]) || {}};
  ${props => (props.textAlign ? {textAlign: props.textAlign} : {})};
  ${({color}) => (color ? {color} : {})}
`;

export const SeparatorText = Styled(Text)`
  font-size: 14px;
  color: #19191A;
  ${props => props.styles?.separatorText}
`;

export const ExpandCollapseIcon = Styled(Image)
  .attrs(props => {
    const {expanded, styles} = props;
    return {
      source: expanded
        ? styles?.expandIcon || ExpandIcon
        : styles?.collapseIcon || CollapseIcon,
    };
  })
  .withConfig({
    shouldForwardProp: prop => !['expanded'].includes(prop),
  })`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  ${props => props.styles?.expandCollapseIcon}
`;

export const GroupContainer = Styled(View).withConfig({
  shouldForwardProp: prop => !['rowWidth'].includes(prop),
})`
  ${props => props.styles?.groupContainer}
  ${({rowWidth}) => (rowWidth ? {width: rowWidth} : {})}
`;

export const GroupRowContainer = Styled(TouchableOpacity)`
  border-bottom: 1px solid #EEEEED;
  align-items: center;
  padding: 8px 12px;
  overflow:hidden;
  flex-direction: row;
  ${props => props.styles?.groupRow}
`;

export const GroupRowContent = Styled(Row)`
  flex: 1;
  overflow: hidden;
  align-items: center;
  ${props => props.styles?.groupRowContent}
`;

export const GroupRowText = Styled(Text)`
  flex: 1;
  overflow: hidden;
  justify-content: center;
  color: #9E9E9E;
  font-size: 12px;
  line-height: 16px;
${props => props.styles?.groupRowText}
`;

export const GroupItemsContainer = Styled(View)`
  overflow:hidden;
  ${props => props.styles?.groupItemsContainer}
`;

export const RecursiveColumnIndent = Styled(View).withConfig({
  shouldForwardProp: prop => !['indentWidth', 'level'].includes(prop),
})`
  ${({level, indentWidth = 20}) => {
    return {paddingLeft: level * indentWidth};
  }}
`;

export const RecursiveIconContainer = Styled(View)`
  width: 24px;
  align-items: center;
  justify-content: center;
  padding-right: 12px;
  ${props => props.styles?.recursiveIconContainer}
`;

export const RecursiveIcon = Styled(Image)
  .attrs(props => {
    const {expanded, styles} = props;
    return {
      source: expanded
        ? styles?.recursiveExpandIcon || ExpandIcon
        : styles?.recursiveCollapseIcon || CollapseIcon,
    };
  })
  .withConfig({
    shouldForwardProp: prop => !['expanded'].includes(prop),
  })`
  height: 16px;
  width: 16px;
  ${props => props.styles?.recursiveIcon}
`;

export const RecursiveNoData = Styled(View)`
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: #A3A3A3;
  ${props => props.styles?.recursiveNoData}
`;

export const RowIndent = Styled(TouchableOpacity).withConfig({
  shouldForwardProp: prop =>
    !['rowIndentation', 'connectorIndentation'].includes(prop),
})`
  ${props => (props?.rowIndentation ? {paddingLeft: props.rowIndentation} : {})}
  ${props =>
    props?.connectorIndentation ? {marginLeft: props.connectorIndentation} : {}}
`;

export const VerticalLine = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['rowIndentation', 'connectorIndentation'].includes(prop),
})`
  position: absolute;
  left: ${props => props.rowIndentation - props.connectorIndentation}px;
  width: 0px;
  border-left-color: #F0F0F0;
  border-left-width: 1px;
  height: 100%;
  ${props => props.styles?.verticalLine}
`;

export const IndentedConnector = Styled(View).withConfig({
  shouldForwardProp: prop =>
    !['connectorIndentation', 'connectorHeight'].includes(prop),
})`
  position: absolute;
  width:${props => props.connectorIndentation}px;
  height: ${props => props.connectorHeight}px;
  border-left-color: #F0F0F0;
  border-left-width: 1px;
  border-bottom-color: #F0F0F0;
  border-bottom-width: 1px;
  border-bottom-left-radius: 8px;
  left: -${props => props.connectorIndentation}px;
  ${props => props.styles?.indentedConnector}
`;

export const AbsoluteLoaderContainer = Styled(View)`
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  align-items: center;
  justify-content: center;
`;

export const FixLoaderContainer = Styled(Row)`
  padding:12px;
  justify-content: center;
`;

export const FetchMoreLoaderContainer = Styled(View)`
  position:absolute;
  bottom:25px;
  left:0;
  right:0;
  align-items: center;
`;

export const NoDataContainer = Styled(View).withConfig({
  shouldForwardProp: prop => !['autoHeight'].includes(prop),
})`
  padding:12px;
  justify-content: center;
  align-items: center;
  ${props => (props.autoHeight ? {} : {flex: 1})}
  ${props => props.styles?.noDataContainer}
`;

export const NoDataText = Styled(Text)`
  color: #9E9E9E;
  font-size: 14px;
  line-height: 20px;
  ${props => props.styles?.noDataText}
`;

export const DraggableRowContainer = Styled(View)`
  background-color:${props =>
    props.isDragging ? props.styles?.rowHoverColor || '#F6F6F6' : void 0};
  ${props => (props.isDragging ? props.styles?.draggingRow : {})}
`;

export const DraggableIconContainer = Styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
  justify-content: center;
  opacity: ${props => (props.isDragging ? 1 : 0)};
  &:hover {
    opacity: 1;
  }
  ${props => props.styles?.draggableIconContainer}
`;

export const DraggableIcon = Styled(Image).attrs(props => ({
  source: props.styles?.draggableIcon || DotsSixVertical,
}))`
  height:20px;
  ${props => props.styles?.draggableIconStyle}
`;
