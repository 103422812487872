import React from 'react';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {useLocation, useParams} from 'react-router-dom';
import {TableHeader} from '../../../components/header/TableHeader';
import {status} from '../../../constant/status';
import {employerType} from '../../../constant/employerType';

export const CompleteVisitTask = props => {
  const {taskId} = useParams();
  const {
    state: {encounter, patient},
  } = useLocation();
  const {onSubmit} = useFormSubmit({
    uri: routeLink.completeVisitTask,
    eventSourceId: ['completeVisitTask', 'visitAttempt', 'tcmtasks'],
  });

  const validateDate = date => {
    const todayDate = new Date().setHours(12, 0, 0, 0);
    const dischargeDate = new Date(encounter.dischargeDate).setHours(
      12,
      0,
      0,
      0,
    );
    const selectDate = date.setHours(12, 0, 0, 0);

    if (selectDate > todayDate) {
      return 'you can not set future Date';
    }
    if (dischargeDate > selectDate) {
      return 'you can not set date earlier than discharge date';
    }
  };

  return (
    <>
      <TableHeader title="Complete Task" />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        mode="edit"
        beforeSubmit={data => ({
          data: {
            ...data.data,
            status: 'Complete',
          },
        })}
        api={routeLink.completeVisitTask + `/${taskId}`}
        fields={{
          updatedBy: {
            user: {
              name: 1,
              email: 1,
            },
          },
          VisitDateTime: 1,
          status: 1,
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'updatedBy',
                label: 'User',
                api: routeLink.getCareTeamUsers,
                suggestionField: 'name',
                secondarySuggestionField: 'email',
                size: 'medium',
                getDisplayValue: ({value}) => {
                  return value?.name;
                },
                fields: {
                  user: {
                    name: 1,
                    email: 1,
                    practiceId: 1,
                    facilityId: 1,
                  },
                },
                filter: JSON.stringify({
                  patient: `${patient._id}`,
                  status: status.active,
                  primaryPractice: patient?.primaryPractice?._id,
                  'employerType.name': employerType.practice,
                  residence: patient?.residence?._id,
                  practices: patient?.practices,
                  currentLocation: patient?.currentLocation?._id,
                }),
                required: true,
              },
              {
                type: 'date',
                label: 'Date of visit',
                field: 'VisitDateTime',
                required: true,
                size: 'medium',
                validate: date => validateDate(date),
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
