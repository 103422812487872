import React, {useRef, useState} from 'react';
import {Platform, Row} from '@unthinkable/react-core-components';
import {FileRender} from './FileRender';
import {
  MultiFileActionIcon,
  MultipleFileActionContainer,
  MultipleFileActionText,
  MultipleFileContainer,
  MultipleFileScroll,
} from './Styles';

export const MultipleFileRender = ({
  styles,
  values,
  itemGap = 12,
  onRemove,
  AddComponent,
  selectable,
  selectedIndex,
  onSelectImage,
  ...props
}) => {
  const [scrollState, _setScrollState] = useState({});
  const scrollRef = useRef(null);

  const setScrollState = data => {
    _setScrollState({...scrollState, ...data});
  };

  const scrollTo = action => {
    if (Platform.OS !== 'web') {
      return;
    }
    let scrollWidth = 0;
    const maxScrollWidth =
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    if (action === 'right' && scrollRef.current.scrollLeft < maxScrollWidth) {
      if (maxScrollWidth - scrollRef.current.scrollLeft > 100) {
        scrollWidth = 100;
      } else {
        scrollWidth = maxScrollWidth - scrollRef.current.scrollLeft;
      }
      scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + scrollWidth;
    } else if (action === 'left' && scrollRef.current.scrollLeft > 0) {
      if (scrollRef.current.scrollLeft > 100) {
        scrollWidth = 100;
      } else {
        scrollWidth = scrollRef.current.scrollLeft;
      }
      scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - scrollWidth;
    }
  };

  const renderAction = ({scrollPosition, icon, text}) => {
    if (!scrollState.showScrollIcon) {
      return null;
    }
    const disabled = scrollState.scrollPosition === scrollPosition;
    return (
      <MultipleFileActionContainer
        onPress={() => {
          !disabled && scrollTo(scrollPosition);
        }}
        styles={styles}>
        {icon ? (
          <MultiFileActionIcon
            styles={styles}
            source={icon}
            scrollPosition={scrollPosition}
          />
        ) : (
          <MultipleFileActionText styles={styles}>
            {text}
          </MultipleFileActionText>
        )}
      </MultipleFileActionContainer>
    );
  };

  const onLayout = e => {
    if (Platform.OS !== 'web' || !scrollRef.current) {
      return;
    }
    const overflow =
      scrollRef.current.scrollWidth > scrollRef.current.offsetWidth;
    scrollState.showScrollIcon !== overflow &&
      setScrollState({showScrollIcon: overflow}, onScroll);
  };

  const onScroll = () => {
    if (Platform.OS !== 'web') {
      return;
    }
    if (!scrollRef.current.scrollLeft) {
      setScrollState({scrollPosition: 'left'});
    } else if (
      scrollRef.current.scrollLeft ===
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth
    ) {
      setScrollState({scrollPosition: 'right'});
    } else {
      setScrollState({scrollPosition: 'center'});
    }
  };

  if (!values || !values.length) {
    return null;
  }

  const {scrollPosition} = scrollState;
  return (
    <MultipleFileContainer styles={styles}>
      {!scrollPosition || scrollPosition === 'left'
        ? void 0
        : renderAction({
            scrollPosition: 'left',
            icon: styles?.scroll?.leftIcon,
            text: '<',
          })}

      <MultipleFileScroll
        styles={styles}
        ref={scrollRef}
        onScroll={onScroll}
        onLayout={onLayout}
        horizontal={true}
        contentContainerStyle={styles?.scroll?.contentContainer}
        showsHorizontalScrollIndicator={false}>
        <Row gap={itemGap}>
          {AddComponent}
          {values?.map((_value, index) => {
            return (
              <FileRender
                styles={styles}
                {...props}
                selected={selectable ? index === selectedIndex : void 0}
                value={_value}
                onRemove={onRemove ? () => onRemove({index}) : void 0}
                openInNewTab={!selectable}
                onPress={() => {
                  onSelectImage && onSelectImage(index);
                }}
              />
            );
          })}
        </Row>
      </MultipleFileScroll>
      {!scrollPosition || scrollPosition === 'right'
        ? void 0
        : renderAction({
            scrollPosition: 'right',
            icon: styles?.scroll?.rightIcon,
            text: '>',
          })}
    </MultipleFileContainer>
  );
};
