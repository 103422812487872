import React, {useEffect, useRef, useState} from 'react';
import {
  TableContainer,
  StyledTable,
  GroupContainer,
  GroupItemsContainer,
  StyledColumn,
  StyledHeaderAggregateColumn,
  StyledHeaderColumn,
  AbsoluteLoaderContainer,
  FetchMoreLoaderContainer,
  FixLoaderContainer,
  StyledRow,
  StyledHeader,
  StyledHeaderAggregate,
  HeaderRow,
  StyledHeaderColumnGroup,
  StyledHeaderColumnGroupText,
  FlexContainer,
  NoDataContainer,
  NoDataText,
  StyledHeaderWrapper,
} from './styles/TableStyles';
import {useColumns, useExpand, useSelection} from './hooks';
import {
  Platform,
  Row,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Column, ColumnAggregate, ColumnHeader} from './Column';
import {resolveExp} from '@unthinkable/react-utils';
import {useResolveProps} from './hooks/useResolveProps';
import {useFetchTableData} from '@unthinkable/react-data-handler';
import {DraggableRow, DraggableWrapper} from './Draggable';
import {RecursiveRowConnector} from './RecursiveRowConnector';
import {GroupRow} from './GroupRow';

const Table = ({
  styles = {},
  rowKey,
  loading: loadingProp,
  loadingSource: loadingSourceProp,
  onRowPress,
  recursiveKey,
  recursiveRowIndent,
  defaultExpanded,
  exclusiveExpand,
  renderExpandRow,
  bounces,
  removeClippedSubviews,
  keyboardShouldPersistTaps,
  showsVerticalScrollIndicator,
  showsHorizontalScrollIndicator,
  ListFooterComponent,
  extraData,
  onScroll: onScrollProp,
  onEndReachedThreshold = 0.8,
  maxHeight,
  height,
  variant = 'none',
  skipRowBorder,
  borderColor,
  borderWidth,
  draggable,
  autoHeight = true,
  skipNoData,
  renderNoData,
  noDataText = 'No Data Found.',
  numColumns,
  ...props
}) => {
  props = useResolveProps(props);
  const {
    renderHeader,
    renderFooter,
    renderTableHeader,
    renderTableFooter,
    renderLoader,
    renderRow,
    selection,
    columnStyles,
    columnFormats,
    columnRenders,
    allowDynamicGrouping,
  } = props;
  const ref = useRef(null);
  const headerRef = useRef(null);
  const headerAggregateRef = useRef(null);

  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [offsetWidth, setOffsetWidth] = useState(0);
  const {
    data,
    aggregates,
    fetchMore,
    loading = loadingProp,
    loadingSource = loadingSourceProp,
    setData,
    dynamicGroupRow,
  } = useFetchTableData(props);

  const groupRow = allowDynamicGrouping ? dynamicGroupRow : props.groupRow;

  const {columns, headerColumns, rowWidth} = useColumns({
    ...props,
    offsetWidth,
  });

  const {toggleExpand, isExpanded} = useExpand({
    defaultExpanded,
    exclusiveExpand,
  });

  const rowSelectionInfo = useSelection({
    rowKey,
    data,
    ...(selection === true ? {} : selection),
  });

  const renderProps = {...props, ...rowSelectionInfo, data, aggregates};

  useEffect(() => {
    if (Platform.OS === 'web' && ref.current) {
      if (ref.current.scrollHeight > ref.current.clientHeight) {
        if (!scrollBarWidth) {
          setScrollBarWidth(ref.current.offsetWidth - ref.current.clientWidth);
        }
      } else if (scrollBarWidth) {
        setScrollBarWidth(0);
      }
    }
  }, [data]);

  const getColumnProps = (
    {type, formatOptions, ...column} = {},
    rowProps = {},
  ) => {
    const render = type && columnRenders?.[type];
    const style = type && columnStyles?.[type];
    const formatInfo = type && columnFormats?.[type];
    if (formatOptions && typeof formatOptions === 'function') {
      formatOptions = formatOptions(rowProps);
    }

    if (formatInfo?.formatOptions && formatOptions) {
      formatOptions = {
        ...formatInfo.formatOptions,
        ...formatOptions,
      };
    }
    return {
      ...style,
      ...formatInfo,
      type,
      formatOptions,
      render,
      ...column,
    };
  };

  const renderItem = ({
    item: row,
    index,
    id,
    parentKey,
    level = 0,
    isLastChild,
  }) => {
    const rowKey = `${parentKey ? parentKey + '_' : ''}${id || index}`;

    const rowExpanded = isExpanded('row', rowKey);
    const toggleRowExpand = () => toggleExpand('row', rowKey);

    const recursiveChildren = row[recursiveKey || 'children'];
    const recursive = {
      expanded: isExpanded('recursive', rowKey),
      toggleExpand: () => toggleExpand('recursive', rowKey),
      hasChildren: recursiveChildren?.length ? true : false,
    };

    const rowProps = {
      styles,
      row,
      index,
      level,
      recursive,
      rowExpanded,
      toggleRowExpand,
      isLastChild,
      ...rowSelectionInfo,
    };

    let render = (
      <TouchableOpacity
        key={rowKey}
        onPress={() => {
          onRowPress && onRowPress({row: row, rowIndex: index});
        }}>
        {renderRow ? (
          renderRow(rowProps)
        ) : (
          <>
            <StyledRow
              styles={styles}
              index={index}
              level={level}
              skipRowBorder={skipRowBorder}
              variant={variant}
              borderColor={borderColor}
              borderWidth={borderWidth}
              rowWidth={!groupRow ? rowWidth : void 0}>
              {columns.map((column, colIndex) => {
                let {type} = column;
                if (type && typeof type === 'object') {
                  type = type.field ? resolveExp(row, type.field) : void 0;
                }
                const {width, minWidth, align, ...columnProps} = getColumnProps(
                  {
                    ...column,
                    type,
                  },
                  {row, index},
                );
                return (
                  <StyledColumn
                    key={colIndex}
                    styles={styles}
                    variant={variant}
                    borderColor={borderColor}
                    borderWidth={borderWidth}
                    index={colIndex}
                    align={align}
                    width={width}
                    minWidth={minWidth}>
                    <Column
                      getColumnProps={getColumnProps}
                      {...rowProps}
                      {...columnProps}
                    />
                  </StyledColumn>
                );
              })}
            </StyledRow>
            {rowExpanded && renderExpandRow
              ? renderExpandRow(rowProps)
              : void 0}
          </>
        )}
      </TouchableOpacity>
    );
    if (draggable) {
      render = (
        <DraggableRow
          styles={styles}
          draggable={draggable}
          draggableId={rowKey}
          index={index}>
          {render}
        </DraggableRow>
      );
    }

    if (recursiveChildren?.length) {
      render = (
        <>
          {render}
          {recursive.expanded &&
            recursiveChildren?.map((childRow, childIndex) => {
              return renderItem({
                item: childRow,
                index: childIndex,
                level: level + 1,
                parentKey: rowKey,
                isLastChild: recursiveChildren.length - 1 === childIndex,
              });
            })}
        </>
      );
    }
    if (recursiveRowIndent) {
      render = (
        <RecursiveRowConnector {...rowProps} styles={styles} {...props}>
          {render}
        </RecursiveRowConnector>
      );
    }
    return render;
  };

  const renderColumnAggregates = ({fixed, aggregates}) => {
    const hasAggregate = columns?.some(column => column.aggregate);
    return hasAggregate ? (
      <StyledHeaderAggregate styles={styles}>
        <HeaderRow
          styles={styles}
          ref={headerAggregateRef}
          variant={variant}
          borderColor={borderColor}
          borderWidth={borderWidth}>
          {columns.map((column, i) => {
            const {width, minWidth, align, ...columnProps} =
              getColumnProps(column);
            return (
              <StyledHeaderAggregateColumn
                key={i}
                styles={styles}
                align={align}
                width={width}
                minWidth={minWidth}
                variant={variant}
                borderColor={borderColor}
                borderWidth={borderWidth}>
                <ColumnAggregate
                  styles={styles}
                  column={columnProps}
                  aggregates={aggregates}
                />
              </StyledHeaderAggregateColumn>
            );
          })}
        </HeaderRow>
        {fixed ? <View style={{width: scrollBarWidth}} /> : void 0}
      </StyledHeaderAggregate>
    ) : null;
  };

  const renderHeaderColumns = ({fixed} = {}) => {
    const hasHeader = columns?.some(column => column.header);
    if (!hasHeader) {
      return null;
    }
    const renderColumns = (columns, level = 0) => {
      return columns.map((column, i) => {
        let {width, minWidth, header, align, children, ...columnProps} =
          getColumnProps(column);
        if (typeof header === 'string') {
          header = {label: header};
        }
        let {render = ColumnHeader, ...restHeader} = header || {};
        if (children?.length) {
          return (
            <View>
              <StyledHeaderColumnGroup
                key={i}
                styles={styles}
                index={i}
                variant={variant}
                borderColor={borderColor}
                borderWidth={borderWidth}>
                <StyledHeaderColumnGroupText styles={styles}>
                  {header.label}
                </StyledHeaderColumnGroupText>
              </StyledHeaderColumnGroup>
              <Row>{renderColumns(children, level + 1)}</Row>
            </View>
          );
        } else {
          return (
            <StyledHeaderColumn
              key={i}
              styles={styles}
              variant={variant}
              borderColor={borderColor}
              borderWidth={borderWidth}
              width={width}
              minWidth={minWidth}
              index={i}
              level={level}
              align={align}>
              {render
                ? render({
                    styles,
                    ...rowSelectionInfo,
                    ...columnProps,
                    ...restHeader,
                  })
                : void 0}
            </StyledHeaderColumn>
          );
        }
      });
    };

    return (
      <StyledHeader styles={styles}>
        <HeaderRow
          styles={styles}
          ref={headerRef}
          variant={variant}
          borderColor={borderColor}
          borderWidth={borderWidth}>
          {renderColumns(headerColumns)}
        </HeaderRow>
        {fixed ? <View style={{width: scrollBarWidth}} /> : void 0}
      </StyledHeader>
    );
  };

  const renderHeaderColumnsAndAggregates = ({aggregates, fixed}) => {
    return (
      <StyledHeaderWrapper styles={styles}>
        {renderHeaderColumns({fixed})}
        {renderColumnAggregates({aggregates, fixed})}
      </StyledHeaderWrapper>
    );
  };

  const renderGroupRow = ({item: row, index}) => {
    const expanded = isExpanded('group', index, groupRow);
    let {
      expandable = true,
      header,
      render,
      hideRow,
    } = groupRow || {};
    if (typeof hideRow === 'function') {
      hideRow = hideRow({row});
    }
    const groupData = resolveExp(row, groupRow.data);
    const aggregates = resolveExp(row, groupRow.aggregate || 'aggregates');
    const groupRowProps = {
      styles,
      row,
      index,
      groupRow,
      isExpanded,
      toggleExpand,
    };

    return (
      <GroupContainer styles={styles} rowWidth={rowWidth}>
        {hideRow ? (
          void 0
        ) : render ? (
          render(groupRowProps)
        ) : (
          <GroupRow {...groupRowProps} />
        )}
        {hideRow || expandable === false || expanded ? (
          <GroupItemsContainer styles={styles}>
            {header
              ? renderHeaderColumnsAndAggregates({
                  aggregates,
                })
              : void 0}
            {groupData?.map((dataRow, dataRowIndex) => {
              return renderItem({
                item: dataRow,
                index: dataRowIndex,
                id: `${index}_${dataRowIndex}`,
              });
            })}
          </GroupItemsContainer>
        ) : (
          void 0
        )}
      </GroupContainer>
    );
  };

  const fetchMoreLoader = () => {
    return renderLoader && loading && loadingSource === 'fetchMore' ? (
      <FetchMoreLoaderContainer>{renderLoader()}</FetchMoreLoaderContainer>
    ) : (
      void 0
    );
  };

  const loader = ({fixLoader} = {}) => {
    const LoaderContainer = fixLoader
      ? FixLoaderContainer
      : AbsoluteLoaderContainer;
    return renderLoader &&
      loading &&
      loadingSource !== 'reloading' &&
      loadingSource !== 'fetchMore' ? (
      <LoaderContainer>{renderLoader()}</LoaderContainer>
    ) : (
      void 0
    );
  };

  const onScroll = event => {
    onScrollProp && onScrollProp(event);
    if (Platform.OS === 'web') {
      if (headerRef.current) {
        headerRef.current.scrollLeft = event.scrollLeft;
      }
      if (headerAggregateRef.current) {
        headerAggregateRef.current.scrollLeft = event.scrollLeft;
      }
    }
  };

  const onLayout = e => {
    if (e?.nativeEvent?.layout?.width) {
      const newOffsetWidth = e.nativeEvent.layout.width;
      if (newOffsetWidth !== offsetWidth) {
        setOffsetWidth(newOffsetWidth);
      }
    }
  };

  const CustomRenderNoData = () => {
    return (
      <NoDataContainer autoHeight={autoHeight}>
        {renderNoData ? renderNoData() : <NoDataText>{noDataText}</NoDataText>}
      </NoDataContainer>
    );
  };

  let dataRender = (
    <StyledTable
      ref={ref}
      styles={styles}
      onScroll={onScroll}
      keyExtractor={(row, index) => row[rowKey] || index.toString()}
      data={data}
      renderItem={groupRow ? renderGroupRow : renderItem}
      extraData={extraData}
      onEndReachedThreshold={onEndReachedThreshold}
      onLayout={onLayout}
      onEndReached={fetchMore}
      bounces={bounces}
      ListFooterComponent={ListFooterComponent}
      removeClippedSubviews={removeClippedSubviews}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
      numColumns={numColumns}
    />
  );
  if (draggable) {
    dataRender = (
      <DraggableWrapper draggable={draggable} data={data} setData={setData}>
        {dataRender}
      </DraggableWrapper>
    );
  }

  let tableComponent = (
    <TableContainer
      styles={styles}
      variant={variant}
      maxHeight={maxHeight}
      autoHeight={autoHeight}
      borderColor={borderColor}
      borderWidth={borderWidth}>
      {renderTableHeader?.(renderProps)}
      {!groupRow?.header
        ? renderHeaderColumnsAndAggregates({aggregates, fixed: true})
        : void 0}
      {!skipNoData && !data?.length && loading === false ? (
        <CustomRenderNoData />
      ) : (
        dataRender
      )}
      {fetchMoreLoader()}
      {renderTableFooter?.(renderProps)}
    </TableContainer>
  );

  return (
    <>
      {renderHeader?.(renderProps)}
      <FlexContainer
        height={height}
        maxHeight={maxHeight}
        autoHeight={autoHeight}>
        {tableComponent}
        {loader({fixLoader: autoHeight && !data?.length})}
      </FlexContainer>
      {renderFooter?.(renderProps)}
    </>
  );
};

export default Table;
