import React from 'react';
import {FooterContainer} from './styles/HeaderStyles';

export const Footer = ({actions, gap = 8, styles, ...props}) => {
  return (
    <FooterContainer gap={gap} styles={styles}>
      {typeof actions === 'function' ? actions(props) : actions}
    </FooterContainer>
  );
};
