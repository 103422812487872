import React, {useEffect, useState} from 'react';
import {FormikProvider} from 'formik';
import {
  FormBody,
  FormGroupContainer,
  FormGroupLabel,
  InlineFormContainer,
  FormGroupRow,
  FormGroupImage,
  GroupButton,
  GroupButtonText,
  GroupButtonIcon,
  GroupButtonRow,
} from './FormStyles';
import Field from './Field';
import {useFormContext} from './FormContext';
import {Grid, GridItem} from '@unthinkable/react-layout';
import {Text, View} from '@unthinkable/react-core-components';
import NestedField from './NestedField';
import {Footer, Header} from '@unthinkable/react-screen-components';
import {hasValue, isMobile} from '@unthinkable/react-utils';
import {MultiStepForm} from './MultiStepForm';
import {EditAction, InlineSubmitButton, SubmitButton} from './FormActions';
import {useForm} from './hooks';

const useResolveFormProps = ({
  header,
  footer,
  skipDefaultSave,
  submitAction,
  editAction,
  ...props
}) => {
  const {styles, steps} = props;
  const context = useFormContext();
  const {readOnly, isDualMode} = context;

  if (typeof header === 'function') {
    header = header(context);
  }
  if (typeof header === 'string') {
    header = {title: header};
  }
  if (typeof footer === 'function') {
    footer = footer(context);
  }
  if (typeof footer === 'string') {
    footer = {title: footer};
  }
  if (typeof submitAction === 'string') {
    submitAction = {label: submitAction};
  }
  if (typeof editAction === 'string') {
    editAction = {label: editAction};
  }

  const submitActionButton = (
    <SubmitButton styles={styles?.headerButton} {...submitAction} />
  );

  let {actions} = header || {};
  if (typeof actions === 'function') {
    actions = actions(context);
  }
  if (isDualMode && readOnly) {
    actions = actions ? [...actions] : [];
    actions.push(<EditAction styles={styles?.headerButton} {...editAction} />);
  }
  if (!readOnly && !steps && !skipDefaultSave) {
    if (isMobile) {
      footer = {
        actions: [submitActionButton],
      };
    } else {
      actions = actions ? [...actions] : [];
      actions.push(submitActionButton);
    }
  }
  return {
    ...props,
    header: {...header, actions},
    footer,
  };
};

const FormGroupHeader = ({label, icon, styles}) => {
  if (!label) {
    return null;
  }
  return (
    <FormGroupRow gap={8} styles={styles}>
      {icon ? <FormGroupImage styles={styles} source={icon} /> : void 0}
      <FormGroupLabel styles={styles}>{label}</FormGroupLabel>
    </FormGroupRow>
  );
};

const GroupsLayout = ({styles, groups}) => {
  const context = useFormContext();
  const {values} = context;
  const [selectedGroups, setSelectedGroups] = useState({});

  groups = groups?.filter(group => {
    if (group && group.label) {
      const {visible = true} = group;
      if (typeof visible == 'function') return visible(context);
      return visible;
    }
  });

  const isValueExist = group => {
    let {valueFields, fields, field} = group;
    if (!valueFields && fields) {
      valueFields = fields?.map(fieldInfo => fieldInfo?.field);
    }
    if (!valueFields && field) {
      valueFields = [field];
    }
    if (Array.isArray(valueFields)) {
      return valueFields.some(field => field && hasValue(values[field]));
    }
  };

  const isGroupActive = group => {
    const {label} = group;
    if (selectedGroups[label] !== undefined) {
      return selectedGroups[label];
    }
    return isValueExist(group);
  };

  const toggleGroup = group => {
    const {label} = group;
    setSelectedGroups({
      ...selectedGroups,
      [label]: !isGroupActive(group),
    });
  };

  let activeGroups = groups.filter(group => isGroupActive(group));
  activeGroups = activeGroups?.map(activeGroup => {
    if (!activeGroup?.fields && activeGroup?.field) {
      let {label, ...rest} = activeGroup;
      return rest;
    } else {
      return activeGroup;
    }
  });
  return (
    <>
      <GroupButtonRow styles={styles} activeCount={activeGroups?.length}>
        {groups.map(group => {
          const label = group.label;
          const isActive = isGroupActive(group);
          const hasValue = isValueExist(group);
          return (
            <GroupButton
              key={label}
              styles={styles}
              isActive={isActive}
              onPress={() => toggleGroup(group)}>
              <GroupButtonText styles={styles} isActive={isActive}>
                {label}
              </GroupButtonText>
              {hasValue ? <GroupButtonIcon styles={styles} /> : void 0}
            </GroupButton>
          );
        })}
      </GroupButtonRow>
      {activeGroups?.length ? (
        <LayoutFieldsRender styles={styles} layoutFields={activeGroups} />
      ) : null}
    </>
  );
};

export const LayoutFieldsRender = ({layoutFields, level = 0, ...props}) => {
  let {styles, defaultSize, computations, _parentValues} = props;
  const nestedComputations = computations?.nestedComputations;
  const context = useFormContext();
  const {rowGap = 12, colGap = 12} = styles || {};

  if (!layoutFields || !Array.isArray(layoutFields)) {
    return (
      <View>
        <Text>{'Fields must be defined in Array in form'}</Text>
      </View>
    );
  }

  layoutFields = layoutFields?.filter(layoutField => {
    if (!layoutField) {
      return;
    }
    let {visible = true} = layoutField;
    if (typeof visible === 'function') {
      visible = visible(context);
    }
    return visible;
  });
  if (!layoutFields?.length) {
    return null;
  }

  return (
    <Grid colGap={colGap} rowGap={rowGap}>
      {layoutFields.map((info, index) => {
        if (Array.isArray(info)) {
          return (
            <GridItem key={index}>
              <LayoutFieldsRender {...props} layoutFields={info} />
            </GridItem>
          );
        } else {
          let {width, size, maxWidth, nested, ...restInfo} = info;
          const {field, render, fields, groups, ...rest} = restInfo;
          let renderComponent = void 0;
          if (nested) {
            renderComponent = (
              <NestedField
                {...restInfo}
                _parentValues={_parentValues}
                computations={nestedComputations?.[field]}
              />
            );
          } else if (field) {
            renderComponent = <Field key={field} {...restInfo} />;
          } else if (render) {
            renderComponent = render(restInfo, context);
          } else if (fields) {
            renderComponent = (
              <FormGroupContainer key={index} styles={styles} level={level}>
                <FormGroupHeader {...rest} styles={styles} />
                <LayoutFieldsRender
                  {...props}
                  layoutFields={fields}
                  level={level + 1}
                />
              </FormGroupContainer>
            );
          } else if (groups) {
            renderComponent = <GroupsLayout {...props} groups={groups} />;
          } else {
            renderComponent = (
              <View>
                <Text>Invalid Field</Text>
              </View>
            );
          }
          return (
            <GridItem
              key={index}
              size={size || defaultSize}
              width={width}
              maxWidth={maxWidth}>
              {renderComponent}
            </GridItem>
          );
        }
      })}
    </Grid>
  );
};

export const FormLayout = props => {
  let {
    header,
    footer = {},
    inline,
    steps,
    skipHeader,
    skipFooter,
    defaultActions,
    ...restProps
  } = useResolveFormProps(props);
  const {styles} = restProps;

  footer = {
    ...footer,
  };
  if (typeof footer.actions == 'function') {
    footer.actions = footer.actions(props);
  }
  footer.actions = [...(footer.actions || []), ...defaultActions];
  if (inline) {
    return (
      <InlineFormContainer gap={8} styles={styles}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <LayoutFieldsRender {...restProps} defaultSize={1} />
        </View>
        <InlineSubmitButton styles={styles.inlineFormButton} />
      </InlineFormContainer>
    );
  } else if (steps) {
    return (
      <>
        {header && !skipHeader ? (
          <Header styles={styles?.header} {...header} />
        ) : (
          void 0
        )}
        <MultiStepForm {...restProps} steps={steps} />
      </>
    );
  } else {
    return (
      <>
        {header && !skipHeader ? (
          <Header styles={styles?.header} {...header} />
        ) : (
          void 0
        )}
        <FormBody styles={styles}>
          <LayoutFieldsRender {...restProps} />
        </FormBody>
        {!skipFooter ? <Footer styles={styles?.footer} {...footer} /> : void 0}
      </>
    );
  }
};

export const Form = ({children, render, ...props}) => {
  const formContext = useForm(props);
  return (
    <FormikProvider value={formContext}>
      {render
        ? render(formContext)
        : typeof children === 'function'
        ? children(formContext)
        : children || <FormLayout {...formContext} />}
    </FormikProvider>
  );
};
