import Config from '../../Config';
import {Chat} from '@pubnub/chat';
export const onMFASuccess = async ({
  user,
  navigate,
  setAuth,
  toast,
  message,
  toastType,
}) => {
  const chat = await Chat.init({
    publishKey: Config.PUB_NUB_PUBLISH_KEY,
    subscribeKey: Config.PUB_NUB_SUBSCRIBE_KEY,
    userId: user.PNChatMemberId,
  });

  user.chatInstance = chat;
  setAuth({user: user});
  toast({
    message,
    type: toastType,
  });
  navigate('/');
};
