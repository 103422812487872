import React, {useState} from 'react';
import {Button, IconButton} from '@unthinkable/react-button';
import images from '../../assets/images';
import { useFormContext } from '@unthinkable/react-form';

const SubmitActionTypes = {
  save: {label: 'Save'},
  saveAndNew: {label: 'Save & New'},
  saveAndNext: {label: 'Save & Next'},
  saveAndClose: {label: 'Save & Close'},
};

export const SubmitButton = ({
  styles,
  type = 'saveAndClose',
  label = 'Save',
  disabled,
  disabledOnInvalid = true,
  variant,
  ...props
}) => {
  const {isValid, setSubmitAction, status} = useFormContext();
  const [loading, setLoading] = useState();
  if (variant) {
    styles = styles[variant];
  }

  return (
    <Button
      styles={styles }
      text={label}
      onPress={async () => {
        setSubmitAction?.({
          ...SubmitActionTypes[type],
          ...props,
          type,
          setLoading,
        });
      }}
      loading={loading}
      disabled={
        disabled || (disabledOnInvalid && !isValid) || status === 'computing'
      }
      icon={images.Submit}
      iconPosition="right"
    />
  );
};

export const InlineSubmitIconButton = ({styles}) => {
  const context = useFormContext();
  const {isValid, dirty, isSubmitting, submitForm, resetForm, status} = context;

  const disabled = !dirty || !isValid || status === 'computing';
  return (
    <IconButton
      styles={styles}
      icon={disabled ? images.Submit : images.SubmitActive}
      onPress={async e => {
        e.preventDefault();
        await submitForm?.();
        resetForm?.();
      }}
      loading={isSubmitting}
      disabled={disabled}
    />
  );
};
