import {SET_CONTEXT_PAGE_COUNT} from './pageCountReduxConsts.js';

export const initialState = {};

const pageCountReducer = function (state = initialState, actions) {
  switch (actions.type) {
    case SET_CONTEXT_PAGE_COUNT: {
      return {
        ...state,
        [actions.payload.key]: actions?.payload?.value,
      };
    }
    default:
      return {
        ...state,
        ...initialState,
      };
  }
};

export default pageCountReducer;
