import React, {useState} from 'react';
import {
  Image,
  Row,
  TouchableOpacity,
  TextInput,
  View,
} from '@unthinkable/react-core-components';

export const SearchFilter = ({
  value,
  onChangeValue,
  placeholder = 'Search',
  width,
  styles,
}) => {
  let {
    SearchIcon,
    CrossIcon,
    containerStyle,
    selectedContainerStyle,
    inputContainerStyle,
    inputStyle,
    iconStyle,
  } = styles;

  const [isFocused, setIsFocused] = useState(false);

  if (isFocused || value) {
    containerStyle = {...containerStyle, ...selectedContainerStyle};
  }

  return (
    <Row style={{...containerStyle, width}}>
      <View style={inputContainerStyle}>
        <TextInput
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          placeholder={placeholder}
          onChangeValue={onChangeValue}
          value={value}
          style={inputStyle}
        />
      </View>
      <TouchableOpacity
        style={{cursor: value ? 'pointer' : 'auto'}}
        onPress={() => {
          if (value) {
            onChangeValue('');
          }
        }}>
        <Image style={iconStyle} source={value ? CrossIcon : SearchIcon} />
      </TouchableOpacity>
    </Row>
  );
};
