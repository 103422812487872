import {
  AutoCompleteRenderer,
  MultiAutoCompleteRenderer,
} from '@unthinkable/react-autocomplete';
import {
  PasswordInput,
  PasswordRenderer,
  TextAreaInput,
  TextAreaRenderer,
  TextInput,
  TextRenderer as TextRendererComponent,
} from '../formEditors/Editors';
import {
  InputTextChip,
  AutoComplete,
  MultiAutoComplete,
} from '../formEditors/autocomplete/AutoComplete';
import {validateEmail, validatePassword} from './utils';
import {FileInput, FileInputRenderer} from '../formEditors/FileInputEditor';
import {DateRenderer} from '@unthinkable/react-date-picker';
import {DateInput} from '../formEditors/dateInput/DateInput';
import {DateTimeInput} from '../formEditors/dateInput/DateTimeInput';

export default {
  text: {
    Editor: TextInput,
    Renderer: TextRendererComponent,
  },
  textArea: {
    Editor: TextAreaInput,
    Renderer: TextAreaRenderer,
  },
  password: {
    validate: validatePassword,
    Editor: PasswordInput,
    Renderer: PasswordRenderer,
  },
  email: {
    validate: validateEmail,
    Editor: TextInput,
    Renderer: TextRendererComponent,
  },
  autoComplete: {
    Editor: AutoComplete,
    Renderer: AutoCompleteRenderer,
  },
  multiAutoComplete: {
    Editor: MultiAutoComplete,
    Renderer: MultiAutoCompleteRenderer,
  },
  inputTextChip: {
    Editor: InputTextChip,
    Renderer: MultiAutoCompleteRenderer,
  },
  file: {
    Editor: FileInput,
    Renderer: FileInputRenderer,
  },
  date: {
    Editor: DateInput,
    Renderer: DateRenderer,
  },
  dateTime: {
    Editor: DateTimeInput,
    Renderer: DateTimeInput,
  },
};
