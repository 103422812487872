import styled from 'styled-components';
export const HeaderContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 4px;
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const appHeaderStyles = () => {
  return {
    container: {
      marginTop: 32,
      alignSelf: 'flex-end',
      float: 'inline-end',
      padding: 8,
      maxHeight: 525,
      maxWidth: 400,
    },
  };
};
