import React from 'react';

import {
  Avatar,
  TaskCardBody,
  TaskCardFooter,
  TaskOwnerSlide,
  TaskType,
  TaskTypeText,
  Icon,
  ContentContainer,
  PartitionLine,
  Container,
  Label,
  CardStyle,
  CardHeaderStyle,
  LeftRectangle,
  HeaderContainer,
} from './taskCard.style';
import Config from '../../Config';
import {Header} from '@unthinkable/react-screen-components';
import images from '../../assets/images';
import moment from 'moment';
import {
  extractInitials,
  getDifference,
  getDueDateColor,
  getPatientsFullName,
} from '../../utils/commonMethod';
import theme from '../../theme/Theme';

export const CardHeader = ({item, actions}) => {
  return (
    <HeaderContainer>
      <LeftRectangle />
      <CardHeaderStyle>
        <Header
          title={getPatientsFullName({
            FirstName: item?.patientId?.FirstName,
            MiddleName: item?.patientId?.MiddleName,
            LastName: item?.patientId?.LastName,
            Render: true,
          })}
          secondaryTitle={moment(item?.patientId?.DOB)
            .utc()
            .format(Config.dateFormat)}
        />
        {actions?.length &&
          actions.map(action => {
            return action;
          })}
      </CardHeaderStyle>
    </HeaderContainer>
  );
};
const TextWithIcon = props => {
  const {text, icon, iconPosition = 'left'} = props;
  const {styles} = props;
  return (
    <ContentContainer style={styles} gap={14}>
      {iconPosition === 'left' ? <Icon src={icon} style={styles} /> : void 0}
      <Label>{text}</Label>
      {iconPosition === 'right' ? <Icon src={icon} style={styles} /> : void 0}
    </ContentContainer>
  );
};

export const CardBody = ({item}) => {
  let dueDate = item?.onGoingTcmDueDate;
  return (
    <TaskCardBody>
      {item?.patientId?.currentLocation?.name && (
        <TextWithIcon
          text={item?.patientId?.currentLocation?.name}
          icon={images.location}
        />
      )}
      <Container>
        <TaskType type={item.type}>
          <TaskTypeText type={item.type}>{item.type}</TaskTypeText>
        </TaskType>
        {dueDate && (
          <TextWithIcon
            text={`Due ${moment(dueDate).utc().format(Config.dateFormat)}`}
            icon={images.calender}
          />
        )}
      </Container>
      <PartitionLine />
    </TaskCardBody>
  );
};

const TaskOwners = ({item}) => {
  const owners = item?.owners?.slice(0, 3);
  return (
    <TaskOwnerSlide>
      {owners.map((owner, index) => {
        if (index === 2) {
          return (
            <Avatar key={owner.id} last={true}>
              {' '}
              +{item.owners.length - 2}
            </Avatar>
          );
        }
        return <Avatar key={owner.id}>{extractInitials(owner.name)}</Avatar>;
      })}
    </TaskOwnerSlide>
  );
};
const TimeRemaining = ({item}) => {
  const time = getDifference(item.onGoingTcmDueDate);
  const type = getDueDateColor(item.onGoingTcmDueDate);
  let color;
  if (type === 'Gray') {
    color = theme.colors.GRAY_TCM_FORE_COLOR;
  } else if (type === 'Yellow') {
    color = theme.colors.YELLOW_TCM_FORE_COLOR;
  } else {
    color = theme.colors.RED_TCM_FORE_COLOR;
  }
  return (
    <TextWithIcon
      styles={{
        'font-size': '14px',
        color: `${color}`,
      }}
      icon={images[`${type === 'Overdue' ? 'Red' : type}hourGlass`]}
      text={time}
    />
  );
};
export const CardFooter = ({item}) => {
  return (
    <TaskCardFooter>
      {item?.owners?.length && <TaskOwners item={item} />}
      {item?.onGoingTcmDueDate && <TimeRemaining item={item} />}{' '}
    </TaskCardFooter>
  );
};

export const renderTaskCard = ({row}) => {
  return (
    <CardStyle>
      <CardHeader item={row} />
      <CardBody item={row} />
      <CardFooter item={row} />
    </CardStyle>
  );
};
