import {useState} from 'react';
export const useAutoCompleteFetch = ({
  api,
  fields,
  filter,
  limit = 50,
  params,
  searchFields,
  suggestionField,
  secondarySuggestionField,
  colorField,
  form,
  fetch,
  sort,
}) => {
  const [loading, setLoading] = useState(false);

  const formProps = {values: form?.values};

  if (typeof params === 'function') {
    params = params(formProps);
  }
  if (typeof filter === 'function') {
    filter = filter({...formProps, params});
  }

  if (!fields) {
    fields = {
      [suggestionField]: 1,
    };
    if (secondarySuggestionField) {
      fields[secondarySuggestionField] = 1;
    }
    if (colorField) {
      fields[colorField] = 1;
    }
  }
  if (!searchFields) {
    searchFields = [suggestionField];
    if (secondarySuggestionField) {
      searchFields.push(secondarySuggestionField);
    }
  }

  const fetchData = async ({searchValue}) => {
    try {
      if (api) {
        if (!fetch) {
          throw new Error('fetch Must be defined in useAutoCompleteFetch');
        }
        setLoading(true);
        let fetchApi = api;
        let fetchProps = void 0;
        if (typeof api === 'function') {
          fetchApi = api({params, ...formProps});
          if (fetchApi.uri) {
            fetchApi = fetchApi.uri;
            fetchProps = fetchApi.props;
          }
        }
        let response = await fetch({
          uri: fetchApi,
          props: {
            ...fetchProps,
            fields,
            filter,
            params,
            limit,
            search: searchValue,
            searchFields,
            sort,
          },
        });
        return response.data;
      }
    } catch (err) {
      console.log('!!!!Error >>>>> ', err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    fetch: fetchData,
  };
};
