import {authorization, action} from '../../constant/authorization';
import {UserScreen} from './screens';
import {AddUser, EditUser} from './screens/UserForm';
import Settings from '../auth/screens/Settings';
import ChangePasswordForm from '../auth/screens/ChangePasswordForm';
import { cronSettings } from '../auth/screens/cronSettings';
const stackRoutes = [
  {
    name: 'Users',
    path: '/users',
    component: UserScreen,
    visible: user => {
      return authorization[user?.userType.name]?.User?.includes(action.view);
    },
  },
  {
    name: 'Add User',
    path: '/users/add',
    component: AddUser,
    visible: user => {
      return authorization[user?.userType.name]?.User?.includes(action.create);
    },
  },
  {
    name: 'Edit User',
    path: '/users/edit/:userId',
    component: EditUser,
    visible: user => {
      return authorization[user?.userType.name]?.User?.includes(action.edit);
    },
  },
  {
    name: 'Settings',
    path: '/settings',
    component: Settings,
    children:[
      {
        name: 'Change Password',
        path: '/settings/changePassword',
        component: ChangePasswordForm,
        screenOptions: {
          modalSize:'small',
        },
      }
    ]
  },
  {
    name:"Cron Settings",
    path:"/cron-setting",
    component:cronSettings,
    visible:(user)=>{
      return authorization[user?.userType.name]?.User?.includes(action.edit);
    }
  }
];

export default {
  stack: stackRoutes,
};
