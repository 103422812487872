export const inputStyles = ({theme: {colors, fonts}}) => {
  return {
    placeholderTextColor: '#7D7D82',
    container: {
      backgroundColor: colors.SURFACE1,
      borderColor: colors.OUTLINE,
    },
    input: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    focus: {
      borderColor: colors.UPPER_OUTLINE,
    },
    error: {
      borderColor: colors.ERROR_MEDIUM,
    },
    errorText: {
      color: colors.ERROR_HIGH,
    },
    helperText: {
      color: colors.NEUTRAL_MEDIUM,
      ...fonts.CAPTION,
    },
    text: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    label: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
      paddingBottom: 2,
    },
  };
};
