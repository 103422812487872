import {
  LOAD_INIT,
  LOAD_SUCCESS,
  LOAD_ERROR,
  RELEASE_TABLE_DATA,
  GET_PAGE_COUNT,
  SET_PAGE,
  PAGE_COUNT_INIT,
} from './TableReduxConsts.js';


export const initialState = {};

const tableReducer = function (state = initialState, actions) {
  switch (actions.type) {
    case LOAD_INIT:
      if (state?.[actions.payload.api]?.data?.length) {
        return {...state};
      }
      return {...state, [actions?.payload?.api]: {loading: true}};
    case LOAD_SUCCESS:
      return {
        ...state,
        [actions.api]: {
          ...state?.[actions?.api],
          data: actions.data || [],
          loading: false,
        },
      };
    case LOAD_ERROR:
      return {
        ...state,
        [actions.api]: {error: actions.error, loading: false},
      };
    case GET_PAGE_COUNT:
      return {
        ...state,
        [actions.api]: {
          ...state?.[actions.api],
          pageCount: actions?.pageCount,
        },
      };

    case SET_PAGE: {
      return {
        ...state,
        [actions.api]: {
          ...state?.[actions.api],
          page: actions?.page,
        },
      };
    }
    case RELEASE_TABLE_DATA:
      return {
        ...initialState,
      };
    case PAGE_COUNT_INIT: {
      return {
        ...state,
      };
    }
    default:
      return {
        ...state,
        ...initialState,
      };
  }
};

export default tableReducer;
