import React from 'react';
import {routeLink} from '../../../constant';
import Config from '../../../Config';
import {Table} from '../../../components/table';
import {TableHeader} from '../../../components/header/TableHeader';
import {useLocation} from 'react-router-dom';
import {useFilter} from '@unthinkable/react-filters';
import {Button} from '../../../components/button';
import images from '../../../assets/images';
import buttonType from '../../../constant/buttonType';
import {HttpAuthService} from '../../../services';
const {defaultListLimit} = Config;

export const ErrorLogsTable = props => {
  const {
    state: {insertionLogId},
  } = useLocation();

  const {filterValues} = useFilter();
  filterValues.filter = {
    ...filterValues.filter,
    insertionLog: insertionLogId,
  };
  return (
    <Table
      noDataText={'No Error found'}
      renderHeader={() => (
        <TableHeader
          title={'ErrorLogs'}
          actions={[
            <Button
              key="download failed insertions"
              text="Download Failed Insertions"
              icon={images.ArrowDownBlack}
              iconPosition="right"
              buttonType={buttonType.linkButtonWithBorder}
              onPress={async () => {
                await HttpAuthService.download(
                  routeLink.getFailedInsertionPatientData,
                  {
                    params: {
                      filter: filterValues.filter,
                      isFailedInsertion: true,
                    },
                  },
                );
              }}
            />,
          ]}
        />
      )}
      api={routeLink.errorLogs}
      eventSourceId="ErrorLogs"
      addOnFilter={JSON.stringify(filterValues.filter)}
      perPage={defaultListLimit}
      fields={{
        lineNumber: 1,
        error: 1,
      }}
      columns={[
        {
          header: 'Line Number',
          field: 'lineNumber',
          type: 'text',
        },
        {
          header: 'Error Message',
          field: 'error',
          type: 'text',
        },
      ]}
      {...props}
    />
  );
};
