import ChangePassword from './screens/ChangePassword';
import ForgotPasswordForm from './screens/ForgotPasswordForm';
import LoginForm from './screens/LoginForm';
import RegisterForm from './screens/RegisterForm';
import ResetPasswordForm from './screens/ResetPasswordForm';
import TwoFactorAuthenticationForm from './screens/TwoFactorAuthenticationForm';
import {VerifyAuthenticatorOtp} from './screens/VerifyAuthenticatorOtp';
import {VerifyEmailOtp} from './screens/VerifyEmailOtp';

const stackRoutes = [
  {
    name: '/login',
    path: '/login',
    component: LoginForm,
    options: {
      headerShown: false,
    },
  },
  {
    name: '/register',
    path: '/register',
    component: RegisterForm,
  },
  {
    name: '/change-password',
    path: '/change-password',
    component: ChangePassword,
  },
  {
    name: '/forgot-password',
    path: '/forgot-password',
    component: ForgotPasswordForm,
  },
  {
    name: '/reset-password',
    path: '/reset-password',
    component: ResetPasswordForm,
  },
  {
    name: '/two-factor-authentication',
    path: '/two-factor-authentication',
    component: TwoFactorAuthenticationForm,
  },
  {
    name: 'Verify OTP',
    path: '/mfa-authenticator',
    component: VerifyAuthenticatorOtp,
  },
  {
    name: 'Verify OTP',
    path: '/mfa-email',
    component: VerifyEmailOtp,
  },
];

export default {stack: stackRoutes};
