import theme from 'src/theme/Theme';
import styled from 'styled-components';

const TaskWiseTextColor = {
  ContactTask: '#188544',
  VisitTask: '#FF00B8',
  MedicationTask: '#2C62B4',
  InitialEligibility: '#188544',
  NotesTask: '#FF00B8',
  Form1823: '#2C62B4',
  'Initial Tcm Eligibility': '#188544',
  'Facility Assignment': '#2C62B4',
};

const TaskWiseBackgroundColor = {
  ContactTask: '#CDF4DD',
  VisitTask: '#FBE6FC',
  MedicationTask: '#E1F6FF',
  InitialEligibility: '#CDF4DD',
  NotesTask: '#FBE6FC',
  Form1823: '#E1F6FF',
  'Initial Tcm Eligibility': '#CDF4DD',
  'Facility Assignment': '#E1F6FF',
};

export const Icon = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;
export const TaskType = styled.div`
  background-color: ${props => TaskWiseBackgroundColor[props.type]};
  width: fit-content;
  height: 22px;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  border-radius: 4px;
`;

export const TaskTypeText = styled.div`
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  color: ${props => TaskWiseTextColor[props.type]};
`;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => (props.last ? '#C6D9FF' : '#000833')};
  color: ${props => (props.last ? '#1D68FF' : '#ffffff')};
  //   border: 0.83px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  // z-index: ;
  padding: 12px;
`;

export const TaskOwnerSlide = styled.div`
  display: flex;
  align-items: center;
  & > :not(:first-child) {
    margin-left: -5px;
  }
`;

export const TaskCardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
`;
export const PatientTaskCardBody = styled.div`
  display: flex;
  gap: 10px;
  padding: 8px;
`;

export const TaskCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  padding-top: 0px;
  @media (max-width: 375px) {
    padding:1px;
  }
`;

export const PartitionLine = styled.div`
  height: 1px;
  background-color: #e3e3e3;
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 4px;
`;
export const Label = styled.div`
  // max-width: 100px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-size: 12px;
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const CardStyle = styled.div`
  background-color: #ffffff;
  border: 1px solid ${theme.colors.OUTLINE};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const CardHeaderStyle = styled.div`
  width: 100%;
  padding-right: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-item: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 6px;
`;
export const LeftRectangle = styled.div`
  width: 2px;
  height: 40px;
  background-color: #161e2d;
`;
