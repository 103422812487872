// formatting.js
import moment from 'moment';
import 'intl';
import 'intl/locale-data/jsonp/en-IN';

const formatIndianCurrency = (value, {symbol = true, toFixed = 2} = {}) => {
  if (!value) {
    return value;
  }
  let formattedValue = value;
  let unit = '';

  if (value >= 10000000) {
    formattedValue = value / 10000000;
    unit = 'CR';
  } else if (value >= 100000) {
    formattedValue = value / 100000;
    unit = 'L';
  } else if (value >= 1000) {
    formattedValue = value / 1000;
    unit = 'K';
  }
  return `${symbol ? '₹' : ''}${formattedValue.toFixed(toFixed)}${unit}`;
};

const formatNumber = (
  value,
  {
    locale = 'en-IN',
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    useGrouping = true,
  } = {},
) => {
  if (value === undefined || value === null) return value;
  return new Intl.NumberFormat(locale, {
    useGrouping,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
};

const formatCurrency = (
  value,
  {
    locale = 'en-IN',
    style = 'currency',
    currency = 'INR',
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    useGrouping = true,
  } = {},
) => {
  if (value === undefined || value === null) return value;
  return new Intl.NumberFormat(locale, {
    style,
    currency,
    useGrouping,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
};

const formatDate = (value, {format = 'DD MMM', fromNow} = {}) => {
  if (value === undefined || value === null) return value;
  if (fromNow) {
    return moment(value).utc().fromNow();
  }
  return moment(value).utc().format(format);
};

const formatPercentage = (value, {toFixed = 2} = {}) => {
  if (value === undefined || value === null) return value;
  value = Number(value).toFixed(toFixed);
  return `${value}%`;
};

export default {
  formatNumber,
  formatDate,
  formatCurrency,
  formatPercentage,
  formatIndianCurrency,
};
