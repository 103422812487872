import {call, put, takeLatest} from 'redux-saga/effects';
import {fetchDataSuccess, fetchDataFailure} from './actions';
import {routeLink} from '../../../constant';
import {HttpAuthService} from '../../../services';
import {FETCH_DATA_REQUEST} from './chatReduxConstant';

const fetchData = async groupId => {
  const result = await HttpAuthService.get(routeLink.getUserBasedOnGroup, {
    params: {
      filter: {
        groupId: groupId,
      },
    },
  });
  const channelMemberResult = result.data.reduce((acc, channelMember) => {
    acc[channelMember.user.PNChatMemberId] = channelMember.user.name;
    return acc;
  }, {});
  return channelMemberResult;
};
function* fetchDataSaga(action) {
  const {groupId} = action.payload;
  try {
    const response = yield call(() => fetchData(groupId));
    yield put(fetchDataSuccess(response, groupId));
  } catch (err) {
    yield put(fetchDataFailure(err));
  }
}
export default function* chatSaga() {
  yield takeLatest(FETCH_DATA_REQUEST, fetchDataSaga);
}
