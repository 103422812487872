import {AutoCompleteStyles} from '../formEditors/autocomplete/AutoComplete.styles';
export const SearchFilterStyles = ({theme: {colors, fonts, icons, shadow}}) => {
  return {
    SearchIcon: icons.MagnifyingGlass,
    CrossIcon: icons.Cross,
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 100,
      alignItems: 'center',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
    },
    selectedContainerStyle: {
      borderColor: colors.UPPER_OUTLINE,
      ...shadow.shadow3,
    },
    inputContainerStyle: {flex: 1, overflow: 'hidden'},
    inputStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      overflow: 'hidden',
    },
    iconStyle: {height: 16, width: 16},
  };
};

export const SoloDateFilterStyles = ({
  theme: {colors, fonts, icons, shadow},
}) => {
  return {
    SearchIcon: icons.MagnifyingGlass,
    CrossIcon: icons.Cross,
    containerStyle: {
      borderWidth: 1,
      borderColor: colors.OUTLINE,
      borderRadius: 100,
      alignItems: 'center',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
      marginRight: 18,
    },
    selectedContainerStyle: {
      borderColor: colors.UPPER_OUTLINE,
      ...shadow.shadow3,
    },
    inputContainerStyle: {flex: 1, overflow: 'hidden'},
    inputStyle: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      overflow: 'hidden',
      border: 'none',
      outline: 'none',
    },
    iconStyle: {height: 16, width: 16},
  };
};

export const AutoCompleteFilterStyles = ({theme}) => {
  const styles = AutoCompleteStyles({theme});
  return {
    ...styles,
    container: {
      ...styles.container,
      minHeight: 36,
      paddingTop: 2,
      paddingBottom: 2,
    },
    floatingContainer: {
      ...styles.floatingContainer,
      top: 8,
    },
    activeFloatingContainer: {
      ...styles.activeFloatingContainer,
      top: 2,
    },
    input: {
      maxWidth: 170,
    },
  };
};

export const MultiAutoCompleteFilterStyles = ({theme}) => {
  const styles = AutoCompleteStyles({theme});
  return {
    ...styles,
    container: {
      ...styles.container,
      minHeight: 36,
      paddingTop: 2,
      paddingBottom: 2,
    },
    floatingContainer: {
      ...styles.floatingContainer,
      top: 8,
    },
    activeFloatingContainer: {
      ...styles.activeFloatingContainer,
      top: 2,
    },
  };
};

export const DateFilterStyles = ({theme}) => {
  const {fonts, colors, icons, shadow} = theme;
  return {
    previousIcon: icons.DatePrevious,
    nextIcon: icons.DateNext,
    gap: 12,
    iconContainerStyle: {
      height: 20,
      width: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
    iconStyle: {
      height: 12,
      width: 12,
    },
    containerStyle: {
      height: 36,
      borderRadius: 8,
      alignItems: 'center',
      flexDirection: 'row',
      borderColor: colors.NEUTRAL_MEDIUM,
      borderWidth: 1,
      width: 185,
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    textStyle: {
      ...fonts.ACTION,
      color: colors.NEUTRAL_HIGH,
      paddingLeft: 8,
      paddingRight: 8,
      textAlign: 'center',
      flex: 1,
    },
    calendarStyle: {
      dropdownStyle: {
        width: 560 + 48,
        height: 350,
        padding: 10,
        borderRadius: 4,
        backgroundColor: 'white',
        ...shadow.shadow1,
      },
      header: {
        containerStyle: {
          flexDirection: 'row',
        },
        prevContainerStyle: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        },
        nextContainerStyle: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
        },
        titleTextStyle: {
          color: colors.NEUTRAL_HIGH,
          ...fonts.BODY1,
        },
        prev: {
          prevIcon: icons.DatePrevious,
          imageStyle: {},
          viewStyle: {
            width: 40,
            height: 20,
            // backgroundColor: colors.ACCENT1_LOW,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            // borderRadius: 4,
          },
          textStyle: {
            color: colors.NEUTRAL_HIGH,
          },
        },
        next: {
          nextIcon: icons.DateNext,
          imageStyle: {},
          viewStyle: {
            width: 40,
            height: 20,
            // backgroundColor: colors.ACCENT1_LOW,
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            // borderRadius: 4,
          },
          textStyle: {
            color: colors.NEUTRAL_HIGH,
          },
        },
      },
      footer: {
        containerStyle: {
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          flex: 1,
        },
      },

      dateStyle: {
        viewStyle: {
          height: 40,
          width: 40,
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        },
        deActiveViewStyle: {},
        selectedViewStyle: {
          backgroundColor: colors.SECONDARY_HIGH,
          borderRadius: 40,
        },
        inBetweenStyle: {
          backgroundColor: '#F8F8FB',
        },
        textStyle: {
          whiteSpace: 'pre-wrap',
          ...fonts.BODY3,
          color: colors.NEUTRAL_HIGH,
        },
        deActiveTextStyle: {
          color: colors.NEUTRAL_LOW,
        },
        selectedTextStyle: {
          color: colors.BACKGROUND,
          ...fonts.BODY1,
        },
      },
      weekContainerStyle: {
        flexDirection: 'row',
        paddingTop: 5,
        alignItems: 'center',
      },
      weekComponentStyle: {
        viewStyle: {
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          padding: 5,
          cursor: 'pointer',
        },
        textStyle: {
          textAlign: 'center',
          color: colors.NEUTRAL_MEDIUM,
          ...fonts.BODY1,
        },
        primaryViewStyle: {},
        primaryTextStyle: {
          // color: colors.ERROR_HIGH,
        },
      },
    },

    autoCompleteTheme: {
      ...AutoCompleteStyles({theme}),
      container: {
        borderRadius: 8,
        borderColor: colors.NEUTRAL_MEDIUM,
        backgroundColor: colors.BACKGROUND,
        minHeight: 36,
      },
      input: {
        ...fonts.ACTION,
        color: colors.NEUTRAL_HIGH,
        overflow: 'hidden',
      },
    },
  };
};
