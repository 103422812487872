import React, {useState} from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {InputWrapper, StyledInputContainer} from '@unthinkable/react-input';
import {DateTimeInputStyles} from './DateTimeInput.style';
import moment from 'moment';
export const DateTimeInput = props => {
  const styles = useStyles(DateTimeInputStyles);
  const {onChangeValue, value} = props;
  const [type, setType] = useState('text');
  return (
    <InputWrapper {...props} styles={styles}>
      <StyledInputContainer styles={styles}>
        <input
          {...props}
          type={type}
          value={
            value &&
            (type == 'datetime-local'
              ? moment(value).format('YYYY-MM-DDTHH:mm')
              : moment(value).format('DD/MM/YYYY, HH:mm'))
          }
          style={styles.input}
          onFocus={() => setType('datetime-local')}
          onBlur={() => setType('text')}
          onChange={e => onChangeValue(e.target.value)}
        />
      </StyledInputContainer>
    </InputWrapper>
  );
};
