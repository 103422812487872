import React from 'react';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import Config from '../../../Config';
import {Table} from '../../../components/table';
import {TableHeader} from '../../../components/header/TableHeader';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {ColumnText} from '@unthinkable/react-table';
import {resolveMessage} from './Utility';

export const AlertTable = () => {
  const {user} = useAuth();
  const {searchValue, onChangeFilter} = useFilter();
  return (
    <Table
      renderHeader={() => (
        <TableHeader
          title="Alerts"
          actions={[
            <SearchFilter
              key={'searchFilter'}
              value={searchValue}
              onChangeFilter={onChangeFilter}
            />,
          ]}
        />
      )}
      api="/v1/OneLinerAlerts"
      search={searchValue}
      searchFields={['title', 'message']}
      sort={{
        _id: -1,
      }}
      filter={JSON.stringify({
        'watcher.userId': user._id,
      })}
      columns={[
        {
          field: 'title',
          header: 'Title',
          type: 'text',
          width: 250,
        },
        {
          header: 'Message',
          render: ({row, ...rest}) => (
            <ColumnText {...rest} title={resolveMessage(row)}>
              {resolveMessage(row)}
            </ColumnText>
          ),
        },
        {
          field: 'createdAt',
          header: 'Alerted At',
          type: 'date',
          formatOptions: {
            format: Config.dateTimeFormat,
          },
          width: 180,
        },
      ]}
    />
  );
};
