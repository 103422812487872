import React from 'react';
import {useNavigate} from 'react-router-dom';
import {TableHeader} from '../../../components/header/TableHeader';
import {Table} from '../../../components/table';
import {useInvoke} from '../../../hooks';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {routeLink} from '../../../constant';
import {useFilter} from '@unthinkable/react-filters';
import {TextRenderer} from '@unthinkable/react-text-input';
import Config from '../../../Config';
import {Icon} from '../../facilities/screens/FacilityTable.style';
import images from '../../../assets/images';
import {
  Patient_LIST_EVENT_SOURCE_ID,
  Patient_Location_Type,
} from '../constants/PatientConstants';
import {patientTableStyles} from './style';
import {Patient_Location_Status} from '../constants/PatientConstants';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {Confirm} from '../../../components/confirm';
import {status} from '../../../constant/status';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import modalMessages from '../../../constant/ModalMessage';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import {HttpAuthService} from '../../../services';
import {employerType} from '../../../constant/employerType';
import moment from 'moment';
import {Row} from '@unthinkable/react-core-components';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {isAdmin} from '../../../utils/checkUserType';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {renderPatientCard} from '../../../components/card/patientCard';

const RenderChat = ({row}) => {
  const navigate = useNavigate();
  const handlePress = event => {
    navigate(`/patient/chat/${row.groupId?.PNGroupId}/${row.groupId?._id}`, {
      state: {
        group: {
          name:
            getPatientsFullName({
              FirstName: row.FirstName,
              MiddleName: row.MiddleName,
              LastName: row.LastName,
              Render: false,
            }) +
            ' ' +
            moment(row.DOB).utc().format(Config.dateFormat),
          _id: row.groupId._id,
        },
        patientId: true,
      },
    });
    event.stopPropagation();
  };
  return <Icon onClick={handlePress} src={images.chatSmileSelected} />;
};
const locationStatus = ({row}) => {
  if (row?.locationStatus !== Patient_Location_Status.NONE) {
    const statusText = row?.locationStatus;
    return (
      <div style={patientTableStyles.StatusContainer}>
        <div
          style={
            statusText == Patient_Location_Status.HOME
              ? patientTableStyles.GreenDot
              : patientTableStyles.RedDot
          }></div>
        <div style={patientTableStyles.textContainer}>{statusText}</div>
      </div>
    );
  }
  return '-';
};
const RenderStatus = ({row}) => {
  if (isAdmin()) {
    if (
      !row.outPatientClinicalSummary ||
      row.outPatientClinicalSummary === 'Not Tried'
    ) {
      return (
        <Row style={{gap: '7px'}}>
          {row.status}
          <Icon src={images.stopwatch} />
        </Row>
      );
    } else if (
      row?.outPatientClinicalSummary === 'Success' ||
      row?.outPatientClinicalSummary === 'No Data'
    ) {
      return (
        <Row style={{gap: '7px'}}>
          {row.status}
          <Icon
            style={{width: '21px', height: '21px'}}
            src={images.fetchClinicalSummarySuccess}
          />
        </Row>
      );
    } else if (
      row?.outPatientClinicalSummary === 'Failed' ||
      row?.outPatientClinicalSummary === 'Null Data'
    ) {
      return (
        <Row style={{gap: '7px'}}>
          {row.status}
          <Icon
            style={{width: '21px', height: '21px'}}
            src={images.fetchClinicalSummaryFailed}
          />
        </Row>
      );
    }
  } else {
    return <TextRenderer value={row.status} />;
  }
};
export const PatientTable = props => {
  const navigate = useNavigate();
  const filterProps = useFilter({
    params: {
      status: status.active,
    },
    filter: {},
  });
  const {searchValue, onChangeFilter, filterValues} = filterProps;
  const {params} = filterValues;
  const {user} = useAuth();
  if (user?.employerType?.name === employerType.practice) {
    filterValues.filter = {
      ...filterValues.filter,
      $or: [
        {primaryPractice: user?.practiceId?._id},
        {practices: {$in: [user?.practiceId?._id]}},
      ],
    };
  }
  if (user?.employerType?.name === employerType.facility) {
    filterValues.filter = {
      ...filterValues.filter,
      $or: [
        {currentLocation: user?.facilityId?._id},
        {residence: user?.facilityId?._id},
      ],
    };
  }
  if (params.status) {
    filterValues.filter = {
      ...filterValues.filter,
      status: params.status,
    };
  } else {
    delete filterValues?.filter?.status;
  }

  const exportHandler = async () => {
    await HttpAuthService.download('/v1/patient/export', {
      params: {
        filter: {...filterValues.filter, status: status.active},
        search: searchValue,
        searchFields: ['FirstName', 'MiddleName', 'LastName', 'FullName'],
      },
    });
  };

  const importHandler = async () => {
    navigate('/patient/insertion-logs', {
      state: {
        source: 'patient',
      },
    });
  };

  const RenderDelete = ({row}) => {
    if (!isAdmin() && row?.primaryPractice?._id !== user?.practiceId?._id) {
      return;
    }
    const rowName = row.FullName;
    const invoke = useInvoke({
      method: 'put',
      eventSourceId: Patient_LIST_EVENT_SOURCE_ID,
    });

    if (row.status === status.inactive) {
      return (
        <Confirm
          title={modalMessages.activate.title}
          secondaryTitle={rowName}
          message={`${modalMessages.activate.message} Patient`}
          confirmText="Confirm"
          onConfirm={async () => {
            await invoke({
              uri: `${routeLink.getPatient}/${row._id}`,
              data: {status: status.active},
            });
          }}>
          <Icon src={images.activation} />
        </Confirm>
      );
    }

    return (
      <Confirm
        title={modalMessages.deactivate.title}
        secondaryTitle={rowName}
        message={`${modalMessages.deactivate.message} Patient`}
        confirmText="Confirm"
        onConfirm={async () => {
          await invoke({
            uri: `${routeLink.getPatient}/${row._id}`,
            data: {status: status.inactive},
          });
        }}>
        <Icon src={images.trash} />
      </Confirm>
    );
  };
  const CardFooter = ({row}) => {
    return (
      <Row style={{padding: '6px', justifyContent: 'space-between'}}>
        {RenderStatus({row})}
        {RenderDelete({row})}
      </Row>
    );
  };

  const {isMobile} = useMediaQuery();
  let columns = [];
  if (isMobile) {
    columns = [
      {
        render: ({row}) => {
          const chatAction = user?.chatAuthKey && RenderChat({row: row});
          return renderPatientCard({
            row,
            headerAction: [chatAction],
            FooterComponent: CardFooter,
          });
        },
      },
    ];
  } else {
    columns = [
      {
        header: 'Name',
        type: 'text',
        render: ({row}) => {
          const {FirstName, LastName, MiddleName} = row;
          return getPatientsFullName({FirstName, LastName, MiddleName});
        },
      },
      {
        header: 'Date Of Birth',
        field: 'DOB',
        type: 'date',
        formatOptions: {
          format: Config.dateFormat,
        },
      },
      {
        header: 'Location Status',
        render: locationStatus,
      },
      {
        header: 'Residence',
        render: ({row}) => {
          const text =
            row?.residenceType?.value == Patient_Location_Type.Home
              ? Patient_Location_Type.Home
              : row.residence?.name;
          return <TextRenderer value={text} />;
        },
      },
      {
        header: 'Current Location',
        render: ({row}) => {
          const text =
            row?.currentLocationType?.value == Patient_Location_Type.Home
              ? Patient_Location_Type.Home
              : row?.currentLocation?.name;
          return <TextRenderer value={text} />;
        },
      },
      {
        header: 'Status',
        render: RenderStatus,
      },
      {
        render: RenderChat,
        visible: user?.chatAuthKey,
        width: 50,
      },
      {
        render: RenderDelete,
        visible: () => checkAuthorization(user, entity.patient, action.delete),
        width: 50,
      },
    ];
  }

  return (
    <Table
      params={{...params}}
      onRowPress={({row}) => {
        navigate(`/patient/details`, {
          state: {
            patient: row,
            titleName: getPatientsFullName({
              FirstName: row.FirstName,
              MiddleName: row.MiddleName,
              LastName: row.LastName,
              Render: false,
            }),
          },
        });
      }}
      renderHeader={() => (
        <TableHeader
          title={'Patient'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search patient'}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'$and'}
              options={[
                {
                  label: 'Home',
                  value: [{locationStatus: Patient_Location_Status.HOME}],
                },
                {
                  label: 'Out',
                  value: [{locationStatus: Patient_Location_Status.OUT}],
                },
                {
                  label: 'None',
                  value: [{locationStatus: Patient_Location_Status.NONE}],
                },
              ]}
              key={'locationStatusFilter'}
              placeholder={'Location Status'}
              suggestionField={'label'}
              keyField={'value'}
              valueField={'label'}
              getDisplayValue={({options}) => {
                const selected = options?.find(
                  option =>
                    JSON.stringify(option.value) ===
                    JSON.stringify(filterValues?.filter?.['$and']),
                );
                return selected ? selected.label : '';
              }}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'residence'}
              filter={() => {
                return JSON.stringify({isRedoxFacility: false});
              }}
              api={routeLink.getFacilities}
              key={'residenceFilter'}
              placeholder={'Residence'}
              suggestionField={'name'}
              sort={{name: 1}}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'status'}
              options={[status.active, status.inactive]}
              key={'statusFilter'}
              placeholder={'Status'}
              asParam={true}
            />,

            <Row key="buttonRow" gap={8}>
              <Button
                key="export"
                onPress={exportHandler}
                icon={images.ArrowDownBlack}
                iconPosition="right"
                buttonType={buttonType.linkButtonWithBorder}
              />
              {isAdmin() && (
                <Button
                  key="import"
                  onPress={importHandler}
                  icon={images.Upload}
                  iconPosition="right"
                  buttonType={buttonType.linkButtonWithBorder}
                />
              )}
            </Row>,
          ]}
        />
      )}
      style={{
        border: isMobile,
        rowBorder: isMobile,
        columnPadding: isMobile,
      }}
      search={searchValue}
      sort={JSON.stringify({LastName: 1, FirstName: 1})}
      searchFields={['FullName']}
      api={routeLink.getPatient}
      eventSourceId={Patient_LIST_EVENT_SOURCE_ID}
      addOnFilter={JSON.stringify(filterValues.filter)}
      fields={{
        FirstName: 1,
        MiddleName: 1,
        LastName: 1,
        DOB: 1,
        groupId: {
          _id: 1,
          name: 1,
          PNGroupId: 1,
        },
        residenceType: {
          value: 1,
        },
        currentLocationType: {
          value: 1,
        },
        residence: {
          name: 1,
          type: 1,
        },
        currentLocation: {
          name: 1,
        },
        locationStatus: 1,
        primaryPractice: {
          _id: 1,
          name: 1,
        },
        latestEncounter: {
          dischargeDate: 1,
          taskInfo: {
            _id: 1,
            taskId: 1,
            status: 1,
            successStatus: 1,
            type: 1,
          },
        },
        outPatientClinicalSummary: 1,
        practices: 1,
        status: 1,
        FullName: 1,
      }}
      columns={columns}
      {...props}
    />
  );
};
