import React, {useMemo, useState, useContext, useCallback} from 'react';

export const PageCountContext = React.createContext();

export const PageCountProvider = ({children, pageCount = {}}) => {
  let [pageCountValues, setPageCountValues] = useState(pageCount);

  const setPageCount = useCallback(
    (key, values) => {
      setPageCountValues({
        ...pageCountValues,
        [key]: values,
      });
    },
    [pageCountValues, setPageCountValues],
  );

  const clearPageCount = key => {
    setPageCountValues(pageCountValues => {
      let newPageCountValues = {
        ...pageCountValues,
      };
      delete newPageCountValues[key];
      return newPageCountValues;
    });
  };

  const PageCountContextValue = useMemo(
    () => ({
      pageCount: pageCountValues,
      setPageCount: setPageCount,
      clearPageCount,
    }),
    [pageCountValues, setPageCount, clearPageCount],
  );

  return (
    <PageCountContext.Provider value={PageCountContextValue}>
      {children}
    </PageCountContext.Provider>
  );
};

export const usePageCountContext = () => {
  return useContext(PageCountContext) || {};
};

export const usePageCountKeyValues = key => {
  const {pageCount} = usePageCountContext();
  return (key && pageCount?.[key]) || 0;
};
