import React from 'react';
import Chat from './Chat';
import { ChatMainScreenContainer } from '../styles/ChatMainScreen.style';

const ChatMainScreen = () => {
  return (
    <ChatMainScreenContainer>
      <Chat />
    </ChatMainScreenContainer>
  );
};

export default ChatMainScreen;
