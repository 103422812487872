export const rowHeaderStyles = ({theme: {colors, fonts}}) => {
  return {
    container: {
      backgroundColor: colors.BACKGROUND,
      margin: 8,
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    title: {
      color: colors.NEUTRAL_HIGH,
      ...fonts.FOOTNOTE,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16px',
    },
    secondaryTitle: {
      ...fonts.FOOTNOTE_SMALL,
      lineHeight: '14px',
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};
