import React from 'react';
import {useStyles} from '@unthinkable/react-theme';
import {Button as ButtonComponent} from '@unthinkable/react-button';
import {buttonStyles,linkButtonStyles,LinkButtonWithBorder} from './Button.styles';
import buttonType from '../../constant/buttonType';

const Button = props => {
  let styles = useStyles(buttonStyles);
  
  if(props.buttonType === buttonType.linkButton){
    styles = useStyles(linkButtonStyles)
  }
  if(props.buttonType === buttonType.linkButtonWithBorder){
    styles = useStyles(LinkButtonWithBorder);
  }
  
  return <ButtonComponent styles={styles} {...props} />;
};



export {Button};
