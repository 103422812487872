import React from 'react';
import {FacilityTable} from './FacilityTable';
import {routeLink} from '../../../constant';
import {useNavigate} from 'react-router-dom';

export const FacilityStatsTable = props => {
  const navigate = useNavigate();
  return (
    <FacilityTable
      {...props}
      api={routeLink.getFacilityWithMyPatients}
      fromFacilityStats
      onRowPress={({row}) => {
        navigate(`/facility-stats/overview`, {
          state: {
            facility: row,
            titleName: row?.name,
          },
        });
      }}
    />
  );
};
