import React from 'react';
import {useAuth} from '../modules/auth/hooks/useAuth';
import images from '../assets/images';
import {
  FooterContainer,
  FooterEmail,
  FooterIcon,
  FooterName,
  FooterTextContainer,
} from './AppFooter.style';
import {useNavigate} from 'react-router-dom';
import {Confirm} from './confirm';
import {isMobileOrTablet} from '../utils/isMobileOrTablet';

const AppFooter = ({setSidebarShow}) => {
  const {user, logout} = useAuth();
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <FooterTextContainer>
        <FooterName>{user?.name}</FooterName>
        <FooterEmail>{user?.email}</FooterEmail>
      </FooterTextContainer>
      <FooterIcon
        src={images.setting}
        onClick={() => {
          if (isMobileOrTablet()) {
            setSidebarShow(false);
          }
          navigate('/settings');
        }}
      />
      <Confirm
        title="Logout"
        secondaryTitle="Are you sure you want to logout?"
        confirmText="Logout"
        onConfirm={async () => await logout()}>
        <FooterIcon
          src={images.logout}
          onClick={() => {
            if (isMobileOrTablet()) setSidebarShow(false);
          }}
        />
      </Confirm>
    </FooterContainer>
  );
};

export default React.memo(AppFooter);
