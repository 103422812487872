import styled from 'styled-components';
export const ColumnTextRender = styled.text`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #141e2e;
`;
export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

export const Box = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
`;