import React from 'react';
import images from '../../assets/images';
import {WithModal} from '@unthinkable/react-popper';
import {FilePreviewers} from './FilePreviewers';
import {Icon} from './Filepreviewer.style';

const getFileTypeFromFileName = filename => {
  const extension = filename.split('.').pop();
  switch (extension) {
    case 'pdf':
      return 'pdf';
    default:
      return 'image';
  }
};
export const FilePreviewer = ({value, icon, renderComponent}) => {
  const fileType = getFileTypeFromFileName(value.filename);
  const Previewer = FilePreviewers[fileType];
  return (
    <WithModal
      position="screenCenter"
      maxHeight="100%"
      renderModal={props => <Previewer value={value} {...props} />}>
      {renderComponent ? renderComponent : <Icon source={icon || images.Eye} />}
    </WithModal>
  );
};
