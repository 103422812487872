import React from 'react';
import {VerifyOtp} from './VerifyOtp';
import {useLocation} from 'react-router-dom';

export const VerifyAuthenticatorOtp = props => {
  const {
    state: {user,message:responseMessage,toastType},
  } = useLocation();
  return (
    <VerifyOtp
      {...props}
      user={user}
      responseMessage={responseMessage}
      toastType={toastType}
      message={`Enter the OTP from Authenticator app.`}
      api={'/v1/verifyAuthenticatorOtp'}
    />
  );
};
