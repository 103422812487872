import React from 'react';
import {Form} from '../../../components/form';
import {useFormSubmit} from '../../../hooks';
import {routeLink} from '../../../constant';

export const InsertionLogsForm = props => {
  const {onSubmit} = useFormSubmit({
    uri: routeLink.insertionLogs,
    eventSourceId: 'InsertionLogs',
  });
  return (
    <Form
      header={{title: 'Upload New File'}}
      skipHeader={false}
      onSubmit={onSubmit}
      defaultValues={{
        source: 'patient',
      }}
      layoutFields={[
        {
          type: 'autoComplete',
          field: 'primaryPractice',
          label: 'Primary Practice',
          api: '/v1/practices',
          size: 'large',
          suggestionField: 'name',
          required: true,
          sort: {name: 1},
        },
        {
          field: 'file',
          type: 'file',
          required: true,
          placeholder: 'Upload File',
          inputProps: {
            accept: '.xlsx, .xls',
          },
        },
      ]}
      {...props}
    />
  );
};
