import React from 'react';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import Config from '../../../Config';
import {useFormSubmit} from '../../../hooks';
import {
  Patient_LIST_EVENT_SOURCE_ID,
  Patient_Location_Type,
} from '../constants/PatientConstants';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {USER_TYPE} from '../../../constant/userType';
import {employerType} from '../../../constant/employerType';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {FACILITY_TYPE} from '../../../constant/facilityType';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
export const EditPatient = () => {
  const {onSubmit} = useFormSubmit({
    uri: routeLink.getPatient,
    eventSourceId: [Patient_LIST_EVENT_SOURCE_ID, 'careTeamMembers'],
  });
  const {PatientId} = useParams();
  const {user} = useAuth();
  return (
    <Form
      computations={{
        resetPatientCurrentLocatin: {
          compute: () => {
            return {currentLocation: null};
          },
          multi: true,
          dependencies: ['currentLocationType'],
          condition: ({currentLocationType}) => {
            return currentLocationType?.value == Patient_Location_Type.Home;
          },
        },
        resetPatientResidence: {
          compute: () => {
            return {residence: null};
          },
          multi: true,
          dependencies: ['residenceType'],
          condition: ({residenceType}) => {
            return residenceType?.value == Patient_Location_Type.Home;
          },
        },
      }}
      mode={'edit'}
      onSubmit={onSubmit}
      api={`${routeLink.getPatient}/${PatientId}`}
      fields={{
        FirstName: 1,
        MiddleName: 1,
        LastName: 1,
        DOB: 1,
        groupId: {
          _id: 1,
          name: 1,
          PNGroupId: 1,
        },
        residenceType: {
          value: 1,
        },
        currentLocationType: {
          value: 1,
        },
        residence: {
          name: 1,
        },
        currentLocation: {
          name: 1,
        },
        PhoneNumber: 1,
        EmailAddresses: 1,
        SSN: 1,
        Sex: 1,
        practices: {
          _id: 1,
          name: 1,
          facilities: 1,
        },
        primaryPractice: {
          _id: 1,
          name: 1,
          facilities: 1,
        },
      }}
      layoutFields={[
        {
          label: 'Patient Information',
          fields: [
            {
              type: 'text',
              label: 'Patient First Name',
              field: 'FirstName',
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'Patient Last Name',
              field: 'LastName',
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'Social Security Number',
              field: 'SSN',
              size: 6,
              readOnly: true,
            },
            {
              type: 'date',
              label: 'Date of Birth',
              field: 'DOB',
              size: 3,
              formatOptions: {
                format: Config.dateFormat,
              },

              readOnly: true,
            },
            {
              type: 'autoComplete',
              label: 'Sex (At birth)',
              field: 'Sex',
              size: 3,
              readOnly: true,
            },
            {
              type: 'text',
              label: 'Primary Phone',
              field: 'PhoneNumber.Home',
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'Primary Email Address',
              field: 'EmailAddresses[0]',
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'autoComplete',
              label: 'Primary Practice',
              field: 'primaryPractice',
              size: 'medium',
              api: routeLink.practiceLink,
              sort: {name: 1},
              suggestionField: 'name',
              valueField: 'name',
              readOnly: true,
            },
          ],
        },
        {
          label: 'Patient Location',
          fields: [
            {
              type: 'autoComplete',
              field: 'residenceType',
              label: 'Residence Type',
              api: routeLink.getLocationTypes,
              sort: {name: 1},
              suggestionField: 'value',
              valueField: 'value',
              size: 'medium',
              required: true,
              readOnly: !checkAuthorization(user, entity.patient, action.edit),
            },
            {
              type: 'text',
              field: 'dummy',
              visible: ({values}) => {
                return (
                  !values.residenceType?.value ||
                  values.residenceType?.value === Patient_Location_Type.Home
                );
              },
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'autoComplete',
              field: 'residence',
              label: 'Residence',
              api: routeLink.getFacilities,
              sort: {name: 1},
              suggestionField: 'name',
              valueField: 'name',
              size: 'medium',
              required: true,
              filter: ({values}) => {
                let array = [];
                for (let practice in values.practices) {
                  if (values.practices[practice]?.facilities?.length)
                    array = [
                      ...array,
                      ...values.practices[practice].facilities,
                    ];
                }
                array = [
                  ...array,
                  ...(values?.primaryPractice?.facilities || []),
                ];
                return JSON.stringify({
                  _id: {$in: array},
                  status: STATUS.ACTIVE,
                  isRedoxFacility: false,
                });
              },
              visible: ({values}) => {
                return (
                  values.residenceType?.value === Patient_Location_Type.Facility
                );
              },
              readOnly: !checkAuthorization(user, entity.patient, action.edit),
            },
            {
              type: 'autoComplete',
              field: 'currentLocationType',
              label: 'Current Location Type',
              api: routeLink.getLocationTypes,
              sort: {name: 1},
              suggestionField: 'value',
              valueField: 'value',
              size: 'medium',
              required: true,
              readOnly: !checkAuthorization(user, entity.patient, action.edit),
            },
            {
              type: 'text',
              field: 'dummy',
              visible: ({values}) => {
                return (
                  !values.currentLocationType?.value ||
                  values.currentLocationType?.value ===
                    Patient_Location_Type.Home
                );
              },
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'autoComplete',
              field: 'currentLocation',
              label: 'Current Location',
              api: routeLink.getFacilities,
              sort: {name: 1},
              suggestionField: 'name',
              valueField: 'name',
              size: 'medium',
              required: true,
              filter: ({values}) => {
                let array = [];
                for (let practice in values.practices) {
                  if (values.practices[practice]?.facilities?.length)
                    array = [
                      ...array,
                      ...values.practices[practice].facilities,
                    ];
                }
                array = [
                  ...array,
                  ...(values?.primaryPractice?.facilities || []),
                ];
                return JSON.stringify({
                  _id: {$in: array},
                  type: {
                    $in: [
                      FACILITY_TYPE.IndependentLiving,
                      FACILITY_TYPE.AssistedLiving,
                      FACILITY_TYPE.SkilledNursing,
                    ],
                  },
                  status: STATUS.ACTIVE,
                });
              },
              visible: ({values}) => {
                return (
                  values.currentLocationType?.value ===
                  Patient_Location_Type.Facility
                );
              },
              readOnly: !checkAuthorization(user, entity.patient, action.edit),
            },
          ],
        },
        {
          label: 'Other Practices',
          fields: [
            {
              type: 'multiAutoComplete',
              field: 'practices',
              label: 'Practice',
              api: routeLink.practiceLink,
              sort: {name: 1},
              suggestionField: 'name',
              valueField: 'name',
              secondarySuggestionField: 'shortName',
              size: 'medium',
              filter: ({values}) => {
                if (user?.employerType?.name === employerType.facility) {
                  return JSON.stringify({
                    _id: {
                      $in: user.facilityId.practices,
                      $nin: [
                        ...(values?.practices || []),
                        values?.primaryPractice?._id,
                      ],
                    },
                    status: STATUS.ACTIVE,
                  });
                }
                if (
                  user?.employerType?.name === employerType.practice ||
                  user?.userType?.name === USER_TYPE.ADMIN
                ) {
                  return JSON.stringify({
                    _id: {
                      $nin: [
                        ...(values?.practices || []),
                        values?.primaryPractice?._id,
                      ],
                    },
                    status: STATUS.ACTIVE,
                  });
                }
              },
              readOnly: !checkAuthorization(user, entity.patient, action.edit),
            },
          ],
        },
      ]}
    />
  );
};
