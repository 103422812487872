import React from 'react';
import {useNavigate} from 'react-router-dom';
import {TableHeader} from '../../../components/header/TableHeader';
import {Table} from '../../../components/table';
import Config from '../../../Config';
import {useFilter} from '@unthinkable/react-filters';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {routeLink} from '../../../constant';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {getGroupName} from '../utils/ChatHelperFunctions';
import GetCountMessages from '../utils/GetCountMessages';
import {isAdmin} from '../../../utils/checkUserType';

const {defaultListLimit} = Config;

const GroupsLists = props => {
  const navigate = useNavigate();
  const {searchValue, onChangeFilter, filterValues} = useFilter();

  const {params: filterParams = {}} = filterValues;
  const filter = {
    status: STATUS.ACTIVE,
    $or: [
      {'groupId.practiceId': {$exists: true}},
      {'groupId.facilityId': {$exists: true}},
    ],
  };

  if (filterParams?.['groupId.facilityId']) {
    filter.$and = filter.$and || [];
    filter['$and'].push({
      'groupId.facilityId': filterParams['groupId.facilityId'],
    });
  }
  if (filterParams?.['groupId.practiceId']) {
    filter.$and = filter.$and || [];
    filter['$and'].push({
      'groupId.practiceId': filterParams['groupId.practiceId'],
    });
  }

  return (
    <Table
      noDataText={'No groups found'}
      onRowPress={({row}) => {
        navigate(`/groups/chat/${row.groupId.PNGroupId}/${row.groupId._id}`, {
          state: {
            group: row.groupId,
          },
        });
      }}
      filter={JSON.stringify(filter)}
      renderHeader={() => (
        <TableHeader
          title={'Groups'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search groups'}
            />,
            isAdmin() ? (
              <AutoCompleteFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                field={'groupId.facilityId'}
                api={routeLink.getFacilities}
                suggestionField={'name'}
                key={'facilityFilter'}
                asParam={true}
                placeholder={'Facility'}
                sort={{name: 1}}
              />
            ) : null,
            isAdmin() ? (
              <AutoCompleteFilter
                onChangeFilter={onChangeFilter}
                filterValues={filterValues}
                field={'groupId.practiceId'}
                api={routeLink.practiceLink}
                asParam={true}
                suggestionField={'name'}
                key={'practiceFilter'}
                placeholder={'Practice'}
                sort={{name: 1}}
              />
            ) : null,
          ]}
        />
      )}
      search={searchValue}
      searchFields={['groupId.name']}
      api={routeLink.getChatGroups}
      eventSourceId="chatgroups"
      perPage={defaultListLimit}
      fields={{
        groupId: 1,
        lastMessageTimeToken: 1,
        lastReadTimeToken: 1,
      }}
      columns={[
        {
          header: 'Group Name',
          field: row =>
            row?.groupId?.facilityId && row?.groupId?.practiceId
              ? getGroupName(row.groupId)
              : row?.groupId?.name,
          type: 'text',
        },
        {header: 'Status', field: 'groupId.status', type: 'text'},
        {header: 'Facility', field: 'groupId.facilityId.name', type: 'text'},
        {header: 'Practice', field: 'groupId.practiceId.name', type: 'text'},
        {
          header: 'Unread Messages',
          render: GetCountMessages,
        },
      ]}
      {...props}
    />
  );
};

export default GroupsLists;
