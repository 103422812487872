import React, {forwardRef} from 'react';
import {
  TextInput as TextInputComponent,
  PasswordInput as PasswordInputComponent,
  TextRenderer as TextRendererComponent,
  PasswordRenderer as PasswordRendererComponent,
} from '@unthinkable/react-text-input';
import {TextAreaInput as TextAreaInputComponent} from '@unthinkable/react-text-area-input';
import {AutoComplete, MultiAutoComplete} from '@unthinkable/react-autocomplete';

import {useStyles} from '@unthinkable/react-theme';
import {inputStyles} from './Editors.styles';

const WithStyles = (Component, InputStyles) => {
  const WrappedComponent = (props, ref) => {
    const styles = useStyles(InputStyles);
    return <Component styles={styles} ref={ref} {...props} />;
  };
  return forwardRef(WrappedComponent);
};

export const TextInput = WithStyles(TextInputComponent, inputStyles);
export const TextAreaInput = WithStyles(TextAreaInputComponent, inputStyles);
export const PasswordInput = WithStyles(PasswordInputComponent, inputStyles);

export const TextRenderer = WithStyles(TextRendererComponent, inputStyles);
export const PasswordRenderer = WithStyles(
  PasswordRendererComponent,
  inputStyles,
);
export const TextAreaRenderer = WithStyles(TextAreaInputComponent, inputStyles);

export const AutoCompleteInput = WithStyles(AutoComplete, inputStyles);

export const MultiAutoCompleteInput = WithStyles(
  MultiAutoComplete,
  inputStyles,
);
