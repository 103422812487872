import React from 'react';
import {useIntl} from 'react-intl';
import {View, Text, TextInput} from '@unthinkable/react-core-components';
import {useRegister} from '../hooks/useRegister';
import { loginFormStyle } from '../styles/LoginForm.styles';
import {Button} from '../../../components/button';

const RegisterForm = props => {
  const intl = useIntl();
  const {formValues, setFormValues, onRegister, loading} = useRegister(props);
  const {email, password, name} = formValues || {};
  const isLoginActionDisabled =
    !name?.trim() || !email?.trim() || !password?.trim() || loading;
  return (
    <View style={loginFormStyle.container}>
      <View>
        <Text style={loginFormStyle.header}>
          {intl.formatMessage({id: 'label.welcome'})}
        </Text>
        <Text style={loginFormStyle.header}>Register</Text>
        <TextInput
          style={loginFormStyle.input}
          placeholder={'Name'}
          onChangeText={value => {
            setFormValues(v => {
              v.name = value;
              return {...v};
            });
          }}
          value={name}
        />
        <TextInput
          style={loginFormStyle.input}
          placeholder={intl.formatMessage({id: 'label.username'})}
          onChangeText={value => {
            setFormValues(v => {
              v.email = value;
              return {...v};
            });
          }}
          value={email}
        />
        <TextInput
          type="password"
          style={loginFormStyle.input}
          placeholder={intl.formatMessage({id: 'label.password'})}
          onChangeText={value => {
            setFormValues(v => {
              v.password = value;
              return {...v};
            });
          }}
          secureTextEntry={true}
          value={password}
        />
        <Button
          text={'Register'}
          onPress={() => {
            onRegister();
          }}
          disabled={isLoginActionDisabled}
          disabledContainerStyle={{opacity: 0.5}}
          containerStyle={loginFormStyle.button}
          textStyle={loginFormStyle.buttonText}
        />
      </View>
    </View>
  );
};

export default RegisterForm;
