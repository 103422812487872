import {useCallback, useState} from 'react';

export const useStep = () => {
  const [selected, setSelected] = useState('0');

  const next = useCallback(
    () => setSelected((Number(selected) + 1).toString()),
    [selected, setSelected],
  );
  const back = useCallback(
    () => setSelected((Number(selected) - 1).toString()),
    [selected, setSelected],
  );

  return {next, back, selected, setSelected};
};
