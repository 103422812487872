import React, {useEffect, useState} from 'react';
import {VerifyOtp} from './VerifyOtp';
import {useLocation} from 'react-router-dom';
import HttpAuth from '../../../services/HttpAuthService';
import {useToast} from '@unthinkable/react-toast';
import {UserService} from '../../../services';

export const VerifyEmailOtp = props => {
  const toast = useToast();
  const [isValidLink, setIsValidLink] = useState(true);
  const [tokenId, setTokenId] = useState(null);

  const {
    state: {user, mode, type, token, message: responseMessage, toastType},
  } = useLocation();

  const sendOtp = async () => {
    try {
      const response = await HttpAuth.post('/v1/sendOtp', {
        user,
      });
      toast({
        message: response.message,
        type: 'Success',
      });
    } catch (e) {
      toast({
        message: e.message,
        type: 'Error',
      });
    }
  };
  const verifyToken = async () => {
    try {
      const data = await UserService.verifyResetPasswordToken(token, type);
      if (data?.success) {
        setIsValidLink(true);
        setTokenId(data.data);
        return true;
      } else {
        setIsValidLink(false);
        setTokenId(null);
        return false;
      }
    } catch (error) {
      setIsValidLink(false);
      setTokenId(null);
      return false;
    }
  };

  useEffect(() => {
    const verifyAndSendOtp = async () => {
      let validLink = true;
      if (mode) {
        validLink = await verifyToken();
        if (validLink) {
          await sendOtp();
        }
      } else {
        await sendOtp();
      }
    };
    verifyAndSendOtp();
  }, [user]);

  return (
    <VerifyOtp
      {...props}
      user={user}
      mode={mode}
      responseMessage={responseMessage}
      toastType={toastType}
      isValidLink={isValidLink}
      message={`Enter the OTP sent to ${user.email}`}
      tokenId={tokenId}
      type={type}
      api={mode ? '/v1/verifyOtpAfterPasswordReset' : '/v1/verifyOtp'}
    />
  );
};
