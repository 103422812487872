import styled from 'styled-components';
export const MainContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 1;
  overflow-x: auto;
  @media (max-width: 350px) {
    padding: 10px;
  }
`;
