import {useFormSubmit} from '../../../hooks';
import {routeLink} from '../../../constant';
import {Form} from '../../../components/form';
import React from 'react';
import {TableHeader} from '../../../components/header/TableHeader';

export const AddMedicineModal = props => {
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.getMedicine}`,
    method: 'post',
  });
  return (
    <>
      <TableHeader title="Add Medicine" />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        layoutFields={[
          {
            fields: [
              {
                type: 'text',
                field: 'Name',
                label: 'Medicine Name',
                size: 'medium',
                required: true,
              },
              {
                type: 'text',
                field: 'Code',
                label: 'Code',
                size: 'medium',
                required: true,
              },
              {
                type: 'text',
                field: 'CodeSystem',
                label: 'Code System',
                size: 'medium',
              },
              {
                type: 'text',
                field: 'CodeSystemName',
                label: 'Code System Name',
                size: 'medium',
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
