import {useState} from 'react';

export const useExpand = ({
  defaultExpanded: defaultExpandedProp,
  exclusiveExpand: exclusiveExpandProp,
} = {}) => {
  const [expandedState, setExpandedState] = useState({});

  const toggleExpand = (
    key,
    id,
    {
      defaultExpanded = defaultExpandedProp,
      exclusiveExpand = exclusiveExpandProp,
      defaultExpandedIndex,
    } = {},
  ) => {
    setExpandedState(prevState => {
      const prevKeyState = prevState[key] || {};
      const isCurrentlyExpanded =
        prevKeyState[id] !== undefined
          ? prevKeyState[id]
          : defaultExpandedIndex !== undefined
          ? defaultExpandedIndex === id
          : defaultExpanded;

      let newKeyState;
      if (exclusiveExpand) {
        // If singleExpand is true, close all other rows when opening a row
        newKeyState = {[id]: !isCurrentlyExpanded};
      } else {
        // If singleExpand is false, toggle the opened row without closing other rows
        newKeyState = {...prevKeyState, [id]: !isCurrentlyExpanded};
      }

      return {
        ...prevState,
        [key]: newKeyState,
      };
    });
  };

  const isExpanded = (
    key,
    id,
    {defaultExpanded = defaultExpandedProp, defaultExpandedIndex} = {},
  ) => {
    if (expandedState[key]?.[id] !== undefined) {
      return !!expandedState[key][id];
    }
    return defaultExpandedIndex !== undefined
      ? defaultExpandedIndex === id
      : defaultExpanded;
  };

  return {toggleExpand, isExpanded};
};