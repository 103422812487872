import {useState, useCallback} from 'react';

export const useSelection = ({
  rowKey = '_id',
  data = [],
  onSelectionChange,
  onSelect,
  singleSelection,
  defaultSelectedIds = [],
} = {}) => {
  const [selectedIds, setSelectedIds] = useState(defaultSelectedIds);

  const _setSelectedIds = useCallback(
    _selectedIds => {
      setSelectedIds(_selectedIds);
      onSelectionChange && onSelectionChange(_selectedIds);
    },
    [setSelectedIds, onSelectionChange],
  );

  // ====================== Selections ======================

  const resetSelection = useCallback(() => {
    _setSelectedIds([]);
  }, [_setSelectedIds]);

  const toggleSelection = useCallback(
    (row, e) => {
      const rowId = row[rowKey];
      if (!rowId) {
        return;
      }
      let isSelected = false;
      let _selectedIds = [...selectedIds];
      let idIndex = _selectedIds.indexOf(rowId);
      if (idIndex > -1) {
        _selectedIds.splice(idIndex, 1);
      } else {
        isSelected = true;
        _selectedIds = singleSelection ? [rowId] : [..._selectedIds, rowId];
      }
      onSelect && onSelect(row, isSelected, e);
      _setSelectedIds(_selectedIds);
    },
    [_setSelectedIds, selectedIds, singleSelection, onSelect],
  );

  const isAllSelected = useCallback(() => {
    // Record key only need check with enabled
    if (!data || data?.length === 0) return false;
    const recordKeys = data.map(item => item[rowKey]);
    const checkedCurrentAll = recordKeys.every(
      key => selectedIds.indexOf(key) !== -1,
    );
    return checkedCurrentAll;
  }, [data, selectedIds]);

  const isIndeterminate = () => {
    if (!data || data?.length === 0 || isAllSelected()) return false;
    const recordKeys = data.map(item => item[rowKey]);
    const checkedCurrentAll = recordKeys.some(
      key => selectedIds.indexOf(key) !== -1,
    );
    return checkedCurrentAll;
  };

  const toggleAllSelection = useCallback(
    (e, indeterminate) => {
      // Record key only need check with enabled
      const allSelected = isAllSelected();

      const recordKeys = data.map(item => item[rowKey]);
      let keySet = new Set(selectedIds);
      if (allSelected || indeterminate) {
        recordKeys.forEach(key => {
          keySet.delete(key);
        });
      } else {
        recordKeys.forEach(key => {
          if (!keySet.has(key)) {
            keySet.add(key);
          }
        });
      }
      const keys = Array.from(keySet);
      _setSelectedIds(keys);
    },
    [data, selectedIds, _setSelectedIds, isAllSelected],
  );

  const isSelected = useCallback(
    row => {
      const rowId = row[rowKey];
      return selectedIds.indexOf(rowId) > -1;
    },
    [selectedIds],
  );

  return {
    selectedIds,
    toggleSelection,
    toggleAllSelection,
    resetSelection,
    isSelected,
    isAllSelected,
    isIndeterminate,
  };
};
