import moment from 'moment';
import {employerType} from '../../../constant/employerType';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
const isToday = time => {
  if (moment(time).calendar().includes('Today')) {
    return true;
  }
  return false;
};
const isMe = (senderId, userId) => {
  return senderId === userId;
};

const getTimeFromTimeToken = timetoken => {
  // Convert timetoken back to seconds
  let timetokenInSeconds = timetoken / 10000000;

  // Convert seconds to milliseconds (JavaScript uses milliseconds for time)
  let timetokenInMilliseconds = timetokenInSeconds * 1000;

  // Create a new Date object using the timetoken in milliseconds
  return new Date(timetokenInMilliseconds);
};

const getGroupName = group => {
  const {user} = useAuth();
  if (group?.practiceId && user?.employerType?.name === employerType.facility) {
    return group?.practiceId?.name;
  } else if (
    group?.facilityId &&
    user?.employerType?.name === employerType.practice
  ) {
    return group?.facilityId?.name;
  } else {
    return group?.name;
  }
};

export {isToday, isMe, getTimeFromTimeToken, getGroupName};
