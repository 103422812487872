import React, {useCallback} from 'react';
import {FileInput as FileInputComponent} from '@unthinkable/react-file-upload';
import {HttpAuthService} from '../../services';
import Config from '../../Config';
import {useStyles} from '@unthinkable/react-theme';
import {FileInputStyles} from './FileInputEditor.styles';
import {FilePreviewer} from '../filePreviewer';
import {Row, View} from '@unthinkable/react-core-components';
import {ErrorMessage, InputWrapper} from '@unthinkable/react-input';
import {
  FileRemoveInnerContainer,
  FileRemoveText,
  FileRemoveWrapper,
  FileRenderImage,
  FileRenderImageContainer,
  FileRenderText,
  FileRenderWrapper,
} from '@unthinkable/react-file-upload/src/Styles';

const RemoveFile = ({styles, onRemove}) => {
  if (!onRemove) {
    return null;
  }
  return (
    <FileRemoveWrapper styles={styles} onPress={onRemove}>
      <FileRemoveInnerContainer styles={styles}>
        <FileRemoveText styles={styles}>X</FileRemoveText>
      </FileRemoveInnerContainer>
    </FileRemoveWrapper>
  );
};

export const FileRendererComponent = ({
  styles,
  value,
  resourceUrl,
  selected,
  defaultContentTypeIcons,
  numberOfLines = 1,
  ...props
}) => {
  if (!value) {
    return null;
  }
  const {filename, contentType} = value;
  const url = resourceUrl && resourceUrl(value);
  let renderComponent = void 0;
  let showText = false;
  if (contentType?.startsWith('image/') && !contentType?.endsWith('/svg+xml')) {
    renderComponent = (
      <FileRenderImageContainer>
        <FileRenderImage resizeMode={'contain'} source={url} />
      </FileRenderImageContainer>
    );
  } else {
    showText = true;
    const isApplicationType = contentType?.startsWith('application/');
    const isVideoType = contentType?.startsWith('video/');
    const isSVGType = contentType?.endsWith('/svg+xml');
    let contentTypeIcon = isApplicationType
      ? defaultContentTypeIcons?.application
      : isVideoType
      ? defaultContentTypeIcons?.video
      : isSVGType
      ? defaultContentTypeIcons?.image
      : void 0;

    renderComponent = (
      <FileRenderText styles={styles} numberOfLines={numberOfLines}>
        {filename}
      </FileRenderText>
    );

    if (contentTypeIcon) {
      renderComponent = (
        <>
          <FileRenderImageContainer>
            <FileRenderImage resizeMode={'contain'} source={contentTypeIcon} />
          </FileRenderImageContainer>
          {renderComponent}
        </>
      );
    }
  }
  return (
    <FileRenderWrapper
      title={filename}
      selected={selected}
      showText={showText}
      styles={styles}>
      {renderComponent}
      <RemoveFile styles={styles} {...props} />
    </FileRenderWrapper>
  );
};

export const FileInput = props => {
  const styles = useStyles(FileInputStyles);
  const resourceUrl = ({key}) => {
    return Config.downloadUrl + '/files/' + key;
  };
  const upload = value => {
    return HttpAuthService.upload('/upload', value);
  };

  let {value} = props;
  const {onChangeValue, multiple, error} = props;
  const onRemove = useCallback(
    ({index}) => {
      let newValue = null;
      if (multiple) {
        newValue = [...value];
        newValue.splice(index, 1);
      }
      onChangeValue && onChangeValue(newValue);
    },
    [value, multiple, onChangeValue],
  );

  if (value && !Array.isArray(value)) {
    value = [value];
  }
  return (
    <View style={styles.wrapper}>
      <FileInputComponent
        upload={upload}
        resourceUrl={resourceUrl}
        styles={styles}
        skipValue
        error={error}
        {...props}
      />
      {error ? <ErrorMessage error={error} styles={styles} /> : void 0}

      <Row gap={8}>
        {value?.map(file => (
          <FilePreviewer
            {...props}
            key={file.key}
            value={file}
            renderComponent={
              <FileRendererComponent
                styles={styles}
                resourceUrl={resourceUrl}
                openInNewTab={false}
                onRemove={onRemove}
                {...props}
                value={file}
              />
            }
          />
        ))}
      </Row>
    </View>
  );
};

export const FileInputRenderer = props => {
  const resourceUrl = ({key}) => {
    return Config.downloadUrl + '/files/' + key;
  };
  const styles = useStyles(FileInputStyles);
  let {value} = props;
  const {multiple} = props;
  if (!multiple && value) {
    value = [value];
  }

  return (
    <InputWrapper {...props}>
      <Row gap={8}>
        {value?.map(file => (
          <FilePreviewer
            {...props}
            key={file.key}
            value={file}
            renderComponent={
              <FileRendererComponent
                styles={styles}
                resourceUrl={resourceUrl}
                openInNewTab={false}
                {...props}
                value={file}
              />
            }
          />
        ))}
      </Row>
    </InputWrapper>
  );
};
