const {AlertTable} = require('./screens/AlertTable');

const AlertRoutes = [
  {
    name: 'Alerts',
    path: '/one-liner-alerts',
    component: AlertTable,
  },
];

module.exports = {
  stack: AlertRoutes,
};
