export const separateNumber = value => {
  if (value === undefined || value === null) {
    return value;
  }
  let numericValue = 0;
  let unitValue;
  if (typeof value === 'string') {
    const indexOfFr = value.indexOf('fr');
    if (indexOfFr >= 0) {
      numericValue = value.substring(0, indexOfFr);
      unitValue = 'fr';
    } else {
      const indexOfPX = value.indexOf('px');
      if (indexOfPX >= 0) {
        numericValue = value.substring(0, indexOfPX);
        unitValue = 'px';
      } else {
        throw new Error(
          `Invalid unit type in separate number, it shold be either fr or px it is [${value}]`,
        );
      }
    }
  } else if (typeof value === 'number') {
    numericValue = value;
    unitValue = 'px';
  }
  return {
    number: Number(numericValue),
    unit: unitValue,
  };
};

const getExtraProps = (props, propsToCheck, breakPoints) => {
  let extraProps = {
    ...props,
  };
  for (let prop of propsToCheck) {
    delete extraProps[prop];
    if (breakPoints) {
      for (var i = 0; i < breakPoints.length; i++) {
        let breakPoint = breakPoints[i];
        let propKey = `${prop}${breakPoint.mq}`;
        let breakPointMQValue = props[propKey];
        if (breakPointMQValue !== undefined) {
          delete extraProps[propKey];
        }
      }
    }
  }
  return extraProps;
};

export const resolveMQ =
  ({breakPoints}) =>
  (props = {}, propsToCheck = [], activeMQ = {}, allPropsRequired) => {
    let {mq, index} = activeMQ;
    const resultProps = {};
    for (let prop of propsToCheck) {
      let mqValue = props[`${prop}${mq}`];
      if (mqValue === undefined && index !== undefined && breakPoints) {
        for (var i = index - 1; i >= 0; i--) {
          let breakPoint = breakPoints[i];
          let breakPointMQValue = props[`${prop}${breakPoint.mq}`];
          if (breakPointMQValue !== undefined) {
            mqValue = breakPointMQValue;
            break;
          }
        }
      }
      if (mqValue === undefined) {
        mqValue = props[prop];
      }
      if (mqValue !== undefined) {
        resultProps[prop] = mqValue;
      }
    }
    if (allPropsRequired) {
      let extraProps = getExtraProps(props, propsToCheck, breakPoints);
      if (extraProps) {
        Object.assign(resultProps, extraProps);
      }
    }
    return resultProps;
  };

const getMQKeys = (key, breakPoints) => {
  return [key, ...breakPoints.map(({mq}) => `${key}${mq}`)];
};

/**
 * populate key as per all mq in props,
 * use case - tableScreenLoader, need to populate fields , actions in rowSM, rowMD etc.
 * @param {*} props
 * @param {*} keys
 * @param {*} value
 */
export const enhancePropWithMQ =
  ({breakPoints}) =>
  (props, keys, value) => {
    if (!keys || !keys.length || !value) {
      return props;
    }
    props = {...props};
    for (let key of keys) {
      const mqKeys = getMQKeys(key, breakPoints);
      for (let mqKey of mqKeys) {
        if (props.hasOwnProperty(mqKey)) {
          let propValue = props[mqKey];
          if (propValue) {
            if (typeof value === 'function') {
              propValue = value({key, mqKey, value: propValue});
            } else {
              for (let valueKey in value) {
                let valueKeyValue = value[valueKey];
                propValue = {...propValue, [valueKey]: valueKeyValue};
              }
            }

            props[mqKey] = propValue;
          }
        }
      }
    }
    return props;
  };

/**
 * replace key as per all mq in props,
 * use case - in GenericTable - we need to put showHeader:false in case of nested table
 * @param {*} props
 * @param {*} keys
 * @param {*} value
 */
export const overridePropWithMQ =
  ({breakPoints}) =>
  (props, key, value) => {
    props = {...props};
    const mqKeys = getMQKeys(key, breakPoints);
    for (let mqKey of mqKeys) {
      if (props.hasOwnProperty(mqKey)) {
        delete props[mqKey];
      }
    }
    props[key] = value;

    return props;
  };

const mergeStyleInernally = (baseStyle, style) => {
  if (Array.isArray(style)) {
    baseStyle = style.reduce((accum, doc) => {
      if (doc) {
        accum = {...accum, ...doc};
      }
      return accum;
    }, baseStyle);
  } else if (style) {
    baseStyle = {...baseStyle, ...style};
  }
  return baseStyle;
};
export const mergeStyle = (style1, style2) => {
  if (!style1) {
    return style2;
  } else if (!style2) {
    return style1;
  } else {
    let newStyle = {};
    newStyle = mergeStyleInernally(newStyle, style1);
    newStyle = mergeStyleInernally(newStyle, style2);
    return newStyle;
  }
};
