const {Image} = require('@unthinkable/react-core-components');
const {default: styled} = require('styled-components');

export const Icon = styled(Image)`
  height: 20px;
  width: 20px;
  @media (max-width: 480px) {
    height: 16px;
    width: 16px;
  }
`;
