import styled from 'styled-components';
import theme from '../../../theme/Theme';
import {Row, Text} from '@unthinkable/react-core-components';

const variantWiseBorderColors = {
  normal: theme.colors.NEUTRAL_HIGH,
  warning: theme.colors.PATIENT_NAV_HIGH,
  success: theme.colors.SUCCESS_HIGH,
  alarm: theme.colors.RED_TCM_FORE_COLOR,
};

const variantWiseBackgroundColor = {
  normal: theme.colors.BACKGROUND,
  warning: theme.colors.PATIENT_NAV_LOW,
  success: theme.colors.SUCCESS_LOW,
  alarm: theme.colors.RED_TCM_BACKGROUND_COLOR,
};

export const StyledSeparator = styled.div`
  height: 21.21px;
  width: 21.21px;
  border-bottom: 1px solid
    ${({variant = 'normal'}) => variantWiseBorderColors[variant]};
  border-right: 1px solid
    ${({variant = 'normal'}) => variantWiseBorderColors[variant]};
  transform: rotate(-45deg);
  margin-left: -10.4px;
  background-color: ${({variant = 'normal'}) =>
    variantWiseBackgroundColor[variant]};
  overflow: hidden;
  margin-top: 4.45px;
  @media (max-width: 480px) {
    height: 17.21px;
    width: 17.21px;
    margin-top: 3.45px;
    transform: rotate(-40deg);
  }
`;

export const LeftNavBoxTitle = styled.div`
  padding: 4px 15px;
  cursor: pointer;
  background-color: ${({variant}) => variantWiseBackgroundColor[variant]};
  border: 1px solid ${({variant}) => variantWiseBorderColors[variant]};
  border-right: none;
  border-radius: 8px 0px 0px 8px;
  padding-right: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  @media (max-width: 480px) {
    font-size: 8px;
    gap: 2px;
    padding: 2px 7px;
    padding-right: 12px;
  }
`;

export const MiddleNavBoxTitle = styled.div`
  padding: 4px 15px;
  cursor: pointer;
  background-color: ${({variant}) => variantWiseBackgroundColor[variant]};
  border: 1px solid ${({variant}) => variantWiseBorderColors[variant]};
  border-left: none;
  border-right: none;
  padding-left: 24px;
  margin-left: -12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  @media (max-width: 480px) {
    font-size: 8px;
    gap: 2px;
    padding: 2px 14px;
    padding-left: 12px;
    margin-left: -6px;
  }
`;

export const RightNavBoxTitle = styled.div`
  padding: 4px 15px;
  cursor: pointer;
  background-color: ${({variant}) => variantWiseBackgroundColor[variant]};
  border: 1px solid ${({variant}) => variantWiseBorderColors[variant]};
  border-radius: 0px 8px 8px 0px;
  border-left: none;
  padding-left: 24px;
  margin-left: -12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  @media (max-width: 480px) {
    font-size: 8px;
    gap: 2px;
    padding: 2px 7px;
    padding-left: 14px;
    margin-left: -6px;
  }
`;

export const NavBoxMessage = styled(Text)`
  ${() => ({
    numberOfLines: 1,
    overflow: 'hidden',
    ...theme.fonts.CAPTION_LARGE,
  })}
  @media (max-width: 480px) {
    font-size: 8px;
    max-width: 80px;
  }
`;

export const MainContainer = styled(Row)`
  border-radius: 8px;
  flex-direction: row-reverse;
  align-self: flex-start;
`;

export const MainContainerColumn = styled(Row)`
  border-radius: 8px;
  flex-direction: column;
  align-self: flex-start;
`;
