export const Patient_LIST_EVENT_SOURCE_ID = 'PATIENT_LIST';
export const DEFAULT_LIMIT = 3;
export const Patient_Location_Type = {
  Home: 'Home',
  Facility: 'Facility',
};

export const Patient_Location_Status = {
  HOME: 'Home',
  OUT: 'Out',
  NONE: 'None',
};
export const Transitional_Event_Type = {
  Discharge: 'Discharge',
  Admission: 'Admission',
  Arrival: 'Arrival',
};
