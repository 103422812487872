import React from 'react';
import {Form as FormComponent} from '@unthinkable/react-form';
import {SubmitButton} from './SubmitButtons';
import FieldTypes from './FieldTypes';
import {HttpAuthService} from '../../services';
import {useToast} from '@unthinkable/react-toast';
import {formStyles} from './Form.styles.js';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {useNavigate} from 'react-router-dom';
import {Button} from '../button';
import buttonType from '../../constant/buttonType';
import {buttonStyles} from '../button/Button.styles';
import {parseErrorMessage} from './formErrorHandling';

const CustomFormComponent = props => {
  const {Crossdark} = useTheme('icons');
  let styles = useStyles(buttonStyles);
  let {
    saveButtonText = 'Save',
    submitType,
    ...rest
  } = props;
  const navigate = useNavigate();
  const defaultActions = [
    <Button
      key="cancelForm"
      text="Cancel"
      onPress={() => {
        navigate(-1);
      }}
      icon={Crossdark}
      iconPosition="right"
      buttonType={buttonType.linkButtonWithBorder}
    />,
    <SubmitButton
      styles={styles}
      key={'Submit'}
      label={saveButtonText}
      type={submitType}
    />,
  ];

  return <FormComponent skipHeader {...rest} defaultActions={defaultActions} />;
};

const BaseForm = props => {
  const navigate = useNavigate();
  const toast = useToast();

  const fetch = async ({uri, props}) => {
    const result = await HttpAuthService.get(uri, {params: props});
    return result;
  };

  return (
    <CustomFormComponent
      fieldTypes={FieldTypes}
      fetch={fetch}
      onClose={() => {
        navigate(-1);
      }}
      onSubmitError={(err, {setFieldError}) => {
        if (err.message.includes('validation failed')) {
          // form validation handling to show field error
          const errorObj = parseErrorMessage(err);
          for (const key in errorObj) {
            setTimeout(() => {
              setFieldError && setFieldError(key, errorObj[key]);
            }, 0);
          }
        } else {
          // form validation handling to show toast level error
          toast({
            message: err.message,
            type: 'Error',
          });
        }
      }}
      onError={err => {
        toast({
          message: err.message,
        });
      }}
      onSubmitSuccess={(message = 'Data Saved.') => {
        message && toast({message});
      }}
      skipDefaultSave
      {...props}
    />
  );
};

const Form = props => {
  let styles = useStyles(formStyles);
  return <BaseForm {...props} styles={styles} />;
};
export default Form;
