import React, {useEffect, useState} from 'react';
import Config from '../Config';
import {AppContent, AppHeader, AppSidebar} from '../components';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {ContentContainer, MainContainer} from './DefaultLayout.style';
import {IdleDetector} from './IdleDetector';
import {useTheme} from '@unthinkable/react-theme';
import {SideBarOpenCloseIcon} from '../components/AppSidebar.style';
import {isMobileOrTablet} from '../utils/isMobileOrTablet';
const DefaultLayout = ({isAuthenticated}) => {
  const [sidebarShow, setSidebarShow] = useState(!isMobileOrTablet());

  const toggleSidebar = () => {
    setSidebarShow(!sidebarShow);
  };

  useEffect(() => {
    setSidebarShow(!isMobileOrTablet());
  }, [isMobileOrTablet()]);

  const {onSessionTimeOut} = useAuth();
  const {DateNext, DatePrevious} = useTheme('icons');

  if (!isAuthenticated) {
    return <AppContent />;
  }

  return (
    <MainContainer>
      <IdleDetector
        timeout={Config.idleTimeoutMinutes * 60 * 1000}
        onIdle={onSessionTimeOut}>
        <AppSidebar sidebarShow={sidebarShow} setSidebarShow={setSidebarShow} />
        <SideBarOpenCloseIcon
          src={sidebarShow ? DatePrevious : DateNext}
          onClick={toggleSidebar}
          isOpen={sidebarShow}
        />
        <ContentContainer>
          <AppHeader />
          <AppContent />
        </ContentContainer>
      </IdleDetector>
    </MainContainer>
  );
};

export default DefaultLayout;
