export const AutoCompleteStyles = ({theme}) => {
  const {colors, fonts} = theme;
  return {
    chip: {
      container: {
        borderRadius: 4,
        paddingTop: 1,
        paddingRight: 6,
        paddingBottom: 1,
        paddingLeft: 6,
      },
      text: {
        ...fonts.BODY3,
        color: colors.NEUTRAL_HIGH,
      },
    },
    modal: {
      sm: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 250,
        },
      },
      md: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 165,
          minWidth: 200,
          borderColor: colors.UPPER_OUTLINE,
          borderWidth: 1,
        },
      },
    },
    rowContainer: {
      backgroundColor: colors.BACKGROUND,
      borderBottomColor: colors.OUTLINE,
    },
    rowText: {
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
    },
    rowSecondaryText: {
      ...fonts.CAPTION_SMALL,
      color: colors.NEUTRAL_MEDIUM,
    },
  };
};

export const CascaderStyles = ({theme}) => {
  const {colors, fonts} = theme;
  return {
    separator: {height: 0},
    dropdownList: {},
    dropdownInputContainer: {
      padding: 0,
      borderBottomWidth: 0,
      paddingBottom: 12,
    },
    rowContainer: {
      backgroundColor: colors.SURFACE2,
      borderRadius: 6,
      marginBottom: 4,
      borderBottomWidth: 0,
    },
    rowContent: {},
    rowText: {color: colors.NEUTRAL_HIGH, ...fonts.BODY3},
    cascadeHeaderRowGap: 16,
    cascadeHeaderContainer: {
      borderBottomWidth: 0,
      padding: 0,
      paddingBottom: 12,
    },
    cascadingIcon: {},
    cascadeHeaderText: {...fonts.SECTION_HEADING2, color: colors.NEUTRAL_HIGH},
    modal: {
      sm: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 250,
          padding: 12,
        },
      },
      md: {
        container: {
          backgroundColor: colors.BACKGROUND,
          height: 250,
          minWidth: 300,
          padding: 12,
        },
      },
    },
  };
};
