import React from 'react';
import {
  TouchableOpacity,
  Image,
  Styled,
  Wrap,
  Row,
  Text,
  Col,
} from '@unthinkable/react-core-components';

export const CheckboxWrapper = Styled(Row)`
align-items: center;
`;

export const CheckboxText = Styled(Text)`
font-size: 14px;
font-family: Inter-Regular;
line-height: 20px;
color: #19191A;
${({styles}) => styles?.label}
`;

export const CheckboxSecondaryText = Styled(Text)`
font-size: 12px;
font-family: Inter-Regular;
line-height: 16px;
color: #7D7D82;
${({styles}) => styles?.secondaryLabel}
`;

export const CheckboxImage = Styled(Image)`
height: 16px;
width: 16px;
${({styles}) => styles?.icon}`;

export const CheckboxContainer = Styled(TouchableOpacity)`
cursor:pointer;
`;

export const MultiCheckboxContainer = Styled(Wrap)`
 column-gap: 36px;
 row-gap: 8px;
${({styles}) => styles?.container}
 `;

export const MultiCheckboxColumnContainer = Styled(Col)`
${({styles}) => styles?.container}
 `;
