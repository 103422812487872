export const hospitalizationMedicationStyle = {
  mainContainer: {
    flex: 1,
    overflow: 'hidden',
    maxHeight: '335px',
  },
  mainContainerMobile: {
    maxHeight: '335px',
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 20,
  },
};
