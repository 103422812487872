import moment from 'moment';
import Config from '../../../Config';

export const formatMessage = message => {
  const {value, type} = message;
  if (type === 'dateTime') {
    return moment(value).utc().format(Config.dateTimeFormat);
  }
  return value;
};
export const resolveMessage = ({message, messageParams}) => {
  if (!messageParams) return message;
  for (const key in messageParams) {
    message = message.replaceAll(
      `{${key}}`,
      formatMessage(messageParams[key]) + '',
    );
  }
  return message;
};
