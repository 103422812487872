import React from "react";
import { Row, ScrollView, View } from "@unthinkable/react-core-components";
import { Button } from "@unthinkable/react-button";
import { ActionWrapper, StepHeader, StepItem, VerifiedIcon } from "./Styles";
import { LayoutFieldsRender } from "./Form";
import { TabBarRow } from "@unthinkable/react-tabs";
import { useStep } from "./hooks";
import { SubmitButton } from "./FormActions";
import Check from "../images/Check.svg";

const StepTabs = ({ steps, styles, selected, setSelected, clickableTabs, isValid, ...props }) => {
  let tabs = {};
  steps?.map(({ title }, key) => {
    tabs[key] = {};
    tabs[key].label = (
      <StepItem>
        <StepHeader styles={styles}>
          {key + 1}. {title}
        </StepHeader>
        {!clickableTabs && isValid && Number(key) < Number(selected) ? <VerifiedIcon source={Check} /> : void 0}
      </StepItem>
    );
  });
  return (
    <TabBarRow
      styles={styles}
      tabs={tabs}
      selectedTab={selected}
      onSelectTab={clickableTabs ? setSelected : void 0}
      {...props}
    />
  );
};

const Footer = ({ isLast, isFirst, next, back, isValid, styles, selectedStep, values, saveOnNext }) => {
  if (selectedStep?.saveOnNext !== undefined) {
    saveOnNext = selectedStep?.saveOnNext;
  }

  const onNextPress = async () => {
    await selectedStep?.onNext?.({ values });
    next?.();
  };

  return (
    <ActionWrapper styles={styles}>
      {!isFirst ? <Button styles={styles?.secondary} text="Back" onPress={back} /> : <View style={styles?.empty} />}
      {!isLast ? (
        saveOnNext ? (
          <SubmitButton
            styles={styles?.primary}
            disabled={!isValid}
            type={"saveAndNext"}
            label={"Save and Next"}
            onNext={onNextPress}
          />
        ) : (
          <Button styles={styles?.primary} disabled={!isValid} text="Next" onPress={onNextPress} />
        )
      ) : (
        <SubmitButton styles={styles?.primary} disabled={!isValid} label="Submit" type="saveAndClose" />
      )}
    </ActionWrapper>
  );
};

export const MultiStepForm = ({ steps, styles, errors, ...rest }) => {
  const stepProps = useStep();
  const { selected, setSelected } = stepProps;

  const selectedStep = steps[selected];

  const { fields, render } = selectedStep;

  const isValid = fields ? fields?.reduce((acc, { field }) => acc && !errors?.[field], true) : true;

  return (
    <>
      <StepTabs
        {...rest}
        isValid={isValid}
        steps={steps}
        selected={selected}
        setSelected={setSelected}
        styles={styles?.tabs}
      />
      {fields ? (
        <ScrollView style={{ padding: 8 }}>
          <LayoutFieldsRender {...rest} layoutFields={fields} />
        </ScrollView>
      ) : (
        render?.({
          ...rest,
        })
      )}
      <Footer
        {...rest}
        selectedStep={selectedStep}
        isValid={isValid}
        isLast={Number(selected) === steps.length - 1}
        isFirst={!Number(selected)}
        styles={styles?.tabs}
        {...stepProps}
      />
    </>
  );
};
