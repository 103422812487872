import React from 'react';
import {HospitalizationMedication} from './HospitalizationMedicationTable';
import {useLocation} from 'react-router-dom';
import {patientDetailStyles} from '../screens/style';
import {View} from '@unthinkable/react-core-components';
import {HospitalizationDiagnose} from './HospitalizationDiagnoseTable';
import {PatientHeader} from './PatientHeader';
const CompleteMedication = () => {
  const {encounter, patient, isTcmMedicationButtonVisiable, medicationId} =
    useLocation().state;

  return (
    <View style={patientDetailStyles.styles.container}>
      <PatientHeader
        patient={patient}
        encounter={encounter}
      />
      <HospitalizationMedication
        encounter={encounter}
        patient={patient}
        isTcmMedicationButtonVisiable={isTcmMedicationButtonVisiable}
        entity={'TCMTASK'}
        medicationId={medicationId}
      />
      <HospitalizationDiagnose encounter={encounter} />
    </View>
  );
};

export default CompleteMedication;
