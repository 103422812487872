import React from 'react';
import {TableHeader} from '../../../components/header/TableHeader';
import Config from '../../../Config';
import {Table} from '../../../components/table';
import {
  AutoCompleteFilter,
  SearchFilter,
} from '../../../components/filter/Filters';
import {useAuth} from '../../auth/hooks/useAuth';
import {routeLink, toasterMessage} from '../../../constant';
import {useFilter} from '@unthinkable/react-filters';
import {Icon, ColumnTextRender} from '../styles/TaskTable.style';
import {status} from '../../../constant/status';
import images from '../../../assets/images';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {TextRender} from '@unthinkable/react-table';
import {
  isFacilityAdmin,
  isFacilityUser,
  isMedicalProvider,
} from '../../../utils/checkUserType';
import {Confirm} from '../../../components/confirm';
import {STATUS} from '../../user/constants/UserConstants';
import {
  TASK_STATUS,
  TASK_TYPE,
  TASK_TYPE_TO_SHOW,
  Tcm_TASKS,
} from '../../../constant/taskType';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {useToast} from '@unthinkable/react-toast';
import {isPracticeEmployee} from '../../../utils/checkEmployerType';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {CardStyle} from '../../../components/card/taskCard.style';
import {
  CardBody,
  CardFooter,
  CardHeader,
} from '../../../components/card/taskCard';
const {defaultListLimit} = Config;

const renderComplete = ({row}) => {
  const {user} = useAuth();
  const navigate = useNavigate();
  // for tcm tasks

  if (
    row?.type !== TASK_TYPE.NotesTask &&
    Tcm_TASKS[row?.type] &&
    checkAuthorization(user, entity.TcmTasks, action.complete)
  ) {
    const onPress = e => {
      if (row.type === TASK_TYPE.ContactTask) {
        navigate(
          `/tasks/${row.encounterId._id}/${row.taskId}/add-contact-attempts`,
          {
            state: {
              encounter: row?.encounterId,
              patient: row?.patientId,
            },
          },
        );
      } else if (row.type === TASK_TYPE.VisitTask) {
        navigate(`/tasks/complete-visit/${row.taskId}`, {
          state: {
            encounter: row?.encounterId,
            patient: row?.patientId,
          },
        });
      } else if (row.type === TASK_TYPE.MedicationTask) {
        navigate(`/tasks/medication-task`, {
          state: {
            encounter: row?.encounterId,
            patient: row?.patientId,
            isTcmMedicationButtonVisiable: true,
            medicationId: row._id,
          },
        });
      }
      e.stopPropagation();
    };
    return <Icon src={images.activation} onClick={onPress} />;
  }
  if (
    row?.type === TASK_TYPE.INITIAL_TCM_ELIGIBILITY &&
    checkAuthorization(user, entity.InitialTcmEligibility, action.complete)
  ) {
    const onPress = e => {
      navigate('/tasks/initial-eligibility', {
        state: {
          status: row.status,
          type: row.type,
          encounter: row?.encounterId,
          patientId: row?.patientId,
          tcmTaskId: row?._id,
        },
      });
      e.stopPropagation();
    };
    return <Icon src={images.activation} onClick={onPress} />;
  }
  // for form 1823
  if (
    row.type === TASK_TYPE.Form1823 &&
    checkAuthorization(user, entity.form1823, action.complete)
  ) {
    if (isFacilityAdmin() && row.status !== TASK_STATUS.Approved) {
      return (
        <Confirm
          title={TASK_TYPE.Form1823}
          message={'First Needed to be singed by PCP of patient'}
          confirmText="Okay">
          <Icon src={images.activation} />
        </Confirm>
      );
    } else {
      const onPress = e => {
        navigate(`/tasks/form1823/${row.taskId}`, {
          state: {
            status: row.status,
            type: row.type,
            encounter: row?.encounterId,
          },
        });
        e.stopPropagation();
      };
      return <Icon src={images.activation} onClick={onPress} />;
    }
  }

  if (
    row?.type === TASK_TYPE.FACILITY_ASSIGNMENT &&
    checkAuthorization(user, entity.FacilityAssignment, action.complete)
  ) {
    return (
      <Icon
        src={images.activation}
        onClick={e => {
          navigate('/tasks/facility-assignment', {
            state: {
              status: row.status,
              type: row.type,
              encounter: row?.encounterId,
              tcmTaskId: row?._id,
              patientId: row?.patientId,
            },
          });
          e.stopPropagation();
        }}
      />
    );
  }
};
const getDifference = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return 'Red';
  } else if (hours >= 24 && hours < 72) {
    return 'Yellow';
  } else if (hours >= 72) {
    return 'Gray';
  }
};

const tcmDueDate = ({row}) => {
  let dueDate = row?.tcmDueDate;
  if (row?.nextTcmDueDate) {
    if (new Date(row?.tcmDueDate) < new Date()) {
      dueDate = row?.nextTcmDueDate;
    }
  }
  if (!dueDate) {
    return '-';
  }
  return (
    <ColumnTextRender type={getDifference(dueDate)}>
      {moment(dueDate).utc().format(`${Config.dateFormat}`)}
    </ColumnTextRender>
  );
};

const renderLocation = ({row}) => {
  const location = row?.patientId?.currentLocation
    ? row?.patientId?.currentLocation?.name
    : row?.patientId?.currentLocationType?.value;
  return <TextRender value={location} />;
};

const renderTaskType = ({row}) => {
  return <TextRender value={TASK_TYPE_TO_SHOW[row?.type]} />;
};

const RenderGenerateNote = ({row}) => {
  const navigate = useNavigate();
  const {user} = useAuth();
  const toast = useToast();
  const activeTasks = row?.encounterId?.taskInfo?.filter(task => {
    return task.status === STATUS.ACTIVE && task.type !== TASK_TYPE.NotesTask;
  });
  const showGenerateNote = activeTasks?.length === 0;
  if (checkAuthorization(user, entity.TcmTasks, action.complete)) {
    if (row.type == TASK_TYPE.NotesTask) {
      return (
        <Icon
          src={images.notes}
          onClick={e => {
            if (showGenerateNote) {
              navigate(`/tasks/tcm-notes`, {
                state: {
                  titleName: row.patientId.FullName,
                  patient: row.patientId,
                  encounter: row.encounterId,
                  taskId: row._id,
                },
              });
            } else {
              toast({
                message: toasterMessage.tcmTaskNotCompleted.message,
                type: 'Error',
              });
            }
            e.stopPropagation();
          }}
        />
      );
    }
  }
};
export const TaskTable = props => {
  const {searchValue, onChangeFilter, filterValues} = useFilter();
  const {user} = useAuth();
  const navigate = useNavigate();
  const autoCompleteOptions = [
    ...(checkAuthorization(user, entity.TcmTasks, action.view)
      ? [
          {label: 'Medication Task', value: 'MedicationTask'},
          {label: 'Contact Task', value: 'ContactTask'},
          {label: 'Visit Task', value: 'VisitTask'},
          {label: 'Tcm Notes', value: 'NotesTask'},
        ]
      : []),
    ...(checkAuthorization(user, entity.form1823, action.view)
      ? [{label: 'Form1823', value: 'Form1823'}]
      : []),

    ...(checkAuthorization(user, entity.FacilityAssignment, action.view)
      ? [{label: 'Facility Assignment', value: 'Facility Assignment'}]
      : []),
    ...(checkAuthorization(user, entity.InitialTcmEligibility, action.view)
      ? [{label: 'Initial Tcm Eligibility', value: 'Initial Tcm Eligibility'}]
      : []),
  ];

  const {params: filterParams = {}} = filterValues;

  let filter = {
    status: {$ne: status.complete},
    'patientId.status': status.active,
  };
  if (isPracticeEmployee()) {
    filter = {
      ...filter,
      'patientId.primaryPractice': user?.practiceId?._id,
    };
  }
  if (isMedicalProvider()) {
    filter = {
      ...filter,
      owners: user?._id,
    };
  }

  const taskTypes = [];
  if (checkAuthorization(user, entity.TcmTasks, action.view)) {
    taskTypes.push(TASK_TYPE.MedicationTask);
    taskTypes.push(TASK_TYPE.ContactTask);
    taskTypes.push(TASK_TYPE.VisitTask);
    taskTypes.push(TASK_TYPE.NotesTask);
  }
  if (checkAuthorization(user, entity.InitialTcmEligibility, action.view)) {
    taskTypes.push(TASK_TYPE.INITIAL_TCM_ELIGIBILITY);
  }
  if (checkAuthorization(user, entity.FacilityAssignment, action.view)) {
    taskTypes.push(TASK_TYPE.FACILITY_ASSIGNMENT);
  }

  filter = {
    ...filter,
    type: {$in: taskTypes},
  };

  if (isFacilityAdmin()) {
    filter = {
      ...filter,
      $or: [
        {'patientId.currentLocation': user?.facilityId},
        {'patientId.residence': user?.facilityId},
      ],
    };
  } else if (isFacilityUser()) {
    filter = {
      ...filter,
      owners: user?._id,
    };
  }

  if (!isFacilityAdmin()) {
    filter['status'] = {$nin: [status.complete, status.approved]};
  }

  if (filterParams?.['type']) {
    filter = {
      ...filter,
      type: filterParams?.['type'],
    };
  }

  filterValues.filter = {
    ...filterValues.filter,
    ...filter,
  };
  const {isMobile} = useMediaQuery();
  const renderTaskCard = ({row}) => {
    const action = renderComplete({row: row});
    const actions = [action];
    return (
      <CardStyle>
        <CardHeader item={row} actions={actions} />
        <CardBody item={row} />
        <CardFooter item={row} />
      </CardStyle>
    );
  };
  let columns = [];
  if (isMobile) {
    columns = [
      {
        render: renderTaskCard,
      },
    ];
  } else {
    columns = [
      {
        header: 'Patient name',
        type: 'text',
        render: ({row}) => {
          const {FirstName, LastName, MiddleName} = row.patientId;
          return getPatientsFullName({FirstName, LastName, MiddleName});
        },
      },
      {
        header: 'Date Of Birth',
        field: 'patientId.DOB',
        type: 'date',
        formatOptions: {
          format: Config.dateFormat,
        },
      },
      {
        header: 'Task Type',
        render: renderTaskType,
      },
      {
        header: 'Location Of Patient',
        render: renderLocation,
      },
      {
        header: 'TCM Due Date',
        render: tcmDueDate,
      },
      {
        render: RenderGenerateNote,
        width: 50,
      },
      {
        render: renderComplete,
        width: 50,
      },
    ];
  }
  return (
    <Table
      onRowPress={({row}) => {
        navigate('/tasks/patient-details', {
          state: {
            patient: row.patientId,
            titleName: getPatientsFullName({
              FirstName: row.patientId.FirstName,
              MiddleName: row.patientId.MiddleName,
              LastName: row.patientId.LastName,
              Render: false,
            }),
          },
        });
      }}
      noDataText={'No task found'}
      renderHeader={() => (
        <TableHeader
          title={isMobile ? '' : 'Tasks'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search name or location'}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'type'}
              asParam={true}
              options={autoCompleteOptions}
              key={'taskTypeFilter'}
              placeholder={'Task type'}
              suggestionField={'label'}
              keyField={'value'}
              getDisplayValue={({options}) => {
                const selected = options?.find(
                  option =>
                    JSON.stringify(option.value) ===
                    JSON.stringify(filterValues?.params?.type),
                );
                return selected ? selected.label : '';
              }}
            />,
          ]}
        />
      )}
      style={{
        border: isMobile,
        rowBorder: isMobile,
        columnPadding: isMobile,
      }}
      sort={{isDateBounded: -1, onGoingTcmDueDate: 1, _id: 1}}
      search={searchValue}
      searchFields={[
        'patientId.FullName',
        'patientId.currentLocation.name',
        'patientId.currentLocationType.value',
      ]}
      api={`${routeLink.getTCMTask}`}
      eventSourceId="tcmtasks"
      addOnFilter={JSON.stringify(filterValues.filter)}
      perPage={defaultListLimit}
      fields={{
        _id: 1,
        patientId: {
          _id: 1,
          FirstName: 1,
          MiddleName: 1,
          LastName: 1,
          FullName: 1,
          DOB: 1,
          groupId: {
            _id: 1,
            name: 1,
            PNGroupId: 1,
          },
          currentLocationType: {
            value: 1,
          },
          residenceType: {
            value: 1,
          },
          currentLocation: {
            name: 1,
          },
          residence: 1,
          primaryPractice: {
            _id: 1,
          },
          latestEncounter: {
            taskInfo: {
              _id: 1,
            },
          },
        },
        owners: {
          name: 1,
          _id: 1,
        },
        type: 1,
        tcmDueDate: 1,
        encounterId: {
          _id: 1,
          dischargeDate: 1,
          taskInfo: {
            _id: 1,
            status: 1,
            type: 1,
          },
          tcmNotes: 1,
        },
        taskId: 1,
        nextTcmDueDate: 1,
        onGoingTcmDueDate: 1,
        isDateBounded: 1,
        status: 1,
      }}
      columns={columns}
      {...props}
    />
  );
};
