import React from 'react';
import moment from 'moment';
import {View, Text, TouchableOpacity} from '@unthinkable/react-core-components';

class Week extends React.Component {
  render() {
    let {children, primary, theme} = this.props;
    let {viewStyle, primaryViewStyle, textStyle, primaryTextStyle} =
      theme?.weekComponentStyle || {};
    let _viewStyle = primary
      ? {...viewStyle, ...primaryViewStyle}
      : {...viewStyle};
    let _textStyle = primary
      ? {...textStyle, ...primaryTextStyle}
      : {...textStyle};
    return (
      <View style={_viewStyle}>
        <Text style={_textStyle}>{children}</Text>
      </View>
    );
  }
}

class DateView extends React.Component {
  onChange = e => {
    e.preventDefault();
    let {onChange, value, year, month, date, clickEvent, toggleClickEvent} =
      this.props;

    const {isDisabled} = this.getSelectionState();
    if (isDisabled) {
      return;
    }
    let newDate = this.createDate({date, month, year});

    const {from, to} = value;

    if (clickEvent === 'start') {
      value = {from: newDate, to: void 0};
    } else {
      value = {from, to: newDate};
    }
    onChange && onChange(value);
    toggleClickEvent && toggleClickEvent();
  };

  createDate = ({date, month, year}) => {
    let newDate = new Date();

    newDate.setFullYear(year);
    newDate.setMonth(month, date);
    return newDate;
  };

  getSelectionState = () => {
    let {date, month, year, value} = this.props;
    let {from, to} = value || {};
    let newDate = this.createDate({date, month, year});
    let inBetween = false;
    let isSelected = false;
    let isDisabled = false;
    if (!from && !to) {
      return {inBetween, isSelected, isDisabled};
    }
    // create UTC moment objects from Date objects
    let momentFrom = from && moment.utc(from);
    let momentTo = to && moment.utc(to);
    let momentNewDate = moment.utc(newDate);
    if (
      (momentFrom && momentNewDate.isSame(momentFrom, 'day')) ||
      (momentTo && momentNewDate.isSame(momentTo, 'day'))
    ) {
      isSelected = true;
    } else if (
      momentFrom &&
      momentTo &&
      momentNewDate.isBetween(momentFrom, momentTo)
    ) {
      inBetween = true;
    }

    if (momentFrom && !momentTo && momentNewDate.isBefore(momentFrom)) {
      isDisabled = true;
    }
    return {inBetween, isSelected, isDisabled};
  };

  render() {
    let {
      children,
      deActive,
      onChange,
      date,
      month,
      year,
      renderDate,
      theme,
      value,
      ...rest
    } = this.props;
    let {
      viewStyle = {},
      textStyle = {},
      selectedTextStyle = {},
      selectedViewStyle = {},
      deActiveTextStyle = {},
      deActiveViewStyle = {},
      inBetweenStyle = {},
    } = theme.dateStyle || {};
    const {inBetween, isSelected, isDisabled} = this.getSelectionState();

    if (isDisabled) {
      deActive = true;
    }

    let _viewStyle = deActive
      ? {...viewStyle, ...deActiveViewStyle}
      : isSelected
      ? {...viewStyle, ...selectedViewStyle}
      : {...viewStyle};
    let _textStyle = deActive
      ? {...textStyle, ...deActiveTextStyle}
      : isSelected
      ? {...textStyle, ...selectedTextStyle}
      : {...textStyle};

    if (inBetween) {
      _viewStyle = {..._viewStyle, ...inBetweenStyle};
    }
    return (
      <TouchableOpacity style={_viewStyle} onClick={this.onChange}>
        {renderDate || (
          <Text style={{..._textStyle}} {...rest}>
            {children > 9 ? children : `0${children}`}
          </Text>
        )}
      </TouchableOpacity>
    );
  }
}

export default class RangeSelectDate extends React.Component {
  getDates() {
    const {
      month,
      year,
      onChange,
      value,
      renderDate,
      theme,
      clickEvent,
      toggleClickEvent,
    } = this.props;
    let dates = [];
    let currMonLstDtObj = new Date(year, month + 1, 0);
    let currMonLstDt = currMonLstDtObj.getDate();
    let currMonLstDay = currMonLstDtObj.getDay();
    let prevMonlstDtObj = new Date(year, month, 0);
    let prevMonLstDt = prevMonlstDtObj.getDate();
    let prevMonLstDay = prevMonlstDtObj.getDay();
    let perRowDate = 0;
    let dateToRender = [];
    while (prevMonLstDay >= 0 && prevMonLstDay < 6) {
      dates.unshift(
        <DateView
          theme={theme}
          deActive
          key={'prev' + prevMonLstDt}
          date={prevMonLstDt}
          renderDate={<></>}>
          {prevMonLstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.unshift(
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
            }}>
            {dates}
          </View>,
        );
        dates = [];
      }
      prevMonLstDt--;
      prevMonLstDay--;
    }
    let currMonFstDt = 1;
    while (currMonFstDt <= currMonLstDt) {
      //Main Month date view
      dates.push(
        <DateView
          theme={theme}
          key={currMonFstDt}
          // selected={date === currMonFstDt}
          date={currMonFstDt}
          month={month}
          year={year}
          value={value}
          onChange={onChange}
          toggleClickEvent={toggleClickEvent}
          clickEvent={clickEvent}
          renderDate={renderDate}>
          {currMonFstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(
          <View
            style={{
              flexDirection: 'row',
            }}>
            {dates}
          </View>,
        );
        dates = [];
      }
      currMonFstDt++;
    }
    currMonLstDay = 7 - currMonLstDay;
    let i = 1;
    while (i < currMonLstDay) {
      dates.push(
        <DateView
          theme={theme}
          deActive
          key={'next' + i}
          date={i}
          renderDate={<></>}>
          {/* {i} */}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(
          <View
            style={{
              flexDirection: 'row',
            }}>
            {dates}
          </View>,
        );
        dates = [];
      }
      i++;
    }

    return <React.Fragment>{dateToRender}</React.Fragment>;
  }

  render() {
    let {weekNames, monthNames, theme} = this.props;
    weekNames = weekNames || ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    monthNames = monthNames || [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let {containerStyle = {}, weekContainerStyle = {}} = theme || {};
    return (
      <View style={containerStyle}>
        <View style={weekContainerStyle}>
          <Week theme={theme} primary>
            {weekNames[0]}
          </Week>
          <Week theme={theme}>{weekNames[1]}</Week>
          <Week theme={theme}>{weekNames[2]}</Week>
          <Week theme={theme}>{weekNames[3]}</Week>
          <Week theme={theme}>{weekNames[4]}</Week>
          <Week theme={theme}>{weekNames[5]}</Week>
          <Week theme={theme}>{weekNames[6]}</Week>
        </View>
        <View style={{cursor: 'pointer'}}>{this.getDates()}</View>
      </View>
    );
  }
}
