export const formStyles = ({theme: {colors}}) => {
  return {
    footer: {
      container: {
        justifyContent: 'flex-end',
        marginTop: 8,
      },
    },
    formGroup: {
      backgroundColor: '#FFFFFF',
      oddBackgroundColor: '#FFFFFF',
    },
    formGroupLabel: {
      flex: 1,
      color: '#141E2E',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    header: {
      container: {
        backgroundColor: colors.BACKGROUND,
        marginBottom: 8,
      },
      title: {
        flex: 1,
        fontSize: '18px',
        lineHeight: '28px',
        fontWeight: 700,
      },
    },
  };
};
