import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  Text,
  Row,
} from '@unthinkable/react-core-components';
import {typecast} from '@unthinkable/react-date-picker';
import RangeSelectDate from './RangeSelectDate';
import moment from 'moment';
import {WithModal} from '@unthinkable/react-popper';
import {Button} from '@unthinkable/react-button';

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

class PrevHandler extends React.Component {
  render() {
    let {onClick, theme} = this.props;
    let {viewStyle, imageStyle, prevIcon: icon, textStyle} = theme;
    return (
      <View style={viewStyle} onClick={onClick}>
        {icon ? (
          <Image style={imageStyle} source={icon} />
        ) : (
          <Text style={textStyle}>{'<'}</Text>
        )}
      </View>
    );
  }
}

class NextHandler extends React.Component {
  render() {
    let {onClick, theme} = this.props;
    let {viewStyle, textStyle, nextIcon: icon, imageStyle} = theme;
    return (
      <View style={viewStyle} onClick={onClick}>
        {icon ? (
          <Image style={imageStyle} source={icon} />
        ) : (
          <Text style={textStyle}>{'>'}</Text>
        )}
      </View>
    );
  }
}

const getCurrentMonthYear = (currentMonth, currentYear) => {
  let year = currentYear;
  let nextYear = year;
  let month = currentMonth;
  let nextMonth = month + 1;
  if (currentMonth === 11) {
    nextYear = year + 1;
    nextMonth = 0;
  }

  return {month, year, nextMonth, nextYear};
};

const ModalView = props => {
  let {
    onDateChange: onDateChangeProp,
    hideModal,
    value: _value = {},
    typecast: propTypecast,
    styles,
  } = props;
  const {calendarStyle = {}} = styles;
  const {header, footer} = calendarStyle;
  const {
    containerStyle,
    prevContainerStyle,
    nextContainerStyle,
    prev,
    next,
    titleTextStyle,
  } = header || {};
  const {containerStyle: footerContainerStyle} = footer;
  let currentYear = moment(new Date()).toDate().getFullYear();

  const [clickEvent, setClickEvent] = useState('start');
  const [value, setValue] = useState(_value);
  const [previousMonth, setCurrentMonth] = useState(0);
  const [previousYear, setCurrentYear] = useState(0);

  const {month, year, nextMonth, nextYear} = useMemo(
    () => getCurrentMonthYear(previousMonth, previousYear),
    [previousMonth, previousYear],
  );

  useEffect(() => {
    // set current month and year
    let {from} = value;
    let currentDate = new Date();
    if (new Date(from) !== 'Invalid Date') {
      currentDate = from;
    }
    let month = moment(currentDate).toDate().getMonth();
    let year = moment(currentDate).toDate().getFullYear();
    setCurrentMonth(month);
    setCurrentYear(year);
  }, []);

  const nextHandler = useCallback(() => {
    if (previousMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(previousYear + 1);
    } else {
      setCurrentMonth(previousMonth + 1);
    }
  }, [previousMonth, previousYear, setCurrentMonth, setCurrentYear]);

  const prevHandler = useCallback(() => {
    if (previousMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(previousYear - 1);
    } else {
      setCurrentMonth(previousMonth - 1);
    }
  }, [previousMonth, previousYear, setCurrentMonth, setCurrentYear]);

  const onChange = data => {
    setValue({...value, ...data});
  };
  const toggleClickEvent = () => {
    let toggleEvent = clickEvent === 'start' ? 'end' : 'start';
    setClickEvent(toggleEvent);
  };

  const onDateChange = () => {
    let newValue = {};
    let typecastDate = propTypecast || typecast;
    if (typecastDate) {
      newValue.from = typecastDate(value.from, props);
      newValue.to = typecastDate(value.to, props);
      newValue = {...value, ...newValue};
    } else {
      newValue = value;
    }

    onDateChangeProp(newValue);
  };

  return (
    <View style={{flex: 1}}>
      <View style={containerStyle}>
        <View style={prevContainerStyle}>
          <PrevHandler onClick={prevHandler} theme={prev} />
          <Text style={titleTextStyle}>
            {Months[month]} {currentYear !== year ? year : void 0}
          </Text>
          <View />
        </View>

        <View style={{width: 24}} />
        <View style={nextContainerStyle}>
          <View />
          <Text style={titleTextStyle}>
            {Months[nextMonth]} {currentYear !== nextYear ? nextYear : void 0}
          </Text>
          <NextHandler onClick={nextHandler} theme={next} />
        </View>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <RangeSelectDate
          {...props}
          value={value}
          theme={calendarStyle}
          onChange={onChange}
          month={month}
          year={year}
          toggleClickEvent={toggleClickEvent}
          clickEvent={clickEvent}
        />
        <View style={{width: 24}} />
        <RangeSelectDate
          {...props}
          value={value}
          theme={calendarStyle}
          onChange={onChange}
          month={nextMonth}
          year={nextYear}
          toggleClickEvent={toggleClickEvent}
          clickEvent={clickEvent}
        />
      </View>
      <Row gap={10} style={footerContainerStyle}>
        <Button text="Cancel" onPress={hideModal} />
        <Button
          text="Save"
          onPress={() => {
            onDateChange();
            hideModal();
          }}
        />
      </Row>
    </View>
  );
};

export const DateRange = props => {
  let {children, styles} = props;
  const {calendarStyle: {dropdownStyle = {}} = {}} = styles;

  return (
    <WithModal
      renderModal={({hide}) => {
        return (
          <TouchableOpacity
            activeOpacity={1}
            style={dropdownStyle}
            onPress={() => {}}>
            <ModalView {...props} hideModal={hide} />
          </TouchableOpacity>
        );
      }}>
      {children}
    </WithModal>
  );
};
