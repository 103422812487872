import Regex from '../../utils/regex';

export const parseErrorMessage = err => {
    const cleanErrorMsg = err.message.replace(
        Regex.validationFailed,
        '',
      );
      const errorObj = {};
      const errors = cleanErrorMsg.split(', ');
      errors.forEach(error => {
        const [key, value] = error.split(/: (.+)/);
        const cleanKey = key.replace(/^Path `|`$/g, '').trim();
        const cleanValue = value.replace(/^Path `[^`]+` /, '').replace("(",'').replace(")",'').trim();
        errorObj[cleanKey] = cleanValue;
      });

    return errorObj
}