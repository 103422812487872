import {inputStyles} from '../Editors.styles';

export const ModalViewStyles = ({theme: {fonts, colors, icons}}) => {
  return {
    header: {
      container: {
        padding: 0,
        marginBottom: 16,
      },
      title: {...fonts.TITLE, color: colors.NEUTRAL_HIGH},
      secondaryTitle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_MEDIUM,
      },
    },
    container: {
      backgroundColor: colors.BACKGROUND,
      padding: 20,
      borderColor: colors.OUTLINE,
      borderWidth: 1,
    },
    unStyled: {
      container: {},
    },
    crossAbsoluteContainer: {
      padding: 4,
      position: 'absolute',
      top: 0,
      right: 0,
    },
    form: {
      container: {
        backgroundColor: colors.BACKGROUND,
      },
      crossAbsoluteContainer: {
        height: 24,
        width: 24,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 12,
        right: 12,
        backgroundColor: colors.SURFACE2,
        borderWidth: 1,
        borderRadius: 100,
        borderColor: colors.OUTLINE,
      },
    },
    crossIcon: icons.Cross,
    crossIconStyle: {
      height: 16,
      width: 16,
    },
    sm: {
      screenCenter: {
        width: '90%',
      },
    },
    md: {
      size: {
        auto: {maxWidth: '90%', maxHeight: '90%'},
        extraSmall: {width: 360, maxHeight: '60%'},
        small: {width: 480, maxHeight: '75%'},
        medium: {width: 720, minHeight: '60%'},
        large: {width: 960, minHeight: '70%'},
        extraLarge: {width: 1080, minHeight: '80%'},
        max: {width: '90%', height: '90%'},
      },
    },
  };
};

export const DateInputStyles = ({theme}) => {
  const {colors, fonts, shadows = {}} = theme;
  return {
    ...inputStyles({theme}),
    selectorContainer: {},
    selectorIconContainer: {},
    selectorIcon: {},
    crossIcon: {},
    modalContainerNative: {},
    modal: {
      ...ModalViewStyles({theme}),
      sm: {
        container: {
          backgroundColor: colors.BACKGROUND,
          padding: 10,
          width: '100%',
          height: '50%',
          ...shadows.shadow1,
        },
      },
      md: {
        container: {
          backgroundColor: colors.BACKGROUND,
          padding: 10,
          width: 300,
          height: 270,
          ...shadows.shadow1,
        },
      },
    },

    calendarStyle: {
      timeCalendarIconStyle: {
        image: void 0,
        imageStyle: {width: 15, height: 15},
        viewStyle: {paddingLeft: 6, paddingRight: 6},
      },

      calendarStyle: {
        dataView: {
          header: {
            containerStyle: {
              flexDirection: 'row',
              alignItems: 'center',
            },
            prev: {
              prevIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            next: {
              nextIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            year: {
              viewStyle: {
                cursor: 'pointer',
                flex: 1,
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
            month: {
              viewStyle: {
                flex: 1,
                cursor: 'pointer',
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
          },
          body: {
            weekContainerStyle: {
              flexDirection: 'row',
              paddingTop: 5,
              alignItems: 'center',
            },
          },
        },
        monthView: {
          containerStyle: {
            paddingTop: 10,
            paddingBottom: 10,
            ...fonts.BODY1,
          },
          header: {
            containerStyle: {
              flexDirection: 'row',
              alignItems: 'center',
            },
            prev: {
              prevIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            next: {
              nextIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            year: {
              viewStyle: {
                cursor: 'pointer',
                flex: 1,
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
          },
          bodyStyle: {
            monthRowStyle: {
              flexDirection: 'row',
              justifyContent: 'space-around',
              cursor: 'pointer',
            },
            monthTextStyle: {
              padding: 7,
              ...fonts.BODY3,
              color: colors.NEUTRAL_HIGH,
            },
            monthViewStyle: {
              cursor: 'pointer',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        },
        yearView: {
          containerStyle: {
            paddingTop: 10,
            paddingBottom: 10,
            ...fonts.BODY1,
          },
          header: {
            containerStyle: {
              flexDirection: 'row',
              alignItems: 'center',
            },
            prev: {
              prevIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            next: {
              nextIcon: void 0,
              imageStyle: {},
              viewStyle: {
                width: 20,
                height: 20,
                backgroundColor: colors.ACCENT1_LOW,
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 4,
              },
              textStyle: {
                color: colors.NEUTRAL_HIGH,
              },
            },
            year: {
              viewStyle: {
                cursor: 'pointer',
                flex: 1,
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center',
              },
              textStyle: {
                ...fonts.BODY1,
                color: colors.NEUTRAL_HIGH,
              },
            },
          },
          bodyStyle: {
            yearRowStyle: {
              flexDirection: 'row',
              cursor: 'pointer',
              justifyContent: 'space-between',
            },
            yearTextStyle: {
              padding: 7,
              ...fonts.BODY3,
              color: colors.NEUTRAL_HIGH,
            },
            yearViewStyle: {
              cursor: 'pointer',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        },
        dateStyle: {
          viewStyle: {
            flex: 1,
            paddingTop: 5,
            paddingBottom: 5,
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          },
          deActiveViewStyle: {},
          selectedViewStyle: {},
          textStyle: {
            paddingLeft: 5,
            paddingRight: 5,
            whiteSpace: 'pre-wrap',
            ...fonts.BODY3,
            color: colors.NEUTRAL_HIGH,
          },
          deActiveTextStyle: {
            color: colors.NEUTRAL_LOW,
          },
          selectedTextStyle: {
            color: colors.ACCENT1_HIGH,
            borderBottomStyle: 'solid',
            borderBottomColor: colors.ACCENT1_HIGH,
            borderBottomWidth: 2,
          },
        },
        weekComponentStyle: {
          viewStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            padding: 5,
            cursor: 'pointer',
          },
          textStyle: {
            textAlign: 'center',
            color: colors.NEUTRAL_MEDIUM,
            ...fonts.BODY1,
          },
          primaryViewStyle: {},
          primaryTextStyle: {
            color: colors.ERROR_HIGH,
          },
        },
        timeViewStye: {
          seperatorStye: {
            width: '100%',
            height: 1,
            backgroundColor: '#97979766',
            marginBottom: 5,
          },
          timeContainerStyle: {flexDirection: 'row', alignItems: 'center'},
          itemStyle: {paddingLeft: 5, alignItems: 'center'},
          mainStyle: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 6,
          },
        },
      },
    },
  };
};
