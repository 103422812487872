import {
  Image,
  Row,
  ScrollView,
  Styled,
  Text,
  TouchableOpacity,
  View,
  Wrap,
} from '@unthinkable/react-core-components';
import CheckBlack from '../images/CheckBlack.svg';

export const FormContainer = Styled(View)`
  flex:1;
  overflow:hidden;
  background-color: #FFFFFF;
  ${props => props.styles?.container}
`;

export const FormBody = Styled(ScrollView).attrs({
  keyboardShouldPersistTaps: 'always',
})`
  ${props => props.styles?.body}
`;

export const FormGroupContainer = Styled(View)`
padding: 12px;
border-radius:4px;
${({styles, level}) => {
  const {backgroundColor = '#F7F7F7', oddBackgroundColor = '#FFFFFF'} =
    styles?.formGroup || {};
  return {
    backgroundColor:
      !level || level % 2 === 0 ? backgroundColor : oddBackgroundColor,
  };
}}
`;

export const FormGroupRow = Styled(Row)`
  align-items: center;
  margin-bottom: 8px;
  ${props => props.styles?.formGroupRow}
`;

export const FormGroupImage = Styled(Row)`
  ${props => props.styles?.formGroupImage}
`;

export const FormGroupLabel = Styled(Text)`
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  color: #A3A3A3;
    ${props => props.styles?.formGroupLabel}
`;

export const InlineFormContainer = Styled(Row)`
  align-items: center;
  overflow:hidden;
  ${props => props.styles?.container}
`;

export const GroupButtonRow = Styled(Wrap).withConfig({
  shouldForwardProp: prop => !['activeCount'].includes(prop),
})`
  gap: 16px;
  ${props =>
    props.activeCount ? {paddingBottom: props.styles?.colGap || 12} : {}}
  ${props => props.styles?.groupButtonRow}
`;

export const GroupButton = Styled(TouchableOpacity)`
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid ${({isActive}) => (isActive ? '#1A1A1A' : '#EDEDED')};
  background-color: #F7F7F7;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  ${({styles}) => styles?.groupButton}
  ${({styles, isActive}) => isActive && styles?.groupActiveButton}
`;

export const GroupButtonText = Styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: #1A1A1A;
  ${({styles}) => styles?.groupButtonText}
  ${({styles, isActive}) => isActive && styles?.groupActiveButtonText}
`;

export const GroupButtonIcon = Styled(Image).attrs({
  source: CheckBlack,
})`
  width: 16px;
  height: 16px;
  ${({styles}) => styles?.groupButtonIcon}
`;
