export const validatePassword = value => {
  if (
    value &&
    !(
      /[A-Z]/.test(value) &&
      /[0-9]/.test(value) &&
      value.length >= 8 &&
      value.length <= 20
    )
  ) {
    return 'Please enter a valid password. Your new password must contain at least one upper case letter, numbers, and be between 8 and 20 characters.';
  }
};

export const validateEmail = value => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
  if (value && !value.match(emailRegex)) {
    return 'Please provide a valid email address.';
  }
};
