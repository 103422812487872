import React from 'react';
import {Form} from '../../../components/form';
import {status} from '../../../constant/status';
import {useAuth} from '../hooks/useAuth';
import {useFormSubmit} from '../../../hooks';
import {routeLink} from '../../../constant';
import {USERS_LIST_EVENT_SOURCE_ID} from '../../../modules/user/constants/UserConstants';
import {Button} from '../../../components/button';
import {View} from '@unthinkable/react-core-components';
import HttpAuth from '../../../services/HttpAuthService';
import {useToast} from '@unthinkable/react-toast';
import Theme from '../../../theme/Theme';
import {USER_TYPE} from '../../../constant/userType';
import {employerType} from '../../../constant/employerType';
import {AuthenticatorSetup} from './AuthenticatorSetup';
import {SwitchInput} from '@unthinkable/react-switch';
import {useNavigate} from 'react-router-dom';
import {isAdmin} from '../../../utils/checkUserType';
const Settings = () => {
  const toast = useToast();
  const {user} = useAuth();
  const {onSubmit} = useFormSubmit({
    uri: routeLink.editUser,
    eventSourceId: USERS_LIST_EVENT_SOURCE_ID,
  });
  const {colors, fonts} = Theme;
  const navigate = useNavigate();
  return (
    <Form
      saveButtonText="Save Changes"
      onSubmit={onSubmit}
      mode="edit"
      api={'/v1/users/' + user._id}
      fields={{
        name: 1,
        phoneNumber: 1,
        email: 1,
        practiceId: {
          name: 1,
        },
        facilityId: {
          name: 1,
        },
        employerType: {
          name: 1,
        },
        mfaMode: 1,
      }}
      layoutFields={[
        {
          label: 'Contact Info',
          fields: [
            {
              type: 'text',
              label: 'Name',
              field: 'name',
              size: 'medium',
            },
            {
              type: 'text',
              label: 'Phone No.',
              field: 'phoneNumber',
              size: 'medium',
            },
          ],
        },
        {
          label: 'Account Settings',
          fields: [
            {
              type: 'email',
              label: 'Email Address',
              field: 'email',
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'autoComplete',
              field: 'employerType.name',
              label: 'Employer Type',
              api: '/v1/employerTypes',
              suggestionField: 'name',
              size: 'medium',
              readOnly: true,
              visible: user.userType.name !== USER_TYPE.ADMIN,
            },
            {
              type: 'multiAutoComplete',
              field:
                user?.employerType?.name === employerType.facility
                  ? 'facilityId.name'
                  : 'practiceId.name',
              filter: {status: status.active},
              label:
                user?.employerType?.name === employerType.facility
                  ? 'Facility Assignment'
                  : 'Practice Assignment',
              suggestionField: 'name',
              secondarySuggestionField: 'shortName',
              size: 'medium',
              readOnly: true,
              visible: user.userType.name !== USER_TYPE.ADMIN,
            },
            {
              render: () => {
                return (
                  <View>
                    <Button
                      key="resetPassword"
                      text="Reset Password"
                      onPress={() => {
                        navigate('/settings/changePassword');
                      }}
                      styles={{
                        container: {
                          backgroundColor: colors.NEUTRAL_HIGH,
                          width: 200,
                        },
                        disabledContainer: {
                          backgroundColor: colors.OUTLINE,
                        },
                        text: {
                          ...fonts.ACTION,
                          color: colors.BACKGROUND,
                        },
                        disabledText: {color: colors.NEUTRAL_LOW},
                      }}
                    />
                  </View>
                );
              },
              size: 'large',
            },
          ],
        },
        {
          label: 'Two Factor Authentication Method',
          fields: [
            {
              type: 'autoComplete',
              field: 'mfaMode',
              options: ['Authenticator App', 'Email'],
              placeholder: 'Please select an option to enable 2FA.',
              onChangeValue: (value, e, {setFieldValue}) => {
                setFieldValue('authRequired', value === 'Authenticator App');
              },
              required: !isAdmin(),
            },
            {
              render: AuthenticatorSetup,
              visible: ({values}) => values.authRequired,
            },
            {
              type: 'text',
              label: 'OTP',
              field: 'otp',
              size: 8,
              visible: ({values}) => values.authRequired,
            },
            {
              render: (e, {values, setFieldValue}) => (
                <Button
                  text="Submit"
                  onPress={async () => {
                    try {
                      const response = await HttpAuth.post(
                        routeLink.verifyAuthenticatorOtp,
                        {
                          otp: values.otp,
                          user: user,
                        },
                      );
                      const resp = response.data;
                      if (resp.isVerified) {
                        setFieldValue('isVerified', true);
                      }
                    } catch (err) {
                      toast({
                        message: err.message,
                        type: 'Error',
                      });
                    }
                  }}
                />
              ),
              size: 4,
              visible: ({values}) => values.authRequired,
            },
            {
              render: SwitchInput,
              field: 'isVerified',
              label: 'Verification Status',
              readOnly: true,
              required: true,
              visible: ({values}) => values.authRequired,
            },
          ],
        },
      ]}
    />
  );
};

export default Settings;
