import { DiagnoseCategoryMapping } from "../modules/patient/constants/DiagnoseCategoryMapping";

export const generateCategoryWiseDiagnose = (data) => {
    const dataMap = {};
    data?.forEach(item => {
      if ( item?.diagnose?.Code && !dataMap[DiagnoseCategoryMapping[item.diagnose.Code[0]]]) {
        dataMap[DiagnoseCategoryMapping[item?.diagnose?.Code?.[0]]] = [item];
      } else if (item?.diagnose?.Code ){
        dataMap[DiagnoseCategoryMapping[item?.diagnose?.Code?.[0]]].push(item);
      }
    });
    const finalData = [
      {
        name: 'Header',
        expanded: true,
      },
    ];
    Object.keys(dataMap).forEach((key) => {
      const obj = {
        name: key,
        data: dataMap[key],
        expanded: true
      };
      finalData.push(obj);
    });
    return finalData;
  };