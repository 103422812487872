import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {status} from '../../../constant/status';
import {USER_TYPE} from '../../../constant/userType';
import {routeLink} from '../../../constant';
import HttpAuth from '../../../services/HttpAuthService';
import {STATE_CODE, ZIPCODE_LEN} from '../../../constant/stateCode';
import {
  facilityNameValidation,
  facilityShortNameValidation,
  phoneAndFaxValidation,
} from '../../../utils/validations/commonValidations';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {FACILITY_TYPE} from 'src/constant/facilityType';

export const AddFacility = props => {
  const {onSubmit} = useFormSubmit({
    uri: '/v1/facilities',
    eventSourceId: 'facilities',
  });
  const {state} = useLocation();
  const {facility} = state || {};
  const {user} = useAuth();
  return (
    <Form
      saveButtonText={props.mode === 'edit' ? 'Save' : 'Create Facility'}
      onSubmit={onSubmit}
      computations={{
        getaddress: {
          compute: async value => {
            try {
              const {data} = await HttpAuth.get(routeLink.getAddressesFromZip, {
                params: {
                  zipCode: value.zipCode,
                },
              });
              return {
                city: data.city,
                state: STATE_CODE[data.state],
              };
            } catch (error) {
              return {
                city: '',
                state: '',
              };
            }
          },
          multi: true,
          dependencies: ['zipCode'],
          condition: ({zipCode}) => {
            return zipCode.length == ZIPCODE_LEN;
          },
        },
      }}
      layoutFields={[
        {
          label: 'Facility Info',
          fields: [
            {
              type: 'text',
              label: 'Name',
              field: 'name',
              required: true,
              validate: facilityNameValidation,
            },
            {
              type: 'text',
              label: 'Short',
              field: 'shortName',
              validate: facilityShortNameValidation,
              required: !(props.mode === 'edit' && facility.isRedoxFacility),
              size: 'medium',
            },
            {
              type: 'autoComplete',
              field: 'type',
              label: 'Facility Type',
              required: true,
              options: [
                FACILITY_TYPE.AssistedLiving,
                FACILITY_TYPE.Hospital,
                FACILITY_TYPE.IndependentLiving,
                FACILITY_TYPE.InpatientPsych,
                FACILITY_TYPE.InpatientRehab,
                FACILITY_TYPE.SkilledNursing,
              ],
              size: 'medium',
            },
            {
              type: 'text',
              label: 'Street Address',
              field: 'streetAddress',
              required: !(props.mode === 'edit' && facility.isRedoxFacility),
              size: 'large',
            },
            {
              type: 'text',
              label: 'Zip Code',
              field: 'zipCode',
              required: !(props.mode === 'edit' && facility.isRedoxFacility),
              size: 'large',
            },
            {
              type: 'text',
              label: 'City',
              field: 'city',
              required: !(props.mode === 'edit' && facility.isRedoxFacility),
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'State',
              field: 'state',
              required: !(props.mode === 'edit' && facility.isRedoxFacility),
              size: 'medium',
              readOnly: true,
            },
            {
              type: 'text',
              label: 'Phone No.',
              field: 'phoneNumber',
              validate: value => phoneAndFaxValidation(value, 'phone'),
              required: !(props.mode === 'edit' && facility.isRedoxFacility),
              size: 'medium',
            },
            {
              type: 'text',
              label: 'Fax No.',
              validate: value => phoneAndFaxValidation(value, 'fax'),
              field: 'faxNumber',
              size: 'medium',
            },
            {
              type: 'autoComplete',
              field: 'isRedoxFacility',
              label: 'origin',
              size: 'medium',
              options: [
                {label: 'Elitecare Facility', value: false},
                {label: 'Redox Facility', value: true},
              ],
              key: 'origin',
              keyField: 'value',
              suggestionField: 'label',
              visible: props.mode === 'edit',
            },
            {
              type: 'autoComplete',
              field: 'isSubscribed',
              label: 'Subscribed?',
              size: 'medium',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ],
              key: 'isSubscribed',
              keyField: 'value',
              suggestionField: 'label',
              visible: ({values}) => {
                return (
                  checkAuthorization(
                    user,
                    entity.adminFacilities,
                    action.create,
                  ) && values.isRedoxFacility === false
                );
              },
            },
            {
              type: 'date',
              field: 'subscribedUpTo',
              label: 'Subscription Up To',
              size: 'medium',
              validate: date => {
                if (!date) return void 0;
                return date > new Date()
                  ? ''
                  : 'Date should be greater than current date';
              },
              visible: ({values}) =>
                values?.isSubscribed &&
                checkAuthorization(user, entity.adminFacilities, action.create),
            },
          ],
        },
        {
          label: 'Facility Admins',
          fields: [
            {
              type: 'autoComplete',
              field: 'primaryAdmin',
              filter: JSON.stringify({
                status: status.active,
                facilityId: props?.api?.split('/').at(-1),
                'userType.name': USER_TYPE.FACILITY_ADMIN,
              }),
              label: 'Primary Admin',
              api: '/v1/users',
              required: false,
              suggestionField: 'name',
              secondarySuggestionField: 'email',
              size: 'medium',
              sort: {name: 1},
            },
          ],
          visible: props.mode === 'edit',
        },
        {
          label: 'Practices',
          fields: [
            {
              type: 'multiAutoComplete',
              field: 'practices',
              label: 'Practices',
              api: '/v1/practices',
              required: !(props.mode === 'edit' && facility.isRedoxFacility),
              suggestionField: 'name',
              secondarySuggestionField: 'shortName',
              size: 'medium',
              sort: {name: 1},
              filter: ({values}) => {
                return JSON.stringify({
                  _id: {
                    $nin: [...(values?.practices || [])],
                  },
                  status: status.active,
                });
              },
            },
          ],
        },
      ]}
      {...props}
    />
  );
};

export const EditFacility = props => {
  const {facilityId} = useParams();
  return (
    <AddFacility
      {...props}
      header="Edit Facility"
      mode="edit"
      api={`/v1/facilities/${facilityId}`}
      fields={{
        name: 1,
        shortName: 1,
        phoneNumber: 1,
        faxNumber: 1,
        streetAddress: 1,
        city: 1,
        state: 1,
        zipCode: 1,
        type: 1,
        primaryAdmin: {
          name: 1,
        },
        practices: {
          name: 1,
        },
        isRedoxFacility: 1,
        isSubscribed: 1,
        subscribedUpTo: 1,
      }}
    />
  );
};
