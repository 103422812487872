import React from 'react';
import {useFilter} from '@unthinkable/react-filters';
import {useNavigate} from 'react-router-dom';
import Config from '../../../Config';
import {SearchFilter, SoloDateFilter} from '../../../components/filter/Filters';
import {TableHeader} from '../../../components/header/TableHeader';
import {Table} from '../../../components/table';
import {STATUS} from '../../user/constants/UserConstants';
import {routeLink} from '../../../constant';
import GetCountMessages from '../../chat/utils/GetCountMessages';
import moment from 'moment';
import {getPatientsFullName} from '../../../utils/commonMethod';

const {defaultListLimit, dateFormat} = Config;

const PatientChatList = props => {
  const navigate = useNavigate();
  const {searchValue, onChangeFilter, filterValues} = useFilter();

  filterValues.filter = {
    ...filterValues.filter,
    'patientDetails.status': STATUS.ACTIVE,
  };

  return (
    <Table
      noDataText={'No groups found'}
      onRowPress={({row}) => {
        navigate(
          `/patientgroups/chat/${row.groupId.PNGroupId}/${row.groupId._id}`,
          {
            state: {
              group: {
                name:
                  getPatientsFullName({
                    FirstName: row.groupId.FirstName,
                    MiddleName: row.groupId.MiddleName,
                    LastName: row.groupId.LastName,
                    Render: false,
                  }) +
                  ' ' +
                  moment(row.DOB).utc().format(dateFormat),
                _id: row.groupId._id,
              },
            },
          },
        );
      }}
      filter={JSON.stringify(filterValues.filter)}
      renderHeader={() => (
        <TableHeader
          title={'Groups'}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search groups'}
            />,
            <SoloDateFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'DOB'}
              key={'dateFilter'}
              placeholder={'DOB'}
            />,
          ]}
        />
      )}
      search={searchValue}
      searchFields={['patientDetails.FullName']}
      api={routeLink.getPatientChatGroups}
      eventSourceId="chatgroups"
      perPage={defaultListLimit}
      fields={{
        groupId: 1,
        DOB: 1,
        lastMessageTimeToken: 1,
        lastReadTimeToken: 1,
      }}
      columns={[
        {
          header: 'Group Name',
          type: 'text',
          render: ({row}) => {
            const {FirstName, LastName, MiddleName} = row.groupId;
            return getPatientsFullName({FirstName, LastName, MiddleName});
          },
        },
        {
          header: 'Date Of Birth',
          field: 'DOB',
          type: 'date',
          formatOptions: {
            format: dateFormat,
          },
        },
        {header: 'Status', field: 'groupId.status', type: 'text'},
        {
          header: 'Unread Messages',
          render: GetCountMessages,
        },
      ]}
      {...props}
    />
  );
};

export default PatientChatList;
