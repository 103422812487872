export const status = {
  active: 'Active',
  inactive: 'Inactive',
  invited: 'Invited',
  complete: 'Complete',
  approved: 'Approved',
};

export const TCMContactStatus = {
  successful: 'Successful',
  unsuccessful: 'Unsuccessful',
};
