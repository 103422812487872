import React, {forwardRef} from 'react';
import {
  AutoComplete as AutoCompleteComponent,
  MultiAutoComplete as MultiAutoCompleteComponent,
  Cascader as CascaderComponent,
  AutoCompleteRenderer as AutoCompleteRendererComponent,
  MultiAutoCompleteRenderer as MACRenderer,
  CascaderRenderer as CascaderRendererComponent,
} from '@unthinkable/react-autocomplete';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {AutoCompleteStyles, CascaderStyles} from './AutoComplete.styles.js';
import {HttpAuthService} from '../../../services';
import MultiInputTextChip from '../../form/MultiInputTextChip.js';

const fetch = async ({uri, props}) => {
  const result = await HttpAuthService.get(uri, {params: props});
  return result;
};

const resolveColor = () => () => {
  return {
    chip: {},
    avatar: {},
  };
};

export const AutoComplete = forwardRef(({onCreate, ...props}, ref) => {
  const styles = useStyles(AutoCompleteStyles);
  const colors = useTheme('colors');

  return (
    <AutoCompleteComponent
      ref={ref}
      styles={styles}
      fetch={fetch}
      resolveColor={resolveColor(colors)}
      {...props}
      onCreate={
        onCreate
          ? _props => {
              onCreate({..._props, ...props});
            }
          : void 0
      }
    />
  );
});
export const InputTextChip = forwardRef(({onCreate, ...props}, ref) => {
  const styles = useStyles(AutoCompleteStyles);
  const colors = useTheme('colors');
  return (
    <MultiInputTextChip
      ref={ref}
      styles={styles}
      multiple
      fetch={fetch}
      resolveColor={resolveColor(colors)}
      {...props}
      onCreate={
        onCreate
          ? _props => {
              onCreate({..._props, ...props});
            }
          : void 0
      }
    />
  );
});
export const MultiAutoComplete = forwardRef(({onCreate, ...props}, ref) => {
  const styles = useStyles(AutoCompleteStyles);
  const colors = useTheme('colors');
  return (
    <MultiAutoCompleteComponent
      ref={ref}
      styles={styles}
      fetch={fetch}
      resolveColor={resolveColor(colors)}
      {...props}
      onCreate={
        onCreate
          ? _props => {
              onCreate({..._props, ...props});
            }
          : void 0
      }
    />
  );
});

export const Cascader = forwardRef(({onCreate, ...props}, ref) => {
  const styles = useStyles(CascaderStyles);
  const {values, setFieldValue} = props.form;

  return (
    <CascaderComponent
      ref={ref}
      styles={styles}
      fetch={fetch}
      setFieldValue={setFieldValue}
      values={values}
      {...props}
      onCreate={
        onCreate
          ? _props => {
              onCreate({..._props, ...props});
            }
          : void 0
      }
    />
  );
});

export const AutoCompleteRenderer = props => {
  const styles = useStyles(AutoCompleteStyles);
  const colors = useTheme('colors');
  return (
    <AutoCompleteRendererComponent
      {...props}
      styles={styles}
      resolveColor={resolveColor(colors)}
    />
  );
};

export const MultiAutoCompleteRenderer = props => {
  const styles = useStyles(AutoCompleteStyles);
  const colors = useTheme('colors');
  return (
    <MACRenderer
      {...props}
      styles={styles}
      resolveColor={resolveColor(colors)}
    />
  );
};

export const CascaderRenderer = props => {
  const styles = useStyles(CascaderStyles);
  const {values} = props.form;
  return (
    <CascaderRendererComponent {...props} values={values} styles={styles} />
  );
};
