import {CNavItem, CSidebar, CSidebarNav} from '@coreui/react';
import {useTheme} from '@unthinkable/react-theme';
import React, {useEffect, useState} from 'react';
import AppFooter from './AppFooter';
import {
  BrandContainer,
  BrandIcon,
  GroupItemContainer,
  GroupItemIcon,
  GroupItemText,
  SidebarNavContainer,
} from './AppSidebar.style';
import {AppSidebarNav} from './AppSidebarNav';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {action, entity} from '../constant/authorization';
import checkAuthorization from '../services/accessService';
import {routeLink} from '../constant';
import {status} from '../constant/status';
import {HttpAuthService} from '../services';
import {TASK_TYPE} from '../constant/taskType';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {
  isFacilityAdmin,
  isFacilityUser,
  isMedicalProvider,
} from '../utils/checkUserType';
import {isPracticeEmployee} from '../utils/checkEmployerType';

const GroupItem = item => {
  const {children, icon, name, isMenuSelected} = item;
  const [isParentHovered, setParentParentHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setParentParentHovered(true);
      }}
      onMouseLeave={() => {
        setParentParentHovered(false);
      }}>
      <GroupItemContainer>
        {icon && <GroupItemIcon src={icon} />}
        <GroupItemText>{name && name}</GroupItemText>
      </GroupItemContainer>
      <div hidden={!isParentHovered && !isMenuSelected}>{children}</div>
    </div>
  );
};

const AppSidebar = ({sidebarShow, setSidebarShow}) => {
  const {user} = useAuth();
  const IS_PRACTICE_EMPLOYEE = isPracticeEmployee();
  const IS_MEDICAL_PROVIDER = isMedicalProvider();
  const IS_FACILITY_ADMIN = isFacilityAdmin();
  const IS_FACILITY_USER = isFacilityUser();
  const [taskCount, setTaskCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [patientUnreadMessageCount, setPatientUnreadMessageCount] = useState(0);
  const items = [
    {
      component: CNavItem,
      name: 'Facilities',
      to: '/facilities-company',
    },
    {
      component: CNavItem,
      name: 'Practices',
      to: '/practices',
    },
    {
      component: CNavItem,
      name: 'User',
      to: '/users',
    },
    {
      component: CNavItem,
      name: 'Audit Logs',
      to: '/audit-logs',
    },
    {
      component: CNavItem,
      name: 'New Requests',
      to: '/new-requests',
    },
    {
      component: CNavItem,
      name: 'Cron Settings',
      to: '/cron-setting',
    },
  ];

  // for render menu on basis of permission
  const itemsToShow = items.filter(item => {
    let NAME = item.name;
    if (NAME === 'Facilities') {
      NAME = 'AdminFacilities';
    }
    return (
      checkAuthorization(user, NAME, action.view) ||
      checkAuthorization(user, NAME, action.viewAll) ||
      checkAuthorization(user, NAME, action.viewOwn)
    );
  });

  const {
    Buildings,
    BuildingsSelected,
    Home,
    HomeSelected,
    Patients,
    PatientsSelected,
    Admin,
    AdminSelected,
    chatSmile,
    chatSmileSelected,
    PatientChat,
    PatientChatSelected,
    Tasks,
    TasksSelected,
    FullBrandLogo,
  } = useTheme('icons');

  const _nav = [
    {
      component: CNavItem,
      name: 'Dashboard',
      to: '/dashboard',
      icon: Home,
      selectedIcon: HomeSelected,
    },
    {
      component: CNavItem,
      name: 'Facilities',
      to: '/facility-stats',
      icon: Buildings,
      selectedIcon: BuildingsSelected,
    },
    {
      component: CNavItem,
      name: 'Patients',
      to: '/patient',
      icon: Patients,
      selectedIcon: PatientsSelected,
    },
    {
      component: CNavItem,
      name: 'Chat',
      to: '/groups',
      icon: chatSmile,
      selectedIcon: chatSmileSelected,
      notificationCount: unreadMessageCount,
    },
    {
      component: CNavItem,
      name: 'Patient Chat',
      to: '/patientgroups',
      icon: PatientChat,
      selectedIcon: PatientChatSelected,
      notificationCount: patientUnreadMessageCount,
    },
    {
      component: CNavItem,
      name: 'Tasks',
      to: '/tasks',
      icon: Tasks,
      selectedIcon: TasksSelected,
      notificationCount: taskCount > 999 ? '999+' : taskCount,
    },
    {
      component: GroupItem,
      name: 'Admin',
      to: '/admin/',
      icon: Admin,
      selectedIcon: AdminSelected,
      items: itemsToShow,
    },
  ];

  const _navToShow = _nav.filter(item => {
    if (
      !user?.chatAuthKey &&
      (item.name === 'Chat' || item.name === 'Patient Chat')
    ) {
      return false;
    }
    return (
      checkAuthorization(user, item.name, action.viewAll) ||
      checkAuthorization(user, item.name, action.viewOwn) ||
      checkAuthorization(user, item.name, action.view)
    );
  });
  const fetchIncompleteTaskCount = async () => {
    let filter = {
      status: {$ne: status.complete},
      'patientId.status': status.active,
    };
    if (IS_PRACTICE_EMPLOYEE) {
      filter = {
        ...filter,
        'patientId.primaryPractice': user?.practiceId?._id,
      };
    }
    if (IS_MEDICAL_PROVIDER) {
      filter = {
        ...filter,
        owners: user?._id,
      };
    }

    const taskTypes = [];
    if (checkAuthorization(user, entity.TcmTasks, action.view)) {
      taskTypes.push(TASK_TYPE.MedicationTask);
      taskTypes.push(TASK_TYPE.ContactTask);
      taskTypes.push(TASK_TYPE.VisitTask);
      taskTypes.push(TASK_TYPE.NotesTask);
    }
    if (checkAuthorization(user, entity.InitialTcmEligibility, action.view)) {
      taskTypes.push(TASK_TYPE.INITIAL_TCM_ELIGIBILITY);
    }
    if (checkAuthorization(user, entity.FacilityAssignment, action.view)) {
      taskTypes.push(TASK_TYPE.FACILITY_ASSIGNMENT);
    }

    filter = {
      ...filter,
      type: {$in: taskTypes},
    };

    if (IS_FACILITY_ADMIN) {
      filter = {
        ...filter,
        $or: [
          {'patientId.currentLocation': user?.facilityId},
          {'patientId.residence': user?.facilityId},
        ],
      };
    } else if (IS_FACILITY_USER) {
      filter = {
        ...filter,
        owners: user?._id,
      };
    }

    if (!IS_FACILITY_ADMIN) {
      filter['status'] = {$nin: [status.complete, status.approved]};
    }

    const response = await HttpAuthService.get(routeLink.getTCMTask, {
      params: {
        filter: JSON.stringify(filter),
      },
    });
    setTaskCount(response.data.length);
  };
  useDataFetchEvents(
    {
      eventSourceId: ['unreadMessageCountUpdate' ,'unreadMessageCount'],
    },
    () => {
      fetchUnreadChatMessages();
    },
  );
  const fetchUnreadChatMessages = async () => {
    const response = await HttpAuthService.get(routeLink.getUnreadMessageCount);
    setUnreadMessageCount(response.data.orgUnReadMessageCount);
    setPatientUnreadMessageCount(response.data.patientUnreadMessageCount);
  };

  useEffect(() => {
    fetchIncompleteTaskCount();
    fetchUnreadChatMessages();
  }, []);

  useDataFetchEvents(
    {
      eventSourceId: [
        'visitAttempt',
        'tcm-notes-status',
        'contactAttempts',
        'form1823Task',
        'addMedication',
        'initialTcmEligibility',
        'facilityAssignment',
      ],
    },
    () => {
      fetchIncompleteTaskCount();
    },
  );
  return (
    <CSidebar
      position="sticky"
      visible={sidebarShow}
      onVisibleChange={visible => setSidebarShow(visible)}>
      <BrandContainer>
        <BrandIcon src={FullBrandLogo} />
      </BrandContainer>
      <CSidebarNav>
        <SidebarNavContainer>
          <AppSidebarNav items={_navToShow} setSidebarShow={setSidebarShow} />
        </SidebarNavContainer>
      </CSidebarNav>
      <AppFooter setSidebarShow={setSidebarShow} />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
