import moment from 'moment';

export const getZeroTimeDate = (date, eod) => {
  if (!date) {
    return date;
  }
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }
  let minutes = date.getTimezoneOffset() * -1;
  if (eod) {
    return moment(date).endOf('day').add(minutes, 'minutes').utc().toDate();
  } else {
    return moment(date).startOf('day').add(minutes, 'minutes').utc().toDate();
  }
};
/*Addded by Jatin on 6/2/19 --For DateFilter in Client Side Query */
export const getDateFilter = date => {
  if (!date) {
    return null;
  }
  let backDate = new Date(date);
  backDate.setUTCHours(0);
  backDate.setUTCMinutes(0);
  backDate.setUTCSeconds(0);
  backDate.setUTCMilliseconds(0);
  backDate.setUTCDate(backDate.getUTCDate());

  let nxtDate = new Date(date);
  nxtDate.setUTCHours(0);
  nxtDate.setUTCMinutes(0);
  nxtDate.setUTCSeconds(0);
  nxtDate.setUTCMilliseconds(0);

  nxtDate.setUTCDate(nxtDate.getUTCDate() + 1);
  return {$gte: backDate, $lt: nxtDate};
};

export const typecastDate = value => {
  if (typeof value === 'string') {
    value = value.trim();
  }
  if (!(value instanceof Date)) {
    value = Date.parse(value);
  }
  if (!value || value === 'Invalid Date') {
    value = null;
  }
  return value;
};

export const typecastTime = (value, props = {}) => {
  let numbers = void 0;
  if (value) {
    value = value.trim().toLowerCase();
    numbers = value.match(/\d+/g);
  }
  let hr = 0;
  let min = 0;
  if (numbers) {
    hr = parseInt(numbers[0]) || 0;
    min = parseInt(numbers[1]) || 0;
    if (value.indexOf('am') !== -1 && hr === 12) {
      hr = 0;
    } else if (value.indexOf('pm') !== -1 && hr < 12) {
      hr = parseInt(hr) + 12;
    }
  }
  let newValue = props.value ? new Date(props.value) : new Date();
  newValue.setHours(hr, min, 0, 0);
  return newValue;
};

export const _CurrentDate = () => {
  return getZeroTimeDate(new Date());
};

export const dateFormatter = (value, format = 'DD MMM YY', isZeroTime) => {
  if (value === undefined || value === null || value === '') {
    return '';
  }
  let formattedValue = moment(value);
  if (isZeroTime) {
    formattedValue = formattedValue.utc();
  }
  if (format === 'fromNow') {
    /*return how much time is elapsed like == 1 min ago or 24 days ago etc -- akshayKanyan 6Jan*/
    return formattedValue.fromNow();
  }
  return formattedValue.format(format);
};

export const getDateRangeLabel = (range, {from, to}, isZeroTime) => {
  if (range === 'Today' || range === 'Yesterday') {
    return dateFormatter(from, 'DD MMM YY', isZeroTime);
  } else if (range === 'Year') {
    return dateFormatter(from, 'YYYY', isZeroTime);
  } else if (range === 'Month' || range === 'LastMonth') {
    return dateFormatter(from, 'MMM YYYY', isZeroTime);
  } else if (range === 'Custom') {
    return `${dateFormatter(from, 'DD MMM YY', isZeroTime)} - ${dateFormatter(
      to,
      'DD MMM YY',
      isZeroTime,
    )}`;
  } else {
    return `${dateFormatter(from, 'DD MMM', isZeroTime)} - ${dateFormatter(
      to,
      'DD MMM',
      isZeroTime,
    )}`;
  }
};

export function getZeroTimeDateRange(rangeStr, rangeOptions) {
  let {from, to} = getDateRange(rangeStr, rangeOptions);
  return {
    from: getZeroTimeDate(from),
    to: getZeroTimeDate(to, true),
  };
}

const getFinancialYearDates = (year, date) => {
  date = moment(date).toDate();
  let currentMonth = date.getMonth();
  if (currentMonth < 3) {
    year -= 1;
  }
  let startDate = new Date(`1 april ${year}`);
  let endDate = new Date(`1 march ${year + 1}`);
  return {startDate, endDate};
};

export const getFinancialYear = date => {
  date = moment(date).toDate();
  let currentMonth = date.getMonth();
  let year = date.getFullYear();
  if (currentMonth < 3) {
    year -= 1;
  }
  return year;
};

export function getDateRange(rangeStr = '', rangeOptions = {}) {
  var {date = new Date()} = rangeOptions;
  date = moment(date).toDate();
  var from, to;
  switch (rangeStr) {
    case 'day':
      from = moment(date).startOf('day').toDate();
      to = moment(date).endOf('day').toDate();
      break;
    case 'today':
      from = moment(date).startOf('day').toDate();
      to = moment(date).endOf('day').toDate();
      break;

    case 'yesterday':
      from = moment(date).subtract(1, 'day').startOf('day').toDate();
      to = moment(date).subtract(1, 'day').endOf('day').toDate();
      break;

    case 'tomorrow':
      from = moment(date).add(1, 'day').startOf('day').toDate();
      to = moment(date).add(1, 'day').endOf('day').toDate();
      break;

    case 'week':
      from = moment(date).startOf('week').toDate();
      to = moment(date).endOf('week').toDate();
      break;

    case 'thisWeek':
      from = moment(date).startOf('week').toDate();
      to = moment(date).endOf('week').toDate();
      break;

    case 'thisMonth':
      from = moment(date).startOf('month').toDate();
      to = moment(date).endOf('month').toDate();
      break;

    case 'quarter':
      from = moment(date).startOf('quarter').toDate();
      to = moment(date).endOf('quarter').toDate();
      break;

    case 'thisYear':
      from = moment(date).startOf('year').toDate();
      to = moment(date).endOf('year').toDate();
      break;

    case 'lastYear':
      from = moment(date).subtract(1, 'year').startOf('year').toDate();
      to = moment(date).subtract(1, 'year').endOf('year').toDate();
      break;

    case 'lastWeek':
      from = moment(date).subtract(1, 'weeks').startOf('Week').toDate();
      to = moment(date).subtract(1, 'weeks').endOf('Week').toDate();
      break;

    case 'lastMonth':
      from = moment(date).subtract(1, 'month').startOf('month').toDate();
      to = moment(date).subtract(1, 'month').endOf('month').toDate();
      break;

    case 'last7Days': {
      from = moment(date)
        .subtract(7 - 1, 'day')
        .startOf('day')
        .toDate();
      to = moment(date).endOf('day').toDate();
      break;
    }

    case 'last30Days': {
      from = true
        ? moment(date)
            .subtract(30 - 1, 'day')
            .startOf('day')
            .toDate()
        : void 0;
      to = true ? moment(date).endOf('day').toDate() : void 0;
      break;
    }

    case 'currentFinancialYear': {
      let year = date.getFullYear();
      let {startDate, endDate} = getFinancialYearDates(year, date);
      from = moment(startDate).startOf('month').toDate();
      to = moment(endDate).endOf('month').toDate();
      break;
    }
    case 'previousFinancialYear': {
      let year = date.getFullYear() - 1;
      let {startDate, endDate} = getFinancialYearDates(year, date);
      from = moment(startDate).startOf('month').toDate();
      to = moment(endDate).endOf('month').toDate();
      break;
    }
    default:
  }
  return {
    from,
    to,
  };
}

export const fromNowDateFormatterWithI18N = date => {
  date = new Date(date);
  let currentDate = getZeroTimeDate(new Date());
  let dateYear = date.getFullYear();
  let currentYear = currentDate.getFullYear();
  let dateMonth = date.getMonth();
  let currentMonth = currentDate.getMonth();
  let startWeek = moment().startOf('isoWeek').toDate();

  let dateStr;
  if (dateYear !== currentYear) {
    if (dateYear === currentYear - 1) {
      dateStr = 'Last Year';
    } else {
      dateStr = dateYear;
    }
  } else if (dateMonth !== currentMonth) {
    if (dateMonth === currentMonth - 1) {
      dateStr = 'Last Month';
    } else {
      dateStr = moment(date).format('MMMM');
    }
  } else if (date < startWeek) {
    let weekDiff = parseInt((startWeek.getDate() - date.getDate()) / 7);
    if (weekDiff === 0) {
      dateStr = 'Last Week';
    } else {
      dateStr = `${weekDiff + 1} week ago`;
    }
  } else {
    let dateDiff = currentDate.getDate() - date.getDate();
    if (dateDiff > 1) {
      dateStr = moment(date).format('dddd');
    } else if (dateDiff === 1) {
      dateStr = 'Yesterday';
    } else {
      dateStr = 'Today';
    }
  }
  return dateStr;
};

const showInDoubleDigits = num => {
  return Math.floor(num / 10) === 0 ? `0${num}` : num;
};

export const getTimeStringFromSeconds = time => {
  if (time === undefined || isNaN(time)) {
    return '-';
  }
  time = Math.round(time);
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time - hours * 3600) / 60);
  let seconds = time - minutes * 60 - hours * 3600;
  //show time in double digits
  hours = hours && showInDoubleDigits(hours);
  minutes = showInDoubleDigits(minutes);
  seconds = showInDoubleDigits(seconds);
  // if no hours is present in time then show min and secs only
  return hours ? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
};

// todo
export const getFormatValue = (value, props = {}) => {
  let {format = 'DD MMM YY', zeroTimeDate, time} = props;
  if (zeroTimeDate === undefined && !time) {
    zeroTimeDate = true;
  }
  return dateFormatter(value, format, zeroTimeDate);
};

// todo
export const typecast = (value, props = {}) => {
  value = typecastDate(value);
  if (!value) {
    return value;
  }
  let {time, eod} = props;
  if (time) {
    if (props.value) {
      let prevValue = new Date(props.value);
      prevValue.setDate(value.getDate());
      prevValue.setMonth(value.getMonth());
      prevValue.setFullYear(value.getFullYear());
      value = prevValue;
    }
  } else {
    value = getZeroTimeDate(value, eod);
  }
  return value;
};

export const getFinancialYearDisplay = date => {
  let currentFyYear = getFinancialYear(date);
  let nextFyYear = moment()
    .set('year', currentFyYear + 1)
    .format('YY');
  return `${currentFyYear} - ${nextFyYear}`;
};

export const getDateRangeDisplay = ({from, to, filterOf} = {}) => {
  if (!from || !to) return 'Select Range';

  let fromStr = '';
  let toStr = '';
  let finalStr = '';
  if (filterOf === 'FY') {
    finalStr = `FY ${getFinancialYearDisplay(from)}`;
  } else if (filterOf === 'Month') {
    finalStr = moment(from).utc().format('MMMM YYYY');
  } else if (filterOf === 'Quarter') {
    let obj = {
      1: 4,
      2: 1,
      3: 2,
      4: 3,
    };
    fromStr = moment(from).utc().format('MMM');
    toStr = moment(to).utc().format('MMM');
    const quarter = moment(from).quarter();
    finalStr = `Q${obj[quarter]}(${fromStr}-${toStr}) ${moment(from).format(
      'YYYY',
    )}`;
  } else if (filterOf === 'day') {
    finalStr = moment(from).utc().format('DD MMM');
  } else if (filterOf === 'week') {
    fromStr = moment(from).utc().format('DD MMM');
    toStr = moment(to).utc().format('DD MMM');
    finalStr = `${fromStr} - ${toStr}`;
  } else {
    fromStr = moment(from).utc().format('DD MMM, YY');
    toStr = moment(to).utc().format('DD MMM, YY');
    finalStr = `${fromStr} - ${toStr}`;
  }
  return finalStr;
};
