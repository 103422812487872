const BackHandler = {};

BackHandler.exitApp = () => {
  return;
};
BackHandler.addEventListener = (eventName, callback) => {
  return;
};

BackHandler.removeEventListener = (eventName, callback) => {
  return;
};

export default BackHandler;
