import React from 'react';
import {DatePicker} from '@unthinkable/react-date-picker';
import {useStyles} from '@unthinkable/react-theme';
import {DateInputStyles} from './DateInput.style';

export const DateInput = props => {
  let styles = useStyles(DateInputStyles);
  return (
    <DatePicker
      {...props}
      styles={styles}
      typecast={value => new Date(value)}
    />
  );
};
