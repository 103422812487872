import React from 'react';
import {Form} from '../../../components/form';
import {useFormSubmit} from '../../../hooks';
import {routeLink} from '../../../constant';
import {useLocation} from 'react-router-dom';

export const EditOutPatientMedicationDetails = props => {
  const {onSubmit} = useFormSubmit({
    uri: routeLink.getOutPatientMedication,
    eventSourceId: ['outpatient-medications'],
  });
  const {
    state: {id,titleName},
  } = useLocation();
  return (
    <Form
      header={{title: 'Edit Out Patient Medication', secondaryTitle: titleName}}
      skipHeader={false}
      mode={'edit'}
      onSubmit={onSubmit}
      api={`${routeLink.getOutPatientMedication}/${id}`}
      fields={{
        Dose: 1,
        Frequency: 1,
      }}
      layoutFields={[
        {
          label: 'Dose',
          fields: [
            {
              type: 'text',
              field: 'Dose.Quantity',
              label: 'Quantity',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'Dose.Units',
              label: 'Units',
              size: 'medium',
              required: true,
            },
          ],
        },
        {
          label: 'Frequency',
          fields: [
            {
              type: 'text',
              field: 'Frequency.Period',
              label: 'Period',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'Frequency.Unit',
              label: 'Unit',
              size: 'medium',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
