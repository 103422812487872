import React from 'react';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {useFormSubmit} from '../../../hooks';
import {useNavigate} from 'react-router-dom';
import {useToast} from '@unthinkable/react-toast';

export const cronSettings = () => {
  const {onSubmit} = useFormSubmit({
    uri: routeLink.cronSettings,
  });
  const navigate = useNavigate();
  const toast = useToast();
  return (
    <Form
      onSubmit={onSubmit}
      onSubmitSuccess={(message = 'Data Saved.') => {
        message && toast({message});
        navigate('dashboard');
      }}
      mode="edit"
      api={routeLink.cronSettings}
      layoutFields={[
        {
          fields: [
            {
              type: 'autoComplete',
              field: 'alertTcmTasks',
              label: 'Alert Care Team Member About Due Tcm Tasks',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ],
              keyField: 'value',
              suggestionField: 'label',
              size: 'medium',
            },
            {
              type: 'autoComplete',
              field: 'completeTcmTasks',
              label: 'Update Encounter status After 7 days of Tcm Due Date',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ],
              keyField: 'value',
              suggestionField: 'label',
              size: 'medium',
            },
            {
              type: 'autoComplete',
              field: 'fetchClinicalSummary',
              label:
                'Fetch Clinical Summary After Seven Days Of Patient Creation',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ],
              keyField: 'value',
              suggestionField: 'label',
              size: 'medium',
            },
            {
              type: 'autoComplete',
              field: 'fetchClinicalSummaryOnLocationChange',
              label: 'Fetch Clinical Summary On Current Location Change',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ],
              keyField: 'value',
              suggestionField: 'label',
              size: 'medium',
            },
          ],
        },
      ]}
    />
  );
};
