import React from 'react';
import {Table} from '../../../components/table';
import {routeLink} from '../../../constant';
import {TableHeader} from '../../../components/header/TableHeader';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {useLocation, useNavigate} from 'react-router-dom';
import {Patient_Location_Type} from '../../../modules/patient/constants/PatientConstants';
import {TextRenderer} from '../../../components/formEditors/Editors';
import {ColumnTextRender} from '../../tasks/styles/TaskTable.style';
import moment from 'moment';
import Config from '../../../Config';
import {TaskWidget} from '../../../modules/patient/components/PatientHeader';
import {Row} from '@unthinkable/react-core-components';
import {STATUS} from '../../user/constants/UserConstants';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {status} from '../../../constant/status';
import {isPracticeAdmin} from '../../../utils/checkUserType';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {ENCOUNTER_STATUS} from '../../../constant/encounterStatus';
import {FACILITY_TYPE} from '../../../constant/facilityType';
import {renderPatientCard} from '../../../components/card/patientCard';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {TaskCardFooter} from '../../../components/card/taskCard.style';

const getDifference = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return 'Red';
  } else if (hours >= 24 && hours < 72) {
    return 'Yellow';
  } else if (hours >= 72) {
    return 'Gray';
  }
};

const TcmDueDate = ({row}) => {
  if (
    !row?.latestEncounter ||
    row?.latestEncounter?.status === ENCOUNTER_STATUS.BILLABLE ||
    row?.latestEncounter?.status === ENCOUNTER_STATUS.INCOMPLETE
  ) {
    return;
  } else if (row?.latestEncounter?.onGoingTcmDueDate) {
    return (
      <ColumnTextRender
        type={getDifference(row?.latestEncounter?.onGoingTcmDueDate)}>
        {moment(row?.latestEncounter?.onGoingTcmDueDate)
          .utc()
          .format(`${Config.dateFormat}`)}
      </ColumnTextRender>
    );
  }
};

export const YourPatientsTable = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {
    state: {facility},
  } = useLocation();
  const {isMobile} = useMediaQuery();

  if (checkAuthorization(user, entity.yourPatient, action.viewAll)) {
    let filter = {};
    if (facility.type === FACILITY_TYPE.SkilledNursing) {
      filter = {
        $or: [{residence: facility._id}, {currentLocation: facility._id}],
        status: status.active,
      };
    } else {
      filter = {residence: facility._id, status: status.active};
    }

    if (isPracticeAdmin()) {
      filter = {
        ...filter,
        primaryPractice: user?.practiceId,
      };
    }
    const FooterComponent = ({row}) => {
      return (
        <TaskCardFooter>
          <TaskWidget
            patient={row}
            encounter={row?.latestEncounter}
            skipMessage
          />
        </TaskCardFooter>
      );
    };
    let columns = [];
    if (isMobile) {
      columns = [
        {
          render: ({row}) => {
            return renderPatientCard({row, FooterComponent});
          },
        },
      ];
    } else {
      columns = [
        {
          header: 'Name',
          type: 'text',
          render: ({row}) => {
            const {FirstName, LastName, MiddleName} = row;
            return getPatientsFullName({FirstName, LastName, MiddleName});
          },
        },
        {
          header: 'Residence',
          render: ({row}) => {
            const text =
              row?.residenceType?.value == Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.residence?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
        },
        {
          header: 'Current Location',
          render: ({row}) => {
            const text =
              row?.currentLocationType?.value == Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.currentLocation?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
        },
        {
          header: 'TCM Due Date',
          render: props => {
            return (
              <Row
                gap={36}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TcmDueDate {...props} />
              </Row>
            );
          },
          width: 150,
        },
        {
          render: ({row}) => {
            if (
              row?.latestEncounter?.taskInfo?.length &&
              row?.latestEncounter?.status === ENCOUNTER_STATUS.Active
            ) {
              return (
                <TaskWidget
                  patient={row}
                  encounter={row?.latestEncounter}
                  skipMessage
                />
              );
            }
          },
          width: 450,
        },
      ];
    }
    return (
      <Table
        flex={false}
        renderHeader={() => {
          return <TableHeader title={'Your Patients'} />;
        }}
        api={routeLink.facilityPatients}
        filter={JSON.stringify(filter)}
        onRowPress={({row}) => {
          navigate(`/facility-stats/overview/patient-details`, {
            state: {
              patient: row,
              titleName: getPatientsFullName({
                FirstName: row.FirstName,
                MiddleName: row.MiddleName,
                LastName: row.LastName,
                Render: false,
              }),
            },
          });
        }}
        style={{
          border: isMobile,
          rowBorder: isMobile,
          columnPadding: isMobile,
        }}
        fields={{
          FullName: 1,
          LastName: 1,
          FirstName: 1,
          MiddleName: 1,
          DOB: 1,
          groupId: {
            _id: 1,
            name: 1,
            PNGroupId: 1,
          },
          residenceType: {
            value: 1,
          },
          currentLocationType: {
            value: 1,
          },
          residence: {
            name: 1,
          },
          currentLocation: {
            name: 1,
          },
          primaryPractice: {
            name: 1,
          },
          latestEncounter: {
            _id: 1,
            status: 1,
            dischargeDate: 1,
            taskInfo: {
              _id: 1,
              taskId: 1,
              successStatus: 1,
              status: 1,
              type: 1,
              tcmDueDate: 1,
              nextTcmDueDate: 1,
            },
            onGoingTcmDueDate: 1,
            isDateBounded: 1,
            ContactTaskDuration: 1,
            MedicationTaskDuration: 1,
            VisitTaskDuration: 1,
          },
          practices: 1,
          status: 1,
          locationStatus: 1,
        }}
        columns={columns}
      />
    );
  } else {
    let columns = [];
    if (isMobile) {
      columns = [
        {
          render: ({row}) => {
            return renderPatientCard({row:row.patient});
          },
        },
      ];
    } else {
      columns = [
        {
          header: 'Name',
          type: 'text',
          render: ({row}) => {
            const {FirstName, LastName, MiddleName} = row.patient;
            return getPatientsFullName({FirstName, LastName, MiddleName});
          },
        },
        {
          header: 'Residence',
          render: ({row}) => {
            const text =
              row?.patient?.residenceType?.value == Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.patient?.residence?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
        },
        {
          header: 'Current Location',
          render: ({row}) => {
            const text =
              row?.patient?.currentLocationType?.value ==
              Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.patient?.currentLocation?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
        },
        {
          header: 'TCM Due Date',
          render: props => {
            return (
              <Row
                gap={36}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TcmDueDate {...{row: props?.row?.patient}} />
              </Row>
            );
          },
          width: 150,
        },
        {
          render: ({row}) => {
            if (
              row?.patient?.latestEncounter?.taskInfo?.length &&
              row?.patient?.latestEncounter?.status === ENCOUNTER_STATUS.Active
            ) {
              return (
                <TaskWidget
                  patient={row?.patient}
                  encounter={row?.patient?.latestEncounter}
                  skipMessage
                />
              );
            }
          },
          width: 450,
        },
      ];
    }
    return (
      <Table
        flex={false}
        renderHeader={() => {
          return <TableHeader title={'Your Patients'} />;
        }}
        api={routeLink.careTeamPatients}
        filter={{
          user: user._id,
          patientResidence: facility._id,
          status: STATUS.ACTIVE,
          patientStatus: STATUS.ACTIVE,
        }}
        onRowPress={({row}) => {
          navigate(`/facility-stats/overview/patient-details`, {
            state: {
              patient: row.patient,
              titleName: row.patient.FullName,
            },
          });
        }}
        style={{
          border: isMobile,
          rowBorder: isMobile,
          columnPadding: isMobile,
        }}
        fields={{
          patient: {
            FullName: 1,
            LastName: 1,
            FirstName: 1,
            MiddleName: 1,
            DOB: 1,
            residenceType: {
              value: 1,
            },
            residence: {
              name: 1,
            },
            currentLocationType: {
              value: 1,
            },
            currentLocation: {
              name: 1,
            },
            latestEncounter: {
              _id: 1,
              status: 1,
              taskInfo: {
                _id: 1,
                taskId: 1,
                successStatus: 1,
                status: 1,
                type: 1,
                tcmDueDate: 1,
                nextTcmDueDate: 1,
              },
              ContactTaskDuration: 1,
              MedicationTaskDuration: 1,
              VisitTaskDuration: 1,
              onGoingTcmDueDate: 1,
              isDateBounded: 1,
            },
          },
        }}
        columns={columns}
      />
    );
  }
};
