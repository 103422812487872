import styled from 'styled-components';
import theme from '../theme/Theme';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  border-top: 1px solid ${theme.colors.SIDEBAR_BACKGROUND_LOW};
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  width: 100%;
  background-color: ${theme.colors.SIDEBAR_BACKGROUND_HIGH};
`;

const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterName = styled.text`
  color: ${theme.colors.BACKGROUND};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const FooterEmail = styled.text`
  color: ${theme.colors.SIDEBAR_BACKGROUND_MEDIUM};
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

const FooterIcon = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export {
  FooterContainer,
  FooterEmail,
  FooterIcon,
  FooterName,
  FooterTextContainer,
};
