import styled from 'styled-components';

const ChatMainScreenContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  position: relative;
`;

export{
    ChatMainScreenContainer
}