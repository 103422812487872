import React from 'react';
import {TextRenderer} from '../components/formEditors/Editors';

export const combineText = (value1, value2) => {
  return `${value1 || ''} ${value2 || ''}`.trim();
};
export const getPatientsFullName = ({
  FirstName,
  MiddleName,
  LastName,
  Render,
}) => {
  const fullName = `${LastName ? LastName + ',' : ''} ${
    FirstName ? FirstName : ''
  } ${MiddleName ? MiddleName : ''}`;

  return Render ? <TextRenderer value={fullName} /> : fullName;
};

export const getDueDateColor = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return 'Red';
  } else if (hours >= 24 && hours < 72) {
    return 'Yellow';
  } else if (hours >= 72) {
    return 'Gray';
  }
};

export const getDifference = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return `${Math.floor(hours)} Hours`;
  } else if (hours >= 24) {
    return `${Math.floor(hours / 24)} Days`;
  }
};

export const extractInitials = fullName => {
  const nameParts = fullName?.split(' ') || []; // Split the full name by space
  const initials = nameParts.map(part => part.charAt(0).toUpperCase());
  return initials.join('');
};
