import React, {useMemo} from 'react';
import {getClosestBreakpoint} from './Utility';
import {useWindowDimensions} from './useWindowDimensions';

const MediaQueryContext = React.createContext();

export const MediaQueryProvider = ({breakpoints, children}) => {
  const windowWidth = useWindowDimensions()?.width;
  const currentBreakpoint = useMemo(
    () => getClosestBreakpoint(breakpoints, windowWidth),
    [windowWidth, breakpoints],
  );

  const isMobile = windowWidth <= breakpoints.sm;
  const isTablet =
    windowWidth > breakpoints.sm && windowWidth <= breakpoints.md;
  const isDesktop = windowWidth > breakpoints.md;

  const providerValue = useMemo(
    () => ({
      current: Object.keys(breakpoints)[currentBreakpoint],
      width: windowWidth,
      breakpoints,
      isMobile,
      isTablet,
      isDesktop,
    }),
    [
      breakpoints,
      currentBreakpoint,
      windowWidth,
      isMobile,
      isTablet,
      isDesktop,
    ],
  );

  return (
    <MediaQueryContext.Provider value={providerValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export {MediaQueryContext};
