import React, {useEffect} from 'react';
import {Table as TableComponent} from '@unthinkable/react-table';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {HttpAuthService} from '../../services';
import {tableStyles} from './Table.styles';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';
import images from '../../assets/images';
import {Image, Text} from '@unthinkable/react-core-components';
import {LabelContainer, Paginate} from '../Paginate.style';
import {LOAD_INIT, SET_PAGE} from './TableReduxConsts.js';
import {connect} from 'react-redux';
import config from '../../Config';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {useAuth} from '../../modules/auth/hooks/useAuth';
import {useMediaQuery} from '../../modules/auth/hooks/useMediaquery';
import {usePageCountContext} from '../../context/pageCount/PageCountProvider';
function loadData(props) {
  return {
    type: LOAD_INIT,
    payload: props,
  };
}

function loadPageCount(props) {
  return {
    type: 'PAGE_COUNT_INIT',
    payload: props,
  };
}

function loadPage({api, page}) {
  return {
    type: SET_PAGE,
    page: page,
    api: api,
  };
}

export const BasicTable = props => {
  const toast = useToast();
  const {colors} = useTheme();
  let styles = useStyles(tableStyles);
  const fetch = async ({uri, props}) => {
    try {
      const result = await HttpAuthService.get(uri, {
        params: props,
      });
      return result;
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  return (
    <TableComponent
      {...props}
      fetch={fetch}
      styles={styles}
      borderColor={colors.OUTLINE}
      renderLoader={() => <ActivityIndicator />}
    />
  );
};

const PaginatedTable = props => {
  const {
    filter,
    addOnFilter,
    groupBy,
    addonGroupBy,
    params,
    search,
    api,
    error,
    page,
    pageCount,
    getData,
    getPageCount,
    setPage,
    eventSourceId,
    style = {},
    ...rest
  } = props;
  const toast = useToast();
  const {colors} = useTheme();
  let styles = useStyles(tableStyles);
  styles = {
    ...styles,
    ...style,
  };
  const {logout} = useAuth();
  const onError = async () => {
    await logout();
  };
  useDataFetchEvents({eventSourceId}, () => {
    getData({
      ...props,
      onError,
    });
    getPageCount({
      ...props,
      onError,
    });
  });
  const {pageCount: pageHistory, setPageCount: setPageHistory} =
    usePageCountContext();

  useEffect(() => {
    setPageHistory(window.location.pathname, page);
  }, [page]);

  useEffect(() => {
    if (!page) {
      setPage({api, page: pageHistory[window.location.pathname]});
    } else {
      setPage({api, page: 0});
    }
  }, [filter, addOnFilter, groupBy, addonGroupBy, params, search]);

  useEffect(() => {
    getData({
      ...props,
      onError,
    });
  }, [filter, addOnFilter, groupBy, addonGroupBy, params, search, page]);

  useEffect(() => {
    getPageCount({
      ...props,
      onError,
    });
  }, [filter, addOnFilter, groupBy, addonGroupBy, params, search]);

  const handlePageClick = event => {
    setPage({api, page: event.selected});
  };

  useEffect(() => {
    if (error) {
      toast({message: error.message, type: 'Error'});
    }
  }, [error]);

  const {isMobile} = useMediaQuery();
  return (
    <>
      <TableComponent
        {...rest}
        styles={styles}
        borderColor={colors.OUTLINE}
        onEndReachedThreshold={2}
        variant="outline"
        renderLoader={() => <ActivityIndicator />}
      />
      <Paginate
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        nextLabel={
          <LabelContainer>
            <Text>Next</Text>
            <Image source={images.ArrowRight} />
          </LabelContainer>
        }
        previousLabel={
          <LabelContainer>
            <Image source={images.ArrowLeft} />
            <Text>Previous</Text>
          </LabelContainer>
        }
        forcePage={page}
        marginPagesDisplayed={isMobile ? 0 : 4}
      />
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    data: state?.table?.[ownProps.api]?.data || [],
    loading: state?.table?.[ownProps.api]?.loading || false,
    error: state?.table?.[ownProps.api]?.error,
    pageCount: state?.table?.[ownProps.api]?.pageCount || 0,
    page: state?.table?.[ownProps.api]?.page || 0,
    skip:
      state?.table?.[ownProps.api]?.page *
        (ownProps?.perPage || config.defaultListLimit) || 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getData: props => dispatch(loadData(props)),
    getPageCount: props => dispatch(loadPageCount(props)),
    setPage: props => {
      dispatch(loadPage(props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginatedTable);
