import styled from 'styled-components';
import Theme from '../../../theme/Theme';
const JumpToLatestMainContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  font-weight: 500;
  bottom: 10%;
  right: 50%;
  transform: translate(50%,-10%);
  color: ${Theme.colors.BACKGROUND};
  background-color: ${Theme.colors.NEUTRAL_HIGH};
  padding: 4px 12px 5px 8px;
  border-radius: 18px;
  cursor: pointer;
`;
const StyledImgJumpToLatest = styled.img`
  width: 23px;
  height: 23px;
`;

export {JumpToLatestMainContainer, StyledImgJumpToLatest};
