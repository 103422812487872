import {LayoutFieldsRender} from '@unthinkable/react-form';
import {useToast} from '@unthinkable/react-toast';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';
import {routeLink, toasterMessage} from '../../../constant';
import buttonType from '../../../constant/buttonType';
import HttpAuth from '../../../services/HttpAuthService';
import {
  BrandName,
  FooterContainer,
  FormContainer,
  HeaderContainer,
  LayoutContainer,
  LoginMessage,
  ScreenContainer,
} from './LoginForm.style';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const resetPassword = async ({values}) => {
    {
      try {
        setLoading(true);
        await HttpAuth.post(routeLink.forgotPasswordLink, values);
        toast({
          message: toasterMessage.forgetPassword.success,
          type: 'Success',
        });
      } catch (err) {
        toast({
          message: err.message,
          type: 'Error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <ScreenContainer>
      <FormContainer>
        <HeaderContainer>
          <BrandName> Elite Care</BrandName>
          <LoginMessage> Forgot Password</LoginMessage>
        </HeaderContainer>
        <Form>
          {formContext => {
            const {values, isValid} = formContext;
            return (
              <LayoutContainer>
                <LayoutFieldsRender
                  layoutFields={[
                    {
                      type: 'email',
                      field: 'email',
                      label: 'Email',
                      required: true,
                      inputProps: {
                        onkeyup: async e => {
                          if (e.which == 13) {
                            await resetPassword({values});
                          }
                        },
                      },
                    },
                  ]}
                  {...formContext}
                />
                <FooterContainer>
                  <Button
                    disabled={!isValid}
                    loading={loading}
                    text="Reset Password"
                    onPress={async () => {
                      resetPassword({values});
                    }}
                  />
                  <Button
                    text="Login"
                    onPress={() => {
                      navigate(routeLink.loginLink);
                    }}
                    buttonType={buttonType.linkButton}
                  />
                </FooterContainer>
              </LayoutContainer>
            );
          }}
        </Form>
      </FormContainer>
    </ScreenContainer>
  );
};

export default ForgotPasswordForm;
