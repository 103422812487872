import {useSelector} from 'react-redux';
import {
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  SET_UNREAD_COUNT,
  INCREASE_UNREAD_COUNT,
} from './chatReduxConstant';

const chatInitialState = {
  groupMemberData: {},
  error: '',
};

const messageCountInitialState = {
  messageCount: {},
  error: '',
};

export const chatReducer = (state = chatInitialState, action) => {
  switch (action.type) {
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        groupMemberData: {
          ...state.groupMemberData,
          [action.payload.groupId]: action.payload.data,
        },
      };
    case FETCH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const unreadMessageCountReducer = (
  state = messageCountInitialState,
  action,
) => {
  switch (action.type) {
    case SET_UNREAD_COUNT:
      return {
        ...state,
        messageCount: {
          ...state.messageCount,
          [action.payload.groupId]: +action.payload.data,
        },
        error: '',
      };
    case INCREASE_UNREAD_COUNT:
      if (state.messageCount[action.payload.groupId]) {
        const count = state.messageCount[action.payload.groupId] + 1;
        return {
          ...state,
          messageCount: {
            ...state.messageCount,
            [action.payload.groupId]: count,
          },
          error: '',
        };
      } else {
        return {
          ...state,
          messageCount: {
            ...state.messageCount,
            [action.payload.groupId]: 1,
          },
          error: '',
        };
      }
    default:
      return state;
  }
};
export const getMembersData = groupId =>
  useSelector(state => state.chat?.groupMemberData[groupId]);
export const getMessageCount = row =>
  useSelector(
    state => state?.chatMessageCount?.messageCount[row?.groupId?.PNGroupId],
  );
