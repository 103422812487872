import {View} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';
import {status} from '../../../constant/status';

import React, {useEffect, useState} from 'react';
import {useInvoke} from '../../../hooks';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {hospitalizationDiagnoseStyle} from './style/hospitalizationDiagnoseTable.style';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {Confirm} from '../../../components/confirm';
import modalMessages from '../../../constant/ModalMessage';
import {Icon} from '../../../modules/facilities/screens/FacilityTable.style';
import images from '../../../assets/images';
import {TextRenderer} from '@unthinkable/react-text-input';
import {useToast} from '@unthinkable/react-toast';
import {HttpAuthService} from '../../../services';
import {generateCategoryWiseDiagnose} from '../../../services/patientDiagnose.service';
import {RowHeading} from '../../../components/header/RowHeading';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {invokeEvents} from '../../../hooks/useInvoke';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';

export const HospitalizationDiagnose = props => {
  const {encounter} = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const RenderAction = props => {
    const {row} = props;
    const toast = useToast();
    const invoke = useInvoke({
      method: 'put',
      eventSourceId: ['hospitalizationDiagnose'],
    });
    const {user} = useAuth();
    if (!row.diagnoseApproved && row.status === status.active) {
      return (
        <View style={hospitalizationDiagnoseStyle.actionContainer}>
          {encounter?.taskInfo?.length > 1 &&
            checkAuthorization(user, entity.diagnose, action.approve) && (
              <Confirm
                title={modalMessages.diagnoseApproval.title}
                secondaryTitle={row.name}
                message={modalMessages.diagnoseApproval.message}
                confirmText={modalMessages.diagnoseApproval.confirmText}
                onConfirm={async () => {
                  const result = await invoke({
                    uri: `${routeLink.updateOutPatientDiagnosesStatus}`,
                    data: {
                      _id: row._id,
                      patientId: row.patientId,
                      diagnoseId: row.diagnose,
                    },
                  });
                  toast(result);
                }}>
                <Icon src={images.checkCircle} />
              </Confirm>
            )}
          {encounter?.taskInfo?.length > 1 &&
            checkAuthorization(user, entity.diagnose, action.delete) && (
              <Confirm
                title={modalMessages.removeDiagnose.title}
                secondaryTitle={row.name}
                message={modalMessages.removeDiagnose.message}
                confirmText={modalMessages.removeDiagnose.confirmText}
                onConfirm={async () => {
                  const result = await invoke({
                    uri: `${routeLink.getOutPatientDiagnoses}/${row._id}`,
                    data: {status: status.inactive},
                  });
                  toast(result);
                }}>
                <Icon src={images.trash} />
              </Confirm>
            )}
        </View>
      );
    } else if (row.diagnoseApproved && row.status === status.active) {
      return (
        <View style={hospitalizationDiagnoseStyle.actionContainer}>
          <Icon src={images.contactSuccess} />
        </View>
      );
    }
  };
  const fetchPatientDiagnose = async () => {
    setLoading(true);
    const response = await HttpAuthService.get(
      routeLink.getOutPatientDiagnoses,
      {
        params: {
          filter: {
            encounterId: encounter._id,
          },
          fields: {
            diagnose: {
              Name: 1,
              Code: 1,
              CodeSystemName: 1,
            },
            status: 1,
            diagnoseApproved: 1,
            patientId: 1,
          },
        },
      },
    );
    setData(generateCategoryWiseDiagnose(response.data));
    setLoading(false);
  };

  useEffect(() => {
    fetchPatientDiagnose();
  }, []);

  useDataFetchEvents(
    {eventSourceId: ['hospitalizationDiagnose']},
    fetchPatientDiagnose,
  );

  const renderTable = props => {
    const {row, index} = props;
    const {notify} = useEventHandler();

    if (!row) return;
    if (row?.name === 'Header') {
      return (
        <View style={{flex: 1, overflow: 'hidden', maxHeight: '335px'}}>
          <BasicTable
            columns={[
              {
                header: 'Code',
                width: 150,
              },
              // {
              //   header: 'Code Name',
              // },
              {
                header: 'Diagnosis',
              },
              {
                header: 'Reconcile',
                width: 100,
              },
            ]}></BasicTable>
        </View>
      );
    } else
      return (
        <View style={{flex: 1}}>
          <RowHeading
            title={row.name}
            actions={() => {
              return (
                <Icon
                  src={row.expanded ? images.showLess : images.showMore}
                  onClick={() => {
                    row.expanded = !row.expanded;
                    invokeEvents({
                      eventSourceId: ['collapse'],
                      notify,
                    });
                  }}
                />
              );
            }}
          />
          {row.expanded && (
            <BasicTable
              eventSourceId={'hospitalizationDiagnose'}
              variant={'outline'}
              api={routeLink.getOutPatientDiagnoses}
              data={row.data}
              columns={[
                {
                  field: 'diagnose.Code',
                  type: 'text',
                  width: 150,
                },
                // {
                //   field: 'diagnose.CodeSystemName',
                //   type: 'text',
                // },
                {
                  field: 'diagnose.Name',
                  render: props => (
                    <TextRenderer
                      {...props}
                      styles={{
                        ...props.styles,
                        text: {
                          ...props.styles.text,
                          textDecoration:
                            props?.row?.status == 'Inactive'
                              ? 'line-through'
                              : 'none',
                        },
                      }}
                    />
                  ),
                },
                {
                  render: props => {
                    const obj = {...props, groupIdx: index};
                    return <RenderAction {...obj} />;
                  },
                  width: 100,
                },
              ]}
              {...props}
            />
          )}
        </View>
      );
  };
  const {isMobile} = useMediaQuery();
  return (
    <View
      style={
        isMobile
          ? hospitalizationDiagnoseStyle.mainContainerMobile
          : hospitalizationDiagnoseStyle.mainContainer
      }>
      <TableHeader title={'Hospitalization Diagnoses'} />
      <BasicTable
        eventSourceId={['hospitalizationDiagnose', 'collapse']}
        variant={'outline'}
        data={data}
        loading={loading}
        renderRow={renderTable}
        {...props}
      />
    </View>
  );
};
