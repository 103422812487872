import {inputStyles} from '../Editors.styles';
export const DateTimeInputStyles = ({theme}) => {
  const {fonts, colors} = theme;
  return {
    ...inputStyles({theme}),
    input: {
      outline: 'none',
      border: 'none',
      ...fonts.BODY3,
      color: colors.NEUTRAL_HIGH,
      backgroundColor:'transparent'
      
    },
  };
};
