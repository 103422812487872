const avatar = '/images/Avatar.svg';
const logo = '/images/logo.png';
const sun = '/images/sun.png';
const moon = '/images/moon.png';
const flagFr = '/images/flag-french.png';
const flagUk = '/images/flag-uk.png';
const homeSolid = '/images/home-solid.png';
const homeOutline = '/images/home-outline.png';
const profileSolid = '/images/profile-solid.png';
const profileOutline = '/images/profile-outline.png';
const Cross = '/images/Cross.svg';
const Buildings = '/images/buildings.svg';
const BuildingsSelected = '/images/buildingsSelected.svg';
const Admin = '/images/admin.svg';
const AdminSelected = '/images/adminSelected.svg';
const Patients = '/images/patients.svg';
const PatientsSelected = '/images/patientsSelected.svg';
const Tasks = '/images/tasks.svg';
const TasksSelected = '/images/tasksSelected.svg';
const Home = '/images/home.svg';
const HomeSelected = '/images/homeSelected.svg';
const edit = '/images/edit.svg';
const addUser = '/images/user-plus-02.svg';
const logout = '/images/logout.svg';
const trash = '/images/trash.svg';
const activation = '/images/activation.svg';
const FullBrandLogo = '/images/FullBrandLogo.png';
const MiniBrandLogo = '/images/MiniBrandLogo.png';
const DateNext = '/images/DateNext.svg';
const DatePrevious = '/images/DatePrevious.svg';
const ArrowRight = '/images/arrowRight.svg';
const ArrowLeft = '/images/arrowLeft.svg';
const ArrowDown = '/images/arrowDown.svg';
const ArrowDownBlack = '/images/arrowDownBlack.svg';
const NavRight = '/images/navRight.svg';
const AddPractice = '/images/practiceAdd.svg';
const AddFacility = '/images/facilityAdd.svg';
const Tick = '/images/tick.svg';
const Crossdark = '/images/Crossdark.svg';
const Eye = '/images/Eye.svg';
const Upload = '/images/upload.svg';
const Send = '/images/send.png';
const setting = '/images/settings.svg';
const Submit = '/images/Submit.svg';
const SubmitActive = '/images/SubmitActive.svg';
const chatSmile = '/images/chatSmile.svg';
const chatSmileSelected = '/images/chatSmileSelected.svg';
const PatientChat = '/images/patientChat.svg';
const PatientChatSelected = '/images/patientChatSelected.svg';
const groupMembers = '/images/groupMembers.svg';
const checkCircle = '/images/check-circle.svg';
const checkDouble = '/images/check-double.svg';
const closeCircle = '/images/close-circle.svg';
const expand = '/images/expand.svg';
const addIcon = '/images/addIcon.svg';
const contact = '/images/contact.svg';
const medRec = '/images/medRec.svg';
const visit = '/images/visit.svg';
const contactSuccess = '/images/contactSuccess.svg';
const medRecComplete = '/images/medRecCompleted.svg';
const notes = '/images/homeHealthSelected.svg';
const showMore = '/images/showMore.svg';
const showLess = '/images/showLess.svg';
const stopwatch = '/images/stopwatch.svg';
const fetchClinicalSummaryFailed = '/images/fetchClinicalSummaryfailed.svg';
const fetchClinicalSummarySuccess = '/images/fetchClinicalSummarySuccess.svg';
const location = '/images/location.svg';
const calender = '/images/calender.svg';
const GrayhourGlass = '/images/GrayhourGlass.svg';
const YellowhourGlass = '/images/YellowhourGlass.svg';
const RedhourGlass = '/images/RedhourGlass.svg';
const residence = '/images/residence.svg';
export default {
  checkCircle,
  checkDouble,
  closeCircle,
  chatSmileSelected,
  chatSmile,
  avatar,
  logo,
  sun,
  moon,
  flagFr,
  flagUk,
  homeSolid,
  homeOutline,
  profileSolid,
  profileOutline,
  Cross,
  Crossdark,
  Buildings,
  BuildingsSelected,
  Admin,
  AdminSelected,
  Patients,
  PatientsSelected,
  Tasks,
  TasksSelected,
  Home,
  HomeSelected,
  edit,
  addUser,
  logout,
  trash,
  activation,
  FullBrandLogo,
  MiniBrandLogo,
  DateNext,
  DatePrevious,
  ArrowRight,
  ArrowLeft,
  ArrowDown,
  ArrowDownBlack,
  NavRight,
  AddPractice,
  AddFacility,
  Tick,
  Eye,
  Upload,
  Send,
  setting,
  Submit,
  SubmitActive,
  groupMembers,
  expand,
  addIcon,
  contact,
  medRec,
  visit,
  contactSuccess,
  medRecComplete,
  PatientChat,
  PatientChatSelected,
  notes,
  showMore,
  showLess,
  stopwatch,
  fetchClinicalSummaryFailed,
  fetchClinicalSummarySuccess,
  location,
  calender,
  GrayhourGlass,
  YellowhourGlass,
  RedhourGlass,
  residence,
};
