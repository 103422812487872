const {AuditLogTable} = require('./screens/AuditLogTable');

const AuditLogRoutes = [
  {
    name: 'Audit Logs',
    path: '/audit-logs',
    component: AuditLogTable,
  },
];

module.exports = {
  stack: AuditLogRoutes,
};
