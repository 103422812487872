import {sortArrayObjects} from '@unthinkable/react-utils';

export const CalculateIndex = ({
  data,
  sort,
  prevIndex,
  nextIndex,
  indexField = 'index',
}) => {
  let updatedIndex;
  let nextIndexValue = data[nextIndex]?.[indexField];
  let prevIndexValue = data[prevIndex]?.[indexField];

  if (sort === 'desc') {
    //for descending order list
    if (prevIndexValue && nextIndexValue) {
      updatedIndex = (nextIndexValue + prevIndexValue) / 2;
    } else if (prevIndexValue && !nextIndexValue) {
      updatedIndex = prevIndexValue - 1000;
    } else if (nextIndexValue && !prevIndexValue) {
      updatedIndex = nextIndexValue + 1000;
    }
  } else {
    if (prevIndexValue && nextIndexValue) {
      updatedIndex = (nextIndexValue + prevIndexValue) / 2;
    } else if (prevIndexValue && !nextIndexValue) {
      updatedIndex = prevIndexValue + 1000;
    } else if (nextIndexValue && !prevIndexValue) {
      updatedIndex = nextIndexValue - 1000;
    }
  }
  return updatedIndex;
};

export const useDraggable = ({
  data,
  setData,
  indexField = 'index',
  sort = 'asc',
  handleDataOnDragEnd,
  updateIndexOnDragEnd,
}) => {
  const modifyDataOnDragEnd = props => {
    const {source} = props;
    const newIndex = CalculateIndex({data, sort, indexField, ...props});
    let sourceRow = data[source.index];
    sourceRow[indexField] = newIndex || sourceRow[indexField];
    updateIndexOnDragEnd &&
      updateIndexOnDragEnd({
        row: sourceRow,
        index: source.index,
        updatedIndex: newIndex,
      });
    return sortArrayObjects(data, sort, indexField);
  };

  const onDragEnd = result => {
    if (!data?.length) {
      return;
    }
    // handle the drag end event here
    // e.g. update the state with the new order of items
    // dropped outside the list
    const {source, destination} = result;

    if (!destination || source.index === destination.index) {
      return;
    }
    let sourceIndex = source.index;
    let destinationIndex = destination.index;

    let prevIndex = destinationIndex;
    let nextIndex = destinationIndex + 1;
    if (destinationIndex === 0) {
      prevIndex = void 0;
      nextIndex = destinationIndex;
    } else if (destinationIndex === data.length - 1) {
      nextIndex = void 0;
      prevIndex = destinationIndex;
    } else if (destinationIndex < sourceIndex) {
      nextIndex = destinationIndex;
      prevIndex = destinationIndex - 1;
    }

    const dragEndProps = {
      source,
      destination,
      prevIndex,
      nextIndex,
    };

    const newData = handleDataOnDragEnd
      ? handleDataOnDragEnd({
          data,
          ...dragEndProps,
        })
      : modifyDataOnDragEnd(dragEndProps);

    setData({data: newData});
  };

  return {
    onDragEnd,
  };
};
