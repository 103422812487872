import {useIdleTimer} from 'react-idle-timer';

export const IdleDetector = ({onIdle, children, timeout = 15 * 60 * 1000}) => {
  useIdleTimer({
    onIdle,
    timeout: timeout,
    throttle: 500,
  });

  return children;
};