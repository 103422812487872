import React, {useState} from 'react';
import {routeLink} from '../../../constant';
import Config from '../../../Config';
import buttonType from '../../../constant/buttonType';
import {Table} from '../../../components/table';
import {TableHeader} from '../../../components/header/TableHeader';
import {Button} from '../../../components/button';
import {useLocation, useNavigate} from 'react-router-dom';
import {useFilter} from '@unthinkable/react-filters';
import images from '../../../assets/images';
import {Icon} from '../../../components/AppSidebarNav.style';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {useInvoke} from '../../../hooks';
import {Confirm} from '@unthinkable/react-confirm';
import {TextRenderer} from '@unthinkable/react-text-input';
import {Row} from '@unthinkable/react-core-components';
import {HttpAuthService} from '../../../services';
const {defaultListLimit} = Config;

export const InsertionLogsTable = props => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {filterValues} = useFilter();
  const {
    state: {source},
  } = useLocation();
  filterValues.filter = {
    ...filterValues.filter,
    source: source,
  };

  const RenderUpload = ({row}) => {
    if (row.status !== 'Pending') return <TextRenderer value={row.status} />;

    const invoke = useInvoke({
      method: 'post',
      eventSourceId: 'InsertionLogs',
    });

    return (
      <Confirm
        title={'Patient Upload'}
        message={`Are you sure you want to upload ${row.file.filename} ?`}
        confirmText="Confirm"
        onConfirm={async () => {
          setLoading(row._id);
          await invoke({
            uri: '/v1/patient/import',
            data: {
              insertionLogId: row?._id,
              key: row.file.key,
            },
          });
          setLoading('');
        }}>
        {loading === row._id ? (
          <ActivityIndicator />
        ) : (
          <Row style={{justifyContent: 'space-between'}}>
            <TextRenderer value={row.status} />
            <Icon src={images.Upload} />
          </Row>
        )}
      </Confirm>
    );
  };

  return (
    <Table
      noDataText={'No Insertion Logs found'}
      onRowPress={({row}) => {
        navigate('/patient/insertion-logs/error-logs', {
          state: {
            insertionLogId: row._id,
            source: source,
          },
        });
      }}
      renderHeader={() => (
        <TableHeader
          title={'Insertion Logs'}
          actions={[
            <Button
              key="addPatient"
              text="Upload File"
              onPress={() => {
                navigate('/patient/insertion-logs/insert-new-file', {
                  state: {
                    source: source,
                  },
                });
              }}
              icon={images.Upload}
              iconPosition="right"
              buttonType={buttonType.linkButtonWithBorder}
            />,
            <Button
              key="Download Sample File"
              text="Download Sample File"
              onPress={async () => {
                await HttpAuthService.download(routeLink.downloadSample, {});
              }}
              icon={images.ArrowDownBlack}
              iconPosition="right"
              buttonType={buttonType.linkButtonWithBorder}
            />,
          ]}
        />
      )}
      sort={{createdAt: -1}}
      api={routeLink.insertionLogs}
      eventSourceId="InsertionLogs"
      addOnFilter={JSON.stringify(filterValues.filter)}
      perPage={defaultListLimit}
      fields={{
        file: {
          filename: 1,
          key: 1,
        },
        status: 1,
        totalInsertions: 1,
        successfulInsertions: 1,
        failedInsertions: 1,
        createdAt: 1,
      }}
      columns={[
        {
          header: 'File Name',
          field: 'file.filename',
          type: 'text',
        },
        {
          header: 'Total Insertions',
          field: 'totalInsertions',
          type: 'text',
        },
        {
          header: 'Successful Insertions',
          field: 'successfulInsertions',
          type: 'text',
        },
        {
          header: 'Failed Insertions',
          field: 'failedInsertions',
          type: 'Number',
        },
        {
          header: 'Status',
          render: RenderUpload,
          width: '150px',
        },
      ]}
      {...props}
    />
  );
};
