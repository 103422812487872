import {useTheme} from '@unthinkable/react-theme';
import React from 'react';
import {
  JumpToLatestMainContainer,
  StyledImgJumpToLatest,
} from '../styles/JumpToLatest.style';

const JumpToLatest = ({handleJump}) => {
  const {ArrowDown} = useTheme('icons');
  return (
    <JumpToLatestMainContainer onClick={handleJump}>
      <StyledImgJumpToLatest src={ArrowDown} />  Jump to latest
    </JumpToLatestMainContainer>
  );
};

export default JumpToLatest;
