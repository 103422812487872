import React from 'react';
const stackRoutes = [
  {
    name: 'Primary Admin',
    path: '/admin',
    component: () => <div>admin</div>,
  },
];

export default {
  stack: stackRoutes,
};
