import React, {useEffect, useState} from 'react';
import {Col} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {useLocation} from 'react-router-dom';
import Theme from '../../../theme/Theme';
import {YourPatientsTable} from './YourPatientsTable';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';
import {
  CardContainer,
  CardMessage,
  CountContainer,
  DueCardRow,
  DueTaskCount,
  OverDueTaskCount,
} from './FacilityStats.styles';
import {useAuth} from '../../auth/hooks/useAuth';
import { isPracticeEmployee } from '../../../utils/checkEmployerType';


const CardRender = ({
  message,
  dueCount,
  overDueCount,
  dueColor,
  overdueColor,
  showOutStandingTcmTasks = true,
}) => {
  return (
    <CardContainer>
      <CardMessage numberOfLines={2}>{message}</CardMessage>
      <CountContainer>
        <DueTaskCount dueColor={dueColor}>{dueCount || 0}</DueTaskCount>
        {showOutStandingTcmTasks&&<OverDueTaskCount overdueColor={overdueColor}>
          Overdue: {overDueCount || 0}
        </OverDueTaskCount>}
      </CountContainer>
    </CardContainer>
  );
};

const DueCards = ({facility}) => {
  return (
    <DueCardRow>
      <CardRender
        message={'Patient Contact Task Outstanding'}
        dueCount={facility.dueContactTasks}
        overDueCount={facility.overDueContactTasks}
        dueColor={Theme.colors.DUE_CONTACT_TASK_COLOR}
        overdueColor={Theme.colors.OVERDUE_CONTACT_TASK_COLOR}
      />
      <CardRender
        message={'Med Recs Outstanding'}
        dueCount={facility.dueMedRecTasks}
        overDueCount={facility.overDueMedRecTasks}
        dueColor={Theme.colors.DUE_MEDREC_TASK_COLOR}
        overdueColor={Theme.colors.OVERDUE_MEDREC_TASK_COLOR}
      />
      <CardRender
        message={'TCM Visits Outstanding'}
        dueCount={facility.dueVisitTasks}
        overDueCount={facility.overDueVisitTasks}
        dueColor={Theme.colors.DUE_VISIT_TASK_COLOR}
        overdueColor={Theme.colors.OVERDUE_VISIT_TASK_COLOR}
      />
      <CardRender
        message={'TCM Notes Outstanding'}
        dueCount={facility.dueNotesTasks}
        overDueCount={facility.overDueNotesTasks}
        dueColor={Theme.colors.DUE_NOTES_TASK_COLOR}
        overdueColor={Theme.colors.OVERDUE_NOTES_TASK_COLOR}
        showOutStandingTcmTasks ={false}
      />
    </DueCardRow>
  );
};

export const FacilityStatsOverView = () => {
  const {
    state: {facility},
  } = useLocation();

  const [data, setData] = useState({});
  const params = {};
  const {user} = useAuth();

  if (isPracticeEmployee()) {
    params.primaryPractice = user?.practiceId?._id;
  }

  const getFacilityDetails = async () => {
    const {data} = await HttpAuthService.get(
      routeLink.getFacilityWithTaskCount + '/' + facility._id,
      {
        params: {
          params,
        },
      },
    );
    setData(data);
  };

  useEffect(() => {
    getFacilityDetails();
  }, []);

  return (
    <Col
      gap={8}
      style={{
        flex: 1,
      }}>
      <TableHeader title={facility?.name} />
      <DueCards facility={data} />
      <YourPatientsTable />
    </Col>
  );
};
