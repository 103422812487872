import React from 'react';
import './activityIndicator.css';
import {View} from '@unthinkable/react-core-components';

const Sizes = {
  small: {
    height: 25,
    width: 25,
    borderWidth: 2,
  },
  large: {
    height: 50,
    width: 50,
    borderWidth: 4,
  },
};

const ActivityIndicator = props => {
  const {
    title,
    size,
    width,
    color = '#999999',
    sizes = Sizes,
    indicatorStyle,
    animationStyle,
  } = props || {};
  let extraIndicatorStyle = {};
  if (typeof size === 'number') {
    extraIndicatorStyle = {
      width: size,
      height: size,
      borderWidth: 2,
    };
  } else if (sizes[size]) {
    extraIndicatorStyle = {
      ...Sizes[size],
    };
  } else {
    extraIndicatorStyle = {
      ...Sizes['small'],
    };
  }
  if (width) {
    extraIndicatorStyle.borderWidth = width;
  }
  if (color) {
    extraIndicatorStyle.borderColor = color;
  }
  return (
    <View
      title={title}
      style={[
        extraIndicatorStyle,
        {borderRightColor: 'transparent', borderRadius: '50%'},
        indicatorStyle,
        animationStyle || {
          animationName: 'spinnerRotate',
          animationDuration: '0.75s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'linear',
        },
      ]}
    />
  );
};

export default ActivityIndicator;
