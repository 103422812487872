import React from 'react';
import {
  GroupRowContainer,
  GroupRowText,
  ExpandCollapseIcon,
  GroupRowContent,
} from './styles/TableStyles';
import {resolveExp} from '@unthinkable/react-utils';
import {Row} from '@unthinkable/react-core-components';

export const GroupRow = ({
  styles,
  row,
  index,
  groupRow,
  isExpanded,
  toggleExpand,
}) => {
  const expanded = isExpanded('group', index, groupRow);
  const {title, actions, expandable, onPress, leftContent, rightContent} =
    groupRow || {};
  let titleValue = void 0;
  if (typeof title === 'function') {
    titleValue = title({row});
  } else if (title) {
    titleValue = resolveExp(row, title);
  }
  let contentOnPress;
  if (onPress) {
    contentOnPress = () => onPress({row, index});
  }
  return (
    <GroupRowContainer
      onPress={() => {
        expandable !== false && toggleExpand('group', index, groupRow);
      }}
      key={`group-${index}`}
      styles={styles}>
      <ExpandCollapseIcon styles={styles} expanded={expanded} />
      <GroupRowContent onPress={contentOnPress} styles={styles}>
        <Row
          style={{
            alignItems: 'center',
            flex: 1,
            overflow: 'hidden',
          }}>
          {titleValue ? (
            <GroupRowText styles={styles} numberOfLines={2}>
              {titleValue}
            </GroupRowText>
          ) : (
            void 0
          )}
          {leftContent && typeof leftContent === 'function'
            ? leftContent({row})
            : leftContent}
        </Row>
        {rightContent && typeof rightContent === 'function'
          ? rightContent({row})
          : rightContent}
        {actions && typeof actions === 'function' ? actions({row}) : actions}
      </GroupRowContent>
    </GroupRowContainer>
  );
};
