import React, {forwardRef} from 'react';
import BaseInputTextChip from './BaseInputTextChip';
import {useAutoComplete} from '@unthinkable/react-autocomplete';

const MultiInputTextChip = (props, ref) => {
  const {value, onChangeValue, multiple} = props;
  props = useAutoComplete({...props, ref});
  props.onRemove = ({index}) => {
    if (!value) {
      return;
    }
    if (multiple) {
      let newValue = value.filter((doc, _index) => index !== _index);
      onChangeValue?.(newValue?.length ? newValue : []);
    }
  };
  return <BaseInputTextChip {...props} />;
};

export default forwardRef(MultiInputTextChip);
