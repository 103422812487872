import {action, entity} from '../../constant/authorization';
import {FacilityStatsOverView} from './screens/FacilityStatsOverView';
import {FacilityStatsTable} from './screens/FacilityStatsTable';
import {AddFacility, EditFacility} from './screens/FacilityForm';
import {FacilityTable} from './screens/FacilityTable';
import checkAuthorization from '../../services/accessService';
import {PatientDetails} from '../patient/screens/PatientDetails';

const stackRoutes = [
  {
    name: 'Facilities',
    path: '/facilities-company',
    component: FacilityTable,
    visible: user => {
      return (
        checkAuthorization(user, entity.adminFacilities, action.view) ||
        checkAuthorization(user, entity.adminFacilities, action.viewOwn) ||
        checkAuthorization(user, entity.adminFacilities, action.viewAll)
      );
    },
  },
  {
    name: 'Facilities',
    path: '/facility-stats',
    component: FacilityStatsTable,
    visible: user => {
      return (
        checkAuthorization(user, entity.facilityStats, action.viewAll) ||
        checkAuthorization(user, entity.facilityStats, action.viewOwn) ||
        checkAuthorization(user, entity.facilityStats, action.view)
      );
    },
  },
  {
    name: 'Add Facility',
    path: '/facilities-company/add',
    component: AddFacility,
    visible: user => {
      return checkAuthorization(user, entity.adminFacilities, action.create);
    },
  },
  {
    name: 'Edit Facility',
    path: '/facilities-company/edit/:facilityId',
    component: EditFacility,
    visible: user => {
      return checkAuthorization(user, entity.adminFacilities, action.edit);
    },
  },
  {
    name: 'Overview',
    path: '/facility-stats/overview',
    component: FacilityStatsOverView,
  },
  {
    name: 'Patient Details',
    path: '/facility-stats/overview/patient-details',
    component: PatientDetails,
    visible: user => {
      return (
        checkAuthorization(user, entity.patient, action.viewAll) ||
        checkAuthorization(user, entity.patient, action.viewOwn)
      );
    },
  },
];

export default {
  stack: stackRoutes,
};
