import {useEffect, useRef, useState} from 'react';
import {useFetchSuggestions} from './useFetchSuggestions';

export const useSelectorFetchSuggestions = ({
  data: initialSuggestions,
  searchValue,
  ...props
}) => {
  const {searching, value} = props;
  let {suggestions, fetchSuggestions, ...suggestionProps} = useFetchSuggestions(
    {
      ...props,
      initialSuggestions,
    },
  );

  const mounted = useRef();
  const [searchText, setSearchValue] = useState();
  const [isDataChanged, setIsDataChanged] = useState(false);
  if (searching) {
    searchValue = searchText;
  }

  const reloadData = () => {
    setIsDataChanged(!isDataChanged);
    suggestionProps.resetCache();
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      !suggestions && fetchSuggestions();
    } else {
      fetchSuggestions({searchValue});
    }
  }, [searchValue, isDataChanged, value]);

  return {
    ...props,
    ...suggestionProps,
    data: suggestions,
    searchValue,
    setSearchValue,
    reloadData,
  };
};
