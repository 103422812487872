import FacilityRoutes from '../modules/facilities/FacilityRoutes';
import UserRoutes from '../modules/user/UserRoutes';
import PracticesRoutes from '../modules/practices/PracticesRoutes';
import ChatRoute from '../modules/chat/ChatRoute';
import UpcomingRoutes from './UpcomingRoutes';
import NewRequestRoutes from '../modules/facility-request/NewRequestsRoutes';
import AuditLogRoutes from '../modules/audit-logs/AuditLogRoutes';
import PatientRoutes from '../modules/patient/PatientRoutes';
import AlertRoutes from '../modules/alerts/AlertRoutes';
import TaskRoutes from '../modules/tasks/TaskRoutes';
import DashboardRoutes from '../modules/dashboard/DashboardRoutes';
import InsertionLogRoutes from '../modules/Insertion-logs/InsertionLogRoutes';

const stackRoutes = [
  ...PatientRoutes.stack,
  ...UpcomingRoutes.stack,
  ...UserRoutes.stack,
  ...FacilityRoutes.stack,
  ...PracticesRoutes.stack,
  ...AuditLogRoutes.stack,
  ...ChatRoute.stack,
  ...NewRequestRoutes.stack,
  ...AlertRoutes.stack,
  ...TaskRoutes.stack,
  ...DashboardRoutes.stack,
  ...InsertionLogRoutes.stack,
];
const ModalRoutes = [
  ...ChatRoute.modal,
  ...NewRequestRoutes.modal,
  ...PatientRoutes.modal,
];

export default {
  stack: stackRoutes,
  modal: ModalRoutes,
};
