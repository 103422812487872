import CIcon from '@coreui/icons-react';
import {CBadge} from '@coreui/react';
import Theme from '../theme/Theme';
import styled from 'styled-components';

export const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const NotificationIcon = styled(CIcon)`
  opacity: ${({notifications}) => (notifications ? 1 : 0.5)};
`;

export const NotificationBadge = styled(CBadge)`
  background: ${Theme.colors.SIDEBAR_BACKGROUND_HIGH};
  margin-top: -4px;
  margin-left: 20px;
`;
