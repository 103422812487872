export const tableStyles = ({theme}) => {
  const {fonts, colors, icons} = theme;
  return {
    container: {
      backgroundColor: colors.BACKGROUND,
    },
    variant: {
      bordered: {
        container: {
          borderColor: colors.OUTLINE,
          borderWidth: 1,
          borderRadius: 8,
        },
      },
      outline: {
        container: {
          borderColor: colors.OUTLINE,
          borderWidth: 1,
          borderRadius: 8,
        },
      },
    },
    header: {
      backgroundColor: colors.SECONDARY_UPPER_LOW,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    headerAggregate: {
      backgroundColor: colors.OUTLINE,
    },
    columnGroupText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    headerText: {
      ...fonts.HEADER_TEXT,
      color: colors.NEUTRAL_HIGH,
      fontSize: 14,
    },
    headerAggregateText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    row: {
      borderColor: colors.OUTLINE,
    },
    rowHoverColor: colors.SURFACE1,
    rowText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    text: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_HIGH,
    },
    columnVariant: {
      subtle: {
        ...fonts.CAPTION_SMALL,
        color: colors.NEUTRAL_LOW,
      },
      secondary: {
        color: colors.NEUTRAL_LOW,
      },
    },
    groupRowText: {
      ...fonts.BODY2,
      color: colors.NEUTRAL_MEDIUM,
    },
    groupItemsContainer: {
      borderColor: colors.OUTLINE,
      borderRadius: 6,
    },
    groupRow: {
      backgroundColor: colors.SURFACE2,
      borderColor: colors.OUTLINE,
    },
    groupRowContent: {
      gap: 10,
    },
    checkbox: {
      checkedIcon: icons.CheckboxIcon,
      uncheckedIcon: icons.UnCheckBoxIcon,
      deSelectIcon: icons.DeSelectBox,
    },
  };
};
