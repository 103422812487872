import React, {useState} from 'react';
import {cilMinus, cilPlus} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {Document, Page, pdfjs} from 'react-pdf';
import images from '../../assets/images';
import HttpAuth from '../../services/HttpAuthService';
import {LabelContainer, Paginate} from '../Paginate.style';
import config from './../../Config';
import './index.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PaginatedPages = ({numPages}) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [scale, setScale] = useState(1);
  const zoomIn = () => {
    if (scale < 2) {
      setScale(scale + 0.1);
    }
  };

  const zoomOut = () => {
    if (scale > 0.5) {
      setScale(scale - 0.1);
    }
  };

  return (
    <View
      style={{
        flexDirection: 'column',
        overflow: 'hidden',
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}>
      <Page
        scale={scale}
        className="page"
        renderMode="canvas"
        loading={<ActivityIndicator />}
        pageNumber={pageNumber + 1}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
      <Paginate
        onPageChange={event => {
          setPageNumber(event.selected);
        }}
        pageCount={numPages}
        renderOnZeroPageCount={null}
        nextLabel={
          <LabelContainer>
            <Text>Next</Text>
            <Image source={images.ArrowRight} />
          </LabelContainer>
        }
        previousLabel={
          <LabelContainer>
            <Image source={images.ArrowLeft} />
            <Text>Previous</Text>
          </LabelContainer>
        }
        forcePage={pageNumber}
      />
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          paddingHorizontal: 20,
          position: 'absolute',
          top: 25,
          right: 25,
          userSelection: 'none',
          gap: 4,
          alignItems: 'center',
          backgroundColor: 'rgba(160, 160, 160,.4)',
          padding: 10,
          borderRadius: 10,
        }}>
        <TouchableOpacity
          style={{
            padding: 5,
            borderRadius: 5,
            backgroundColor: 'rgba(189, 190, 191,.8)',
          }}
          onPress={zoomOut}>
          <CIcon icon={cilMinus} size={'sm'} />
        </TouchableOpacity>
        <Text>{Number.parseInt(scale * 100)}%</Text>
        <TouchableOpacity
          onPress={zoomIn}
          style={{
            padding: 5,
            borderRadius: 5,
            backgroundColor: 'rgba(189, 190, 191,.8)',
          }}>
          <CIcon icon={cilPlus} size={'sm'} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export const PdfPreviewer = ({value}) => {
  const [numPages, setNumPages] = useState(0);
  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages ?? 0);
  }
  const {downloadUrl} = config;
  const url = downloadUrl + '/files/' + value.key;
  const [key, setKey] = useState(value.key);
  return (
    <Document
      className="document"
      key={key}
      file={url}
      options={{
        withCredentials: true,
      }}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={async () => {
        await HttpAuth.refreshTokens();
        setKey(prev => prev + Date.now());
      }}
      renderMode="canvas">
      <PaginatedPages numPages={numPages} />
    </Document>
  );
};
